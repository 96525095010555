import Home from './landingPage/home'
import Showcases from './landingPage/showcases'
// import Pricing from './landingPage/pricing'
import Support from './landingPage/support'
import Getstarted from './landingPage/getStarted'
import TermsandConditons from './landingPage/terms&conditions'
import Faq from './landingPage/faq'
import PrivacyPolicy from './landingPage/privacyPolicy'

import UserProfile from './user/userProfile'
import ProductPage from './user/productPage'
import ProductsPage from './user/productsPage'
import CataloguesPage from './user/cataloguesPage'
import CataloguePage from './user/cataloguePage'
import UserAboutUs from './user/userAboutUs'
import UserContactUs from './user/userContactUs'

import SignIn from './admin/signIn'
import Search from './common/search'
import PrivateLink from './common/privateLink'

import * as Layout from 'layouts'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { getBusinessName } from 'helpers'

const landingPage = [
  {
    path: '/home',
    Component: Home,
    Super: Layout.Home,
    auth: false
  },
  {
    path: '/showcases',
    Component: Showcases,
    Super: Layout.Home,
    auth: false
  },
  // {
  //   path: '/pricing',
  //   Component: Pricing,
  //   Super: Layout.Home,
  //   auth: false
  // },
  {
    path: '/support',
    Component: Support,
    Super: Layout.Home,
    auth: false
  },
  {
    path: '/getStarted/:planType',
    Component: Getstarted,
    Super: Layout.Home,
    auth: false
  },
  {
    path: '/terms&conditions',
    Component: TermsandConditons,
    Super: Layout.Home,
    auth: false
  },
  {
    path: '/privacyPolicy',
    Component: PrivacyPolicy,
    Super: Layout.Home,
    auth: false
  },
  {
    path: '/faq',
    Component: Faq,
    Super: Layout.Home,
    auth: false
  }
  // {
  //   path: '/signUp',
  //   Component: SignUp,
  //   auth: false
  // },
]

const userProfile = [
  {
    path: '/:userProfile',
    Component: () => {
      const navigate = useNavigate()
      const userProfile = getBusinessName()

      useEffect(() => {
        navigate('/' + userProfile + '/home', { replace: true })
      }, [navigate, userProfile])
    },
    Super: Layout.User,
    auth: false
  },
  {
    path: '/:userProfile/home',
    Component: UserProfile,
    Super: Layout.User,
    auth: false
  },
  {
    path: '/:userProfile/products/:productId',
    Component: ProductPage,
    Super: Layout.User,
    auth: false
  },
  {
    path: '/:userProfile/products',
    Component: ProductsPage,
    Super: Layout.User,
    auth: false
  },
  {
    path: '/:userProfile/catalogues/:catalogueId',
    Component: CataloguePage,
    Super: Layout.User,
    auth: false
  },
  {
    path: '/:userProfile/catalogues',
    Component: CataloguesPage,
    Super: Layout.User,
    auth: false
  },
  {
    path: '/:userProfile/aboutus',
    Component: UserAboutUs,
    Super: Layout.User,
    auth: false
  },
  {
    path: '/:userProfile/contactus',
    Component: UserContactUs,
    Super: Layout.User,
    auth: false
  },
  {
    path: '/signIn',
    Component: () => {
      const isAuthenticated = !!window.localStorage.getItem('authentication')

      const navigate = useNavigate()

      useEffect(() => {
        if (isAuthenticated) navigate('/admin/dashboard', { replace: true })
      }, [isAuthenticated, navigate])

      return isAuthenticated ? null : <SignIn />
    },
    auth: false
  },
  {
    path: '/:userProfile/search',
    Component: Search,
    Super: Layout.User,
    auth: false
  },
  {
    path: '/:userProfile/privateLink',
    Component: PrivateLink,
    Super: Layout.User,
    auth: false
  }
]

const routes = [...landingPage, ...userProfile]

export default routes
