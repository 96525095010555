import { useCallback, useEffect, useRef, useState } from 'react'
import { Alert, ImageTag, Loader, Modal, NoData, Swiper } from 'components'
import { Link, useParams } from 'react-router-dom'
import { getBusinessName, head, isCustomDomain } from 'helpers'
import s from './styles.module.scss'
import * as api from 'api'
import images from 'images'

const limit = 10
const perPage = 4
const imagePerPage = 3
const IMAGE_HOST = process.env.REACT_APP_IMAGE_HOST
const mediaMatch = window.matchMedia('(max-width: 1279px)')

export default function Main(props) {
  let { productId } = useParams()
  if (!productId) productId = window.btoa(JSON.stringify(props.product._id))
  const userProfile = getBusinessName()
  const isUserCustomDomain = isCustomDomain()
  const [product, setProduct] = useState([])
  const [loading, setLoading] = useState(false)
  const [currImage, setCurrImage] = useState()
  const [productImages, setProductImages] = useState([])
  const [suggestedProducts, setSuggestedProducts] = useState([])
  const [matches, setMatches] = useState(mediaMatch.matches)
  const processing = useRef(false)
  const processing1 = useRef(false)
  const processing2 = useRef(false)
  const imageRef = useRef(null)

  useEffect(() => {
    const handler = e => setMatches(e.matches)
    mediaMatch.addEventListener('change', handler)
    return () => mediaMatch.removeEventListener('change', handler)
  })

  const getSuggestedProducts = useCallback(async () => {
    if (processing1.current) return

    processing1.current = true
    setLoading(true)

    const fetchSuggestedProducts = await api.products.fetchAllUsersProducts({
      businessName: product.businessName,
      category: product.category,
      limit: limit
    })
    if (fetchSuggestedProducts.code === 'OK') {
      const suggestedProductsData = fetchSuggestedProducts.products.filter(
        suggestedProduct => suggestedProduct._id !== product._id
      )
      const chunks = Array.from({ length: Math.ceil(suggestedProductsData.length / perPage) }, (element, index) =>
        suggestedProductsData.slice(index * perPage, index * perPage + perPage)
      )
      setSuggestedProducts(chunks)
      !matches ? setSuggestedProducts(chunks) : setSuggestedProducts(suggestedProductsData)
    } else {
      Alert.error(fetchSuggestedProducts.error)
    }
    processing1.current = false
    setLoading(false)
  }, [product, matches])

  const getUserProduct = useCallback(async () => {
    if (processing.current) return

    processing.current = true
    setLoading(true)

    const fetchUserProduct = await api.products.fetchAllUsersProducts({
      businessName: userProfile,
      id: JSON.parse(window.atob(productId))
    })

    if (fetchUserProduct.code === 'OK') {
      if (fetchUserProduct.products.length !== 0) {
        setProduct(fetchUserProduct.products[0])
        const chunks = Array.from(
          { length: Math.ceil(fetchUserProduct.products[0].images.length / imagePerPage) },
          (element, index) =>
            fetchUserProduct.products[0].images.slice(index * imagePerPage, index * imagePerPage + imagePerPage)
        )
        setProductImages(chunks)
      }
    } else {
      Alert.error(fetchUserProduct.error)
    }
    processing.current = false
    setLoading(false)
  }, [productId, userProfile])

  const visitsCounter = useCallback(async () => {
    if (processing2.current) return

    processing2.current = true

    const updateProductVisits = await api.products.updateProductVisits({
      businessName: userProfile,
      id: JSON.parse(window.atob(productId))
    })

    if (updateProductVisits.code === 'OK') {
      // let productVisits = updateProductVisits.productVisits
    } else {
      Alert.error(updateProductVisits.error)
    }
    processing2.current = false
  }, [userProfile, productId])

  useEffect(() => {
    if (!props.product) getUserProduct()
    else {
      setProduct(props.product)
      const chunks = Array.from({ length: Math.ceil(props.product.images.length / imagePerPage) }, (element, index) =>
        props.product.images.slice(index * imagePerPage, index * imagePerPage + imagePerPage)
      )
      setProductImages(chunks)
    }
  }, [getUserProduct, props.product, setProduct])

  useEffect(() => {
    if (product.length !== 0) getSuggestedProducts()
  }, [getSuggestedProducts, product])

  useEffect(() => {
    head({
      title: (product.productName || 'Product') + ' | ' + userProfile
    })
  }, [userProfile, product.productName])

  useEffect(() => {
    if (product.length !== 0) visitsCounter()
  }, [visitsCounter, product])

  return (
    <div className={s.main}>
      <div className={s.userProduct + ' indent'}>
        {!!loading && <Loader color='var(--c-primary)' colorText='var(--c-font)' />}
        {!loading && product.length === 0 && <NoData color='var(--c-font)' />}
        {!loading && product.length !== 0 && (
          <>
            {!matches && (
              <div className={s.carousel} style={{ display: matches ? 'none' : 'block' }}>
                <div className={s.productName}>"{product.productName}"</div>
                {product.images.length > 1 && (
                  <Swiper
                    infinite
                    timer={3000}
                    carouselType='casual'
                    paginationOn={product.images.length > 1}
                    arrowsInside={true}
                    navArrowsOn={true}
                    borderTopRadius={false}
                  >
                    {product.images.map((image, i) => (
                      <ImageTag src={IMAGE_HOST + image} alt='' key={i} />
                    ))}
                  </Swiper>
                )}
              </div>
            )}
            <div className={s.product}>
              {!matches ? (
                <div className={s.productImages} style={{ display: matches ? 'none' : 'block' }}>
                  <div className={s.productImage}>
                    <ImageTag src={IMAGE_HOST + product.images[0]} alt='' />
                  </div>

                  {product.images.length >= 3 && (
                    <div className={s.productImageAlt} style={{ paddingTop: '0.5rem' }}>
                      <div>
                        <ImageTag src={IMAGE_HOST + product.images[1]} alt='' />
                      </div>
                      <div>
                        <ImageTag src={IMAGE_HOST + product.images[2]} alt='' />
                      </div>
                    </div>
                  )}

                  {product.images.length > 0 && product.images.length % 2 === 0 && (
                    <div className={s.productImage} style={{ marginTop: '0.5rem' }}>
                      <ImageTag
                        src={IMAGE_HOST + product.images[product.images.length >= 6 ? 5 : product.images.length - 1]}
                        alt=''
                      />
                    </div>
                  )}

                  {product.images.length >= 5 && (
                    <div className={s.productImageAlt} style={{ paddingTop: '0.5rem' }}>
                      <div>
                        <ImageTag src={IMAGE_HOST + product.images[3]} alt='' />
                      </div>
                      <div>
                        <ImageTag src={IMAGE_HOST + product.images[4]} alt='' />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className={s.productImagesAlt} style={{ display: !matches ? 'none' : 'block' }}>
                  <div className={s.row1}>
                    <ImageTag src={currImage ? currImage : IMAGE_HOST + product.images[0]} ref={imageRef} alt='' />
                  </div>
                  <div className={s.row2}>
                    <Swiper
                      infinite
                      timer={3000}
                      carouselType='casualTo'
                      paginationOn={product.images.length > 3}
                      navArrowsOn={false}
                      arrowsInside={false}
                      borderTopRadius={false}
                    >
                      {productImages.map((chunk, index) => (
                        <div className={s.page} key={index}>
                          {chunk.map((image, i) => (
                            <div className={s.suggestedProduct} key={i}>
                              <div className={s.image}>
                                <ImageTag
                                  src={IMAGE_HOST + image}
                                  alt=''
                                  onClick={e => {
                                    setCurrImage(e.target.src)
                                    const image = imageRef.current
                                    image.style.opacity = 0
                                    image.style.transition = 'opacity 1s ease'
                                    setTimeout(() => {
                                      image.style.opacity = 1
                                    }, 400)
                                  }}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </Swiper>
                  </div>
                </div>
              )}
              <div className={s.productDetails}>
                {product.images.length > 1 && <div className={s.productName}>"{product.productName}"</div>}

                {!!product.tags && (
                  <div className={s.tags}>
                    {product.tags.map((tag, i) => (
                      <div key={i}>#{tag}</div>
                    ))}
                  </div>
                )}

                {/* {product.isPromoted && (
                  <div className={s.promotedBadgeBody}>
                    <div className={s.promotedBadge}>
                      <div className={s.title}>StashBlog Assured</div>
                      <div className={s.star}>
                        <span className='material-icons-outlined'>star</span>
                      </div>
                    </div>
                  </div>
                )} */}

                <div className={s.category}>
                  <span>Category:</span>
                  {product.category}
                </div>

                {!!product.pricePerUnit && (
                  <div className={s.category}>
                    <span>Price per unit:</span>
                    {product.pricePerUnit}
                  </div>
                )}

                {!!product.totalUnits && (
                  <div className={s.category}>
                    <span>Total units:</span>
                    {product.totalUnits}
                  </div>
                )}

                <div className={s.username}>
                  <span>By:</span>
                  <Link to={isUserCustomDomain ? '/' : '/' + product.businessName}>{product.businessName}</Link>
                </div>

                <div className={s.date}>
                  <span>Product listed on:</span>
                  {new Date(product.createdAt).toLocaleString('en-US', {
                    timeZone: 'Asia/Kolkata'
                  })}
                </div>

                <div className={s.description}>
                  <div>Description</div>
                  <div>{product.description}</div>
                </div>

                <div className={s.sendQuery}>
                  <div
                    onClick={async () =>
                      Modal.queryModal(
                        'queryModal',
                        product.businessName,
                        [
                          {
                            productId: product.id,
                            link: window.location.href,
                            name: product.productName,
                            image: product.images.length ? IMAGE_HOST + product.images[0] : images.noImage
                          }
                        ],
                        () => {}
                      )
                    }
                  >
                    Send Query
                  </div>
                </div>
              </div>
            </div>

            {!!product.specifications.length > 0 && (
              <div className={s.productDesc}>
                <div className={s.heading}>Product Specifications</div>
                <div className={s.desc}>
                  <ul>
                    {product.specifications.map((specification, i) => (
                      <li key={i}>{specification}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            {!!product.disclaimer.length > 0 && (
              <div className={s.productDisclaimer}>
                <div className={s.heading}>Product disclaimer</div>
                <div className={s.disclaimer}>{product.disclaimer}</div>
              </div>
            )}

            {!loading && suggestedProducts.length !== 0 && (
              <div className={s.suggestion}>
                <div className={s.heading}>You Might Also Like</div>
                {!matches ? (
                  <div className={s.suggestions} style={{ display: matches ? 'none' : 'block' }}>
                    <Swiper
                      infinite
                      timer={3000}
                      carouselType='product'
                      stopOnManual
                      paginationOn={false}
                      arrowsInside={false}
                      navArrowsOn={true}
                      borderTopRadius={false}
                    >
                      {suggestedProducts.map((chunk, index) => (
                        <div
                          className={s.page}
                          key={index}
                          style={{
                            gridTemplateColumns:
                              chunk.length === 1
                                ? 'auto'
                                : chunk.length === 2
                                ? 'auto auto'
                                : chunk.length === 3
                                ? 'auto auto auto'
                                : 'auto auto auto auto'
                          }}
                        >
                          {chunk.map(({ _id, businessName, productName, images }, i) => (
                            <Link
                              to={
                                (isUserCustomDomain ? '/products/' : '/' + businessName + '/products/') +
                                window.btoa(JSON.stringify(_id))
                              }
                              key={i}
                              className={s.suggestedProduct}
                            >
                              <div className={s.image}>
                                <ImageTag src={IMAGE_HOST + images[0]} alt='' />
                              </div>
                              <div className={s.productName + ' ellipsis'}>{productName}</div>
                            </Link>
                          ))}
                        </div>
                      ))}
                    </Swiper>
                  </div>
                ) : (
                  <div className={s.suggestionsAlt} style={{ display: !matches ? 'none' : 'block' }}>
                    <Swiper
                      infinite
                      timer={3000}
                      carouselType='productAlt'
                      paginationOn={false}
                      navArrowsOn={true}
                      arrowsInside={false}
                      borderTopRadius={false}
                    >
                      {suggestedProducts.map(({ _id, businessName, productName, images }, i) => (
                        <Link
                          to={
                            (isUserCustomDomain ? '/products/' : '/' + businessName + '/products/') +
                            window.btoa(JSON.stringify(_id))
                          }
                          key={i}
                          className={s.suggestedProduct}
                        >
                          <div className={s.image}>
                            <ImageTag src={IMAGE_HOST + images[0]} alt='' />
                          </div>
                          <div className={s.productName + ' ellipsis'} style={{ WebkitLineClamp: 2 }}>
                            {productName}
                          </div>
                        </Link>
                      ))}
                    </Swiper>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
