import s from './styles.module.scss'
import { head } from 'helpers'
import { useEffect, useRef, useState } from 'react'
import { Alert, Form, Input, Modal } from 'components'
import md5 from 'md5'
import * as api from 'api'

export default function Main() {
  const processing = useRef()
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [eyeVisible1, setEyeVisible1] = useState(false)
  const [eyeVisible2, setEyeVisible2] = useState(false)

  useEffect(() => {
    head({ title: 'Change Password | StashBlog Admin' })
  }, [])

  const onSubmit = async () => {
    if (processing.current) return

    if (!currentPassword) return Alert.warn('Current password required!')
    if (!newPassword) return Alert.warn('New password required!')
    if (!confirmPassword) return Alert.warn('Re-enter new password!')

    processing.current = true

    const changePassword = await api.auth.changePassword({
      password: md5(currentPassword.trim()),
      newPassword: md5(newPassword.trim())
    })

    if (changePassword.code !== 'OK') Alert.error('Please enter correct credentials')
    else Alert.success(changePassword.success)

    setCurrentPassword('')
    setNewPassword('')
    setConfirmPassword('')

    processing.current = false
  }

  const modalHandler = () => Modal.confirm("Are you sure you want to change account's password?", onSubmit)

  return (
    <div className={s.main}>
      <div className={s.changePassword + ' indent'}>
        <div className={s.header}>
          <span className='material-icons-outlined'>lock</span>
          Change Password
        </div>
        <div className={s.content + ' innerScroll'}>
          <Form onSubmit={onSubmit} className={s.form}>
            <div className={s.password}>
              <Input.Admin
                type={eyeVisible1 ? 'text' : 'password'}
                iconName='lock'
                placeholder='Enter Current Password'
                value={currentPassword}
                onChange={e => setCurrentPassword(e.target.value)}
              />
              <span className='material-icons-outlined' onClick={() => setEyeVisible1(!eyeVisible1)}>
                {!!eyeVisible1 ? 'visibility_off' : 'visibility'}
              </span>
            </div>
            <div className={s.password}>
              <Input.Admin
                type={eyeVisible2 ? 'text' : 'password'}
                iconName='lock'
                placeholder='Enter New Password'
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
              />
              <span className='material-icons-outlined' onClick={() => setEyeVisible2(!eyeVisible2)}>
                {!!eyeVisible2 ? 'visibility_off' : 'visibility'}
              </span>
            </div>

            <div className={s.password}>
              <Input.Admin
                type='password'
                iconName='lock'
                placeholder='Re-enter New Password'
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
              />
              {!!confirmPassword && !!newPassword && (
                <span
                  className='material-icons-outlined'
                  style={{ color: newPassword === confirmPassword ? 'var(--c-primary)' : 'var(--c-red)' }}
                >
                  {newPassword === confirmPassword ? 'done' : 'close'}
                </span>
              )}
            </div>

            <div className={s.button}>
              <button
                onClick={modalHandler}
                disabled={
                  newPassword === '' ||
                  confirmPassword === '' ||
                  currentPassword === '' ||
                  newPassword !== confirmPassword
                }
              >
                Change Password
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}
