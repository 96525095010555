import FingerprintJS from '@fingerprintjs/fingerprintjs'

export default async function Main() {
  try {
    const fp = await FingerprintJS.load()
    const fingerprint = await fp.get()

    // The fingerprint object will contain the visitorId, components, and version.
    // We'll use the visitorId as the unique fingerprint.
    const uniqueFingerprint = fingerprint.visitorId
    return uniqueFingerprint
  } catch (error) {
    console.error('Error obtaining fingerprint:', error)
    return null
  }
}
