import { Alert, Filter, InfiniteScroll, Loader, NoData, ProductCard } from 'components'
import s from './styles.module.scss'
// import images from 'images'
import { useCallback, useRef, useState } from 'react'
import * as api from 'api'
import { getBusinessName, isCustomDomain } from 'helpers'

const perPage = 12

const filterFields = [
  { field: 'Date, new to old' },
  { field: 'Date, old to new' },
  { field: 'Alphabetically A-Z' },
  { field: 'Alphabetically Z-A' }
]

export default function Main() {
  const [heading, setHeading] = useState('Date, new to old')
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const userProfile = getBusinessName()
  const isUserCustomDomain = isCustomDomain()
  const processing = useRef(false)

  const pageDataHandler = useCallback(
    async (sort, currPage) => {
      if (processing.current) {
        return
      }

      processing.current = true
      setLoading(true)
      setCurrentPage(currPage + 1)

      const userProducts = await api.products.fetchAllUsersProducts({
        pageNo: currPage,
        isPromoted: false,
        businessName: userProfile,
        sort: sort
      })

      if (userProducts.code === 'OK') {
        setProducts([...products, ...userProducts.products])
        setTotalPages(Math.ceil(userProducts.count / perPage))
      } else {
        Alert.error(userProducts.error)
      }

      processing.current = false
      setLoading(false)
    },
    [products, userProfile]
  )

  const filterHandler = field => {
    setProducts([])
    setCurrentPage(1)
    setHeading(field)
  }

  return (
    <div className={s.main}>
      <div className={s.heading}>Products</div>

      <Filter title='sort' heading={heading} filterFields={filterFields} filterHandler={filterHandler} />

      {!loading && products.length === 0 && <NoData color='var(--c-font)' />}

      <InfiniteScroll
        next={pageDataHandler}
        filter={heading}
        currentPage={currentPage}
        hasMore={currentPage <= totalPages}
        loader={<Loader color='var(--c-primary)' colorText='var(--c-font)' />}
      >
        {products.length !== 0 && (
          <>
            <div className={s.productCards}>
              {products.map(({ _id, productName, businessName, description, images }, i) => (
                <ProductCard
                  name={productName}
                  desc={description}
                  images={images}
                  productLink={
                    (isUserCustomDomain ? '/products/' : '/' + businessName + '/products/') +
                    window.btoa(JSON.stringify(_id))
                  }
                  key={i}
                />
              ))}
              {/* <Illus products={products} /> */}
            </div>
          </>
        )}
      </InfiniteScroll>

      {!!loading && <Loader color='var(--c-primary)' colorText='var(--c-font)' />}
    </div>
  )
}

// const Illus = ({ products }) => <IllusArt iterate={Math.ceil(products.length / 2) - 7} />

// const IllusArt = ({ iterate }) =>
//   iterate > 0 && (
//     <div className={s.illus}>
//       {Array.apply(0, Array(iterate)).map(function (x, index) {
//         return (
//           <div
//             key={index}
//             style={
//               index % 2 === 0
//                 ? { top: `${12 + 36 * Math.floor(index / 2)}rem` }
//                 : { top: `${28 + 36 * Math.floor(index / 2)}rem` }
//             }
//           >
//             <img src={images.illusDesign1} alt='' />
//           </div>
//         )
//       })}
//     </div>
//   )
