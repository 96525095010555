import { useState } from 'react'
import s from './styles.module.scss'

const DELAY = 3000
const MAX_MESSAGES = 3

var $messages, $setMessages

const handler = (text = '', color = '') => {
  const messages = text ? $messages.concat({ text, color, ts: Date.now() }) : $messages

  $setMessages(messages.filter(message => Date.now() <= message.ts + DELAY).slice(-MAX_MESSAGES))

  setTimeout(handler, DELAY + 1)
}

const Main = {
  error: (text = 'Dummy error message!') => handler(text, 'red'),
  info: (text = 'Dummy info message!') => handler(text, 'blue'),
  success: (text = 'Dummy success message!') => handler(text, 'green'),
  warn: (text = 'Dummy warning message!') => handler(text, 'yellow'),
  // Set timeout is used to avoid immediate rerendering
  clear: () => ($messages.length ? setTimeout(() => $setMessages([])) : null)
}

Main.Component = function Component() {
  const [messages, setMessages] = useState([])

  $messages = messages
  $setMessages = setMessages

  return (
    <div className={s.main}>
      {$messages.map(message => (
        <div key={message.ts} className={s.message} style={{ backgroundColor: `var(--message-${message.color})` }}>
          {message.text}
        </div>
      ))}
    </div>
  )
}

export default Main
