import handler from 'api/handler'

const prefix = '/auth'
// const updatedAt = Date.now()

const methods = {
  activateUserCatalogues: args => handler({ method: 'put', url: prefix + '/activeUserCatalogues', args }),
  activateUserProducts: args => handler({ method: 'put', url: prefix + '/activeUserProducts', args }),
  changePassword: args => handler({ method: 'put', url: prefix + '/changePassword', args }),
  // deleteUser: args => handler({ method: 'delete', url: prefix + '/deleteAccount', args }),
  getUser: args => handler({ method: 'get', url: prefix + '/getUser', args }),
  loginUser: args => handler({ method: 'post', url: prefix + '/login', args }),
  updateUserAboutUsDetails: args =>
    handler({ method: 'put', url: prefix + '/updateUserAboutUsDetails', args, isFile: true }),
  updateUserDetails: args => handler({ method: 'put', url: prefix + '/updateUserDetails', args, isFile: true }),
  updateUserHomeDetails: args => handler({ method: 'put', url: prefix + '/updateUserHomeDetails', args, isFile: true })
}

export default methods
