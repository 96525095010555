import images from 'images'
import s from './styles.module.scss'
import { useState } from 'react'

const features = [
  {
    label: 'Fast',
    image: images.fast,
    content:
      'At Stashblog, we believe in the power of speed. Our platform is engineered to provide an unparalleled fast-paced experience for both you and your visitors. Say goodbye to lag times and slow loading pages. With Stashblog, your website will operate at lightning speed, ensuring that your products are showcased and managed swiftly and efficiently. Experience the difference of a platform that prioritizes speed without compromising on quality. Your website will be up and running in no time, delivering a seamless and responsive user experience.'
  },
  {
    label: 'Reliable',
    image: images.reliable,
    content:
      "Reliability is the cornerstone of Stashblog. We understand that your online presence is not just a showcase, it's your digital identity. That's why our platform is built with a strong emphasis on reliability. From showcasing your products to managing your operations, you can count on Stashblog to be your steadfast partner. With robust hosting, dependable uptime, and a support team ready to assist you, rest easy knowing that your website will always be accessible and functional. Trust in a platform that prioritizes your peace of mind and ensures that your digital journey is marked by unwavering dependability."
  },
  {
    label: 'Simple',
    image: images.simple,
    content:
      "Simplicity is at the heart of Stashblog. We believe that technology should never be a barrier to your creative vision. Our platform is designed to make the process of creating, managing, and showcasing your products as straightforward as possible. With an intuitive user interface and user-friendly tools, you don't need to be a tech expert to craft a stunning website. Whether you're customizing layouts, adding products, or performing essential operations, simplicity is the guiding principle that ensures you're always in control. Experience the joy of a platform that simplifies the complex, allowing you to focus on what truly matters - bringing your unique ideas to life."
  },
  {
    label: 'Scalable',
    image: images.scalable,
    content:
      "At Stashblog, we understand that your business's success is dynamic and ever-evolving. That's why we're proud to offer not only customizable features but also scalable solutions that adapt to your growth. Whether you're just starting out or experiencing rapid expansion, our platform ensures that your website and operations can seamlessly scale to meet your demands. With Stashblog, your ambitions are never limited by technology - we grow with you."
  },
  {
    label: 'Secure',
    image: images.secure,
    content:
      "Security is non-negotiable at Stashblog. We understand that your products and operations are valuable assets that deserve the utmost protection. Our platform is fortified with advanced security features to ensure your website and customer data remain safe from cyber threats. Every aspect of your online presence is guarded against unauthorized access. With Stashblog, you can operate confidently, knowing that your business's security is a top priority. Experience the peace of mind that comes with a platform that takes security as seriously as you do, allowing you to focus on growing your business without compromising on safety."
  }
]

export default function Main() {
  const [featureInfo, setFeatureInfo] = useState({ label: features[0].label, content: features[0].content })

  return (
    <div className={s.main}>
      <div className={s.featuresSection}>
        <div className={s.content + ' indent'}>
          <div className={s.heading}>StashBlog Features</div>
        </div>

        <div className={s.back}>
          <div className={s.content + ' indent'}>Here are some features stashblog offer</div>
        </div>
        <div className={s.content + ' indent ' + s.featureBody}>
          <div className={s.featureCards}>
            {features.map(({ label, image, content }, i) => (
              <Features label={label} image={image} content={content} setFeatureInfo={setFeatureInfo} key={i} />
            ))}
          </div>
          <div className={s.featureInfo}>
            <div>{featureInfo.label}</div>
            <div>{featureInfo.content}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Features = ({ label, image, content, setFeatureInfo }) => (
  <div className={s.feature} onClick={() => setFeatureInfo({ label, content })}>
    <div className={s.featureBox}>
      <img src={image} alt='' />
    </div>
    <div className={s.featureName}>{label}</div>
  </div>
)
