import s from './styles.module.scss'

export default function Main(props) {
  return (
    <div className={s.field}>
      <span className={'material-icons-outlined ' + (props.dark ? s.icon + ' ' + s.iconAlt : s.icon)}>
        {props.iconName}
      </span>
      <input
        className={props.dark ? s.input + ' ' + s.inputAlt : s.input}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        autoComplete='off'
      />
      {!!props.label && <label className={props.dark ? s.label + ' ' + s.labelAlt : s.label}>{props.label}</label>}
    </div>
  )
}
