import s from './styles.module.scss'
import images from 'images'
import { useState } from 'react'

const serviceData = [
  {
    service: 'Listing',
    image: images.listing,
    contentHead: 'Product Showcase',
    content:
      'Display your products in all their glory. Upload high-quality images, write engaging descriptions, and create an immersive shopping experience for your visitors.'
  },
  {
    service: 'Management',
    image: images.management,
    contentHead: 'Comprehensive Cataloguing',
    content:
      "Organize your products effortlessly using our intuitive cataloguing system. Categorize, tag, and sort your offerings to ensure visitors can easily find what they're looking for."
  },
  {
    service: 'Insights',
    image: images.insights,
    contentHead: 'Analytics and Insights',
    content:
      "Monitor your website's performance with detailed analytics. Understand visitor behavior, track conversions, and make informed decisions to improve your online strategy."
  },
  {
    service: 'Marketing',
    image: images.marketing,
    contentHead: 'Built-in SEO',
    content:
      "Get noticed on search engines with our built-in search engine optimization tools. Enhance your website's visibility and attract more visitors."
  },
  {
    service: 'Responsive',
    image: images.responsive,
    contentHead: 'Responsive Design',
    content:
      'Your website will look stunning on any device. Our responsive design ensures a seamless experience for both desktop and mobile users.'
  }
]

export default function Main() {
  const [selectedServiceData, setSelectedServiceData] = useState(serviceData[0])
  const [isAlt, setIsAlt] = useState(false)

  return (
    <div className={s.main}>
      <div className={s.servicesSection + ' indent'}>
        <div className={s.heading}>Our Services</div>
        <div className={s.content}>
          <div className={s.innerNav}>
            {serviceData.map((serviceDataField, i) => (
              <Service
                serviceDataField={serviceDataField}
                selectedServiceData={selectedServiceData}
                setSelectedServiceData={setSelectedServiceData}
                index={i}
                setIsAlt={setIsAlt}
                key={i}
              />
            ))}
          </div>
          <div className={isAlt ? s.navBody : s.navBody + ' ' + s.navBodyAlt}>
            <div className={s.image}>
              <img src={selectedServiceData.image} alt='' />
            </div>
            <div className={s.navContent}>
              <div className={s.heading}>{selectedServiceData.contentHead}</div>
              <div className={s.desc}>{selectedServiceData.content}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Service = ({ serviceDataField, selectedServiceData, setSelectedServiceData, index, setIsAlt }) => (
  <div
    className={serviceDataField.service === selectedServiceData.service ? s.service + ' ' + s.active : s.service}
    onClick={() => {
      if (index % 2 !== 0) setIsAlt(true)
      else setIsAlt(false)

      if (serviceDataField.service !== selectedServiceData.service) setSelectedServiceData(serviceDataField)
    }}
  >
    {serviceDataField.service}
  </div>
)
