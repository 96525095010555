import { useState } from 'react'
import { Link } from 'react-router-dom'
import s from './styles.module.scss'
import { ImageTag } from 'components'
import { isCustomDomain } from 'helpers'

const IMAGE_HOST = process.env.REACT_APP_IMAGE_HOST

export default function Main(props) {
  return (
    <div className={s.table}>
      <div className={s.tHead}>
        <div className={s.id}>ID</div>
        <div className={s.image}>Image</div>
        <div className={s.productName}>Name</div>
        {/* <div className={s.isPublic}>Public</div> */}
        <div className={s.last}></div>
      </div>

      <div className={s.tBody}>
        {props.data.length !== 0 && (
          <TableBodyData
            data={props.data}
            perPage={props.perPage}
            currentPage={props.currentPage}
            tableCatalogue={props.tableCatalogue}
            statusHandler={props.statusHandler}
            promoteHandler={props.promoteHandler}
            deleteHandler={props.deleteHandler}
          />
        )}
      </div>
    </div>
  )
}

const TableBodyData = props => {
  const isUserCustomDomain = isCustomDomain()

  return props.data.map(
    (
      { _id, businessName, category, productName, description, isPublic, isPromoted, images, totalUnits, pricePerUnit },
      i
    ) => (
      <TableElements
        productId={_id}
        category={category}
        productName={productName}
        description={description}
        isPublic={isPublic}
        isPromoted={isPromoted}
        perPage={props.perPage}
        currentPage={props.currentPage}
        index={i}
        link={
          (isUserCustomDomain ? '/products/' : '/' + businessName + '/products/') + window.btoa(JSON.stringify(_id))
        }
        images={images}
        totalUnits={totalUnits}
        pricePerUnit={pricePerUnit}
        statusHandler={props.statusHandler}
        promoteHandler={props.promoteHandler}
        deleteHandler={props.deleteHandler}
        key={i}
      />
    )
  )
}

const TableElements = props => {
  const [isPublic, setIsPublic] = useState(props.isPublic)
  const [isPromoted, setIsPromoted] = useState(props.isPromoted)

  return (
    <div>
      <div className={s.id}>
        #{props.currentPage > 1 ? (props.currentPage - 1) * props.perPage + props.index + 1 : props.index + 1}
      </div>
      <div className={s.image}>
        <span>
          <ImageTag src={IMAGE_HOST + props.images[0]} alt='' />
        </span>
      </div>
      <div className={s.productName} title={props.productName}>
        <a href={props.link} className='ellipsis' target='_blank' rel='noreferrer'>
          {/* {props.productName.length >= 20 ? props.productName.substring(0, 20) + '...' : props.productName} */}
          {props.productName}
        </a>
      </div>
      {/* <div className={s.isPublic}>
        <span>
          <span
            className='material-icons'
            style={{ color: isPublic && 'var(--c-green)' }}
            title={!isPublic ? 'Mark Public' : 'Mark Private'}
            onClick={() => {
              props.statusHandler(props.productId)
              setIsPublic(!isPublic)
            }}
          >
            {!isPublic ? 'toggle_off' : 'toggle_on'}
          </span>
        </span>
      </div> */}
      <div className={s.actionIcons}>
        <span
          className='material-icons'
          style={{ color: isPublic && 'var(--c-green)', margin: !isPublic ? '0 0.25rem' : '0 0.5rem' }}
          title={!isPublic ? 'Mark Public' : 'Mark Private'}
          onClick={() => {
            props.statusHandler(props.productId)
            setIsPublic(!isPublic)
          }}
        >
          {!isPublic ? 'toggle_off' : 'toggle_on'}
        </span>
        <span
          className='material-icons-outlined'
          title='Delete'
          onClick={() => props.deleteHandler(props.productId)}
          style={{ margin: !isPublic ? '0 0.25rem' : '0 0.5rem' }}
        >
          delete
        </span>
        <Link
          className='material-icons-outlined'
          title='Edit'
          to={'/admin/products/editProduct/' + window.btoa(JSON.stringify(props.productId))}
          style={{ margin: !isPublic ? '0 0.25rem' : '0 0.5rem' }}
        >
          edit
        </Link>
        <span
          className='material-icons-outlined'
          title={!isPromoted ? 'Promote' : 'Demote'}
          style={{
            color: isPromoted ? 'var(--c-golden)' : 'var(--c-font-light1)',
            margin: !isPublic ? '0 0.25rem' : '0 0.5rem'
          }}
          onClick={() => {
            props.promoteHandler(props.productId)
            setIsPromoted(!isPromoted)
          }}
        >
          star
          {/* verified_user */}
        </span>
        {!isPublic && (
          <Link
            className='material-icons-outlined'
            title='Private Link'
            to={'/admin/products/privateLink/' + window.btoa(JSON.stringify(props.productId))}
            style={{ color: 'var(--c-green)', margin: '0 0.25rem' }}
          >
            add_link
          </Link>
        )}
      </div>
    </div>
  )
}
