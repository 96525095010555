import { SignForm } from 'components'
import { getBusinessName, head } from 'helpers'
import { useEffect } from 'react'

export default function Main() {
  const userProfile = getBusinessName()

  useEffect(() => {
    head({ title: 'Sign In | ' + (!userProfile ? ' StashBlog' : userProfile) })
  }, [userProfile])

  return <SignForm formType='Signin' />
}
