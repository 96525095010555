import { Alert, ImageTag } from 'components'
import s from './styles.module.scss'
import { isCustomDomain } from 'helpers'

const IMAGE_HOST = process.env.REACT_APP_IMAGE_HOST

export default function Main(props) {
  return (
    <div className={s.table}>
      <div className={s.tHead}>
        <div className={s.id}>ID</div>
        <div className={s.image}>Image</div>
        <div className={s.productName}>Name</div>
        <div className={s.last}></div>
      </div>

      <div className={s.tBody}>
        {props.data.length !== 0 && (
          <TableBodyData
            data={props.data}
            perPage={props.perPage}
            currentPage={props.currentPage}
            productHandler={props.productHandler}
            add={props.add}
            disable={props.isLastPage && (props.add ? false : true)}
          />
        )}
      </div>
    </div>
  )
}

const TableBodyData = props => {
  const isUserCustomDomain = isCustomDomain()

  return props.data.map(
    ({ _id, businessName, category, productName, description, isPublic, images, totalUnits, pricePerUnit }, i) => (
      <TableElements
        productId={_id}
        category={category}
        productName={productName}
        description={description}
        perPage={props.perPage}
        currentPage={props.currentPage}
        index={i}
        isPublic={isPublic}
        link={
          (isUserCustomDomain ? '/products/' : '/' + businessName + '/products/') + window.btoa(JSON.stringify(_id))
        }
        images={images}
        totalUnits={totalUnits}
        pricePerUnit={pricePerUnit}
        productHandler={props.productHandler}
        disable={props.disable}
        add={props.add}
        key={i}
      />
    )
  )
}

const TableElements = props => {
  return (
    <div>
      <div className={s.id}>
        #{props.currentPage > 1 ? (props.currentPage - 1) * props.perPage + props.index + 1 : props.index + 1}
      </div>
      <div className={s.image}>
        <span>
          <ImageTag src={IMAGE_HOST + props.images[0]} alt='' />
        </span>
      </div>
      <div className={s.productName + ' ellipsis'}>
        <a href={props.link} title={props.productName} target='_blank' rel='noreferrer'>
          {/* {props.productName.length >= 20 ? props.productName.substring(0, 20) + '...' : props.productName} */}
          {props.productName}
        </a>
      </div>
      <div className={s.actionIcons}>
        <span
          className='material-icons-outlined'
          title={props.add ? 'Add' : 'Remove'}
          onClick={() =>
            !props.disable ? props.productHandler(props.productId) : Alert.warn('Minimum 3 products required')
          }
          style={{
            color: props.add ? 'var(--c-blue)' : props.disable ? 'var(--c-font)' : 'var(--c-red)',
            opacity: props.disable ? '0.3' : '1'
          }}
        >
          {props.add ? 'add_circle_outline' : 'cancel'}
        </span>
      </div>
    </div>
  )
}
