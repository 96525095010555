import { useCallback, useEffect, useRef, useState } from 'react'
import s from './styles.module.scss'
import * as api from 'api'
import { head } from 'helpers'
import { useParams } from 'react-router-dom'
import { Alert, Input, Loader, NoData, Pagination, Table } from 'components'

const perPage = 12

export default function Main() {
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPage2, setCurrentPage2] = useState(1)
  const [currentSearchedPage1, setCurrentSearchedPage1] = useState(1)
  const [currentSearchedPage2, setCurrentSearchedPage2] = useState(1)
  const [searchData1, setSearchData1] = useState([])
  const [searchData2, setSearchData2] = useState([])
  const [search1, setSearch1] = useState('')
  const [search2, setSearch2] = useState('')
  const [searchResults1, setSearchResults1] = useState(0)
  const [searchResults2, setSearchResults2] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [totalProductPages, setTotalProductPages] = useState(0)
  const [totalSearchedPages1, setTotalSearchedPages1] = useState(0)
  const [totalSearchedPages2, setTotalSearchedPages2] = useState(0)
  const [catalogue, setCatalogue] = useState({})
  const [catalogueProducts, setCatalogueProducts] = useState([])
  const [addProducts, setAddProducts] = useState([])
  const { catalogueId } = useParams()

  const processing = useRef()

  const addProductsHandler = useCallback(async (currPage, id) => {
    setCurrentPage2(currPage)

    const fetchProducts = await api.products.fetchUserProducts({
      pageNo: currPage
    })

    if (fetchProducts.code === 'OK') {
      setTotalProductPages(Math.ceil(fetchProducts.count / perPage))
      setAddProducts(fetchProducts.products.filter(x => !id.includes(x._id)))
    } else {
      Alert.error(fetchProducts.error)
    }
  }, [])

  const catalogueProductsHandler = useCallback(async (currPage, id) => {
    setCurrentPage(currPage)

    const fetchCatalogueProducts = await api.products.fetchUserProducts({
      pageNo: currPage,
      id
    })

    if (fetchCatalogueProducts.code === 'OK') {
      setTotalPages(Math.ceil(fetchCatalogueProducts.count / perPage))
      setCatalogueProducts(fetchCatalogueProducts.products)
    } else {
      Alert.error(fetchCatalogueProducts.error)
    }
  }, [])

  const pageDataHandler = useCallback(async () => {
    if (processing.current) {
      return
    }
    processing.current = true
    setLoading(true)

    const fetchCatalogue = await api.catalogues.fetchUserCatalogues({
      id: JSON.parse(window.atob(catalogueId))
    })

    if (fetchCatalogue.code === 'OK') {
      setCatalogue(fetchCatalogue.catalogues[0])
      catalogueProductsHandler(currentPage, fetchCatalogue.catalogues[0].products)
      addProductsHandler(currentPage2, fetchCatalogue.catalogues[0].products)
    } else {
      Alert.error(fetchCatalogue.error)
    }
    processing.current = false
    setLoading(false)
  }, [catalogueId, currentPage, catalogueProductsHandler, addProductsHandler, currentPage2])

  useEffect(() => {
    head({ title: 'Catalogue Products | StashBlog Admin' })
  }, [])

  useEffect(() => {
    pageDataHandler()
    // eslint-disable-next-line
  }, [])

  const productHandler = id => {
    setAddProducts(addProducts.concat(catalogueProducts.find(x => x._id === id)))
    setCatalogueProducts(catalogueProducts.filter(x => x._id !== id))
  }

  const productHandler2 = id => {
    setCatalogueProducts(catalogueProducts.concat(addProducts.find(x => x._id === id)))
    setAddProducts(addProducts.filter(x => x._id !== id))
  }

  const searchHandler1 = async currPage => {
    if (processing.current) return
    processing.current = true
    setLoading(true)
    setCurrentSearchedPage1(currPage)

    const searchedCatalogueProduct = await api.products.fetchUserProducts({
      pageNo: currPage,
      productName: search1
    })

    if (searchedCatalogueProduct.code === 'OK') {
      if (searchedCatalogueProduct.products.length === 0) {
        setSearchData1([])
        setSearch1('')
        setCurrentSearchedPage1(1)
        Alert.warn('No result found')
      } else {
        let data = searchedCatalogueProduct.products.filter(x => catalogue.products.some(y => y._id === x._id))
        if (data.length === 0) {
          Alert.warn('No result found')
          setSearchData1([])
          setSearch1('')
          setCurrentSearchedPage1(1)
        } else {
          setSearchResults1(data.length)
          setTotalSearchedPages1(Math.ceil(data.length / perPage))
          setSearchData1(data)
        }
      }
    } else {
      Alert.warn(searchedCatalogueProduct.error)
    }

    setLoading(false)
    processing.current = false
  }

  const searchHandler2 = async currPage => {
    if (processing.current) return
    processing.current = true
    setLoading(true)
    setCurrentSearchedPage2(currPage)

    const searchedCatalogueProduct = await api.products.fetchUserProducts({
      pageNo: currPage,
      productName: search2
    })

    if (searchedCatalogueProduct.code === 'OK') {
      if (searchedCatalogueProduct.products.length === 0) {
        setSearchData2([])
        setSearch2('')
        setCurrentSearchedPage2(1)
        Alert.warn('No result found')
      } else {
        let data = searchedCatalogueProduct.products.filter(x => !catalogue.products.some(y => y._id === x._id))
        if (data.length === 0) {
          Alert.warn('No result found')
          setSearchData2([])
          setSearch2('')
          setCurrentSearchedPage2(1)
        } else {
          setSearchResults2(data.length)
          setTotalSearchedPages2(Math.ceil(data.length / perPage))
          setSearchData2(data)
        }
      }
    } else {
      Alert.warn(searchedCatalogueProduct.error)
    }

    setLoading(false)
    processing.current = false
  }

  const saveHandler = async () => {
    if (processing.current) {
      return
    }
    processing.current = true
    setLoading(true)

    const updateCatalogueProducts = await api.catalogues.updateCatalogue({
      id: JSON.parse(window.atob(catalogueId)),
      products: catalogueProducts.map(x => x._id)
    })
    if (updateCatalogueProducts.code === 'OK') {
      Alert.success('Catalogue updated successfully')
    } else {
      Alert.error(updateCatalogueProducts.error)
    }

    processing.current = false
    setLoading(false)
  }

  return (
    <div className={s.main}>
      <div className={s.catalogueProducts + ' indent'}>
        <div className={s.header} id='nav'>
          <div>
            <span className='material-icons-outlined'>view_carousel</span>

            {!loading && catalogue.length !== 0 && catalogue.catalogueName + ' Products'}
          </div>
          <div className={s.save} onClick={saveHandler}>
            save
          </div>
        </div>
        <div
          className={s.content + ' innerScroll'}
          id='carousel'
          // style={{ justifyContent: loading ? 'center' : 'flex-start' }}
        >
          <ProductsInCatalogue
            loading={loading}
            catalogueProducts={catalogueProducts}
            search1={search1}
            setSearch1={setSearch1}
            setSearchData1={setSearchData1}
            searchHandler1={searchHandler1}
            currentSearchedPage1={currentSearchedPage1}
            searchData1={searchData1}
            searchResults1={searchResults1}
            productHandler={productHandler}
            currentPage={currentPage}
            totalPages={totalPages}
            totalSearchedPages1={totalSearchedPages1}
            catalogueProductsHandler={catalogueProductsHandler}
          />

          <AvailableProducts
            loading={loading}
            addProducts={addProducts}
            catalogueProducts={catalogueProducts}
            search2={search2}
            setSearch2={setSearch2}
            setSearchData2={setSearchData2}
            searchHandler2={searchHandler2}
            currentSearchedPage2={currentSearchedPage2}
            searchData2={searchData2}
            searchResults2={searchResults2}
            productHandler2={productHandler2}
            currentPage2={currentPage2}
            totalProductPages={totalProductPages}
            totalSearchedPages2={totalSearchedPages2}
            addProductsHandler={addProductsHandler}
          />
        </div>
      </div>
    </div>
  )
}

const ProductsInCatalogue = ({
  loading,
  catalogueProducts,
  search1,
  setSearch1,
  setSearchData1,
  searchHandler1,
  currentSearchedPage1,
  searchData1,
  searchResults1,
  productHandler,
  currentPage,
  totalPages,
  totalSearchedPages1,
  catalogueProductsHandler
}) => (
  <>
    {!!loading && <Loader color='var(--c-primary)' colorText='var(--c-font-dark)' />}

    {!loading && <div className={s.available}>Products in Catalogue</div>}

    {!loading && catalogueProducts.length === 0 && <NoData color='var(--c-font)' />}

    {!loading && catalogueProducts.length !== 0 && (
      <>
        <Input.Search
          type='text'
          placeholder='Search...'
          iconName='search'
          value={search1}
          onChange={e => {
            setSearch1(e.target.value)
            e.target.value === '' && setSearchData1([])
          }}
          onKeyDown={e => {
            if (e.key === 'Enter' && search1 !== '') {
              searchHandler1(currentSearchedPage1)
            }
          }}
          onClick={() => {
            search1 !== '' && searchHandler1(currentSearchedPage1)
          }}
        />
        {searchData1.length !== 0 && <div className={s.searchResults}>{searchResults1} search results found</div>}
        {searchData1.length === 0 ? (
          <Table.CatalogueProducts
            data={catalogueProducts}
            currentPage={currentPage}
            perPage={perPage}
            productHandler={productHandler}
            isLastPage={currentPage === totalPages && catalogueProducts.length <= 3}
          />
        ) : (
          <Table.CatalogueProducts
            data={searchData1}
            currentPage={currentSearchedPage1}
            perPage={perPage}
            productHandler={productHandler}
            isLastPage={currentSearchedPage1 === totalSearchedPages1 && catalogueProducts.length <= 3}
          />
        )}
      </>
    )}

    {!loading && searchData1.length === 0 && totalPages > 1 && (
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        pageDataHandler={currPage =>
          catalogueProductsHandler(
            currPage,
            catalogueProducts.map(x => x._id)
          )
        }
        adminPagination={true}
      />
    )}

    {!loading && searchData1.length !== 0 && totalSearchedPages1 > 1 && (
      <Pagination
        currentPage={currentSearchedPage1}
        totalPages={totalSearchedPages1}
        pageDataHandler={searchHandler1}
        adminPagination={true}
      />
    )}
  </>
)

const AvailableProducts = ({
  loading,
  addProducts,
  catalogueProducts,
  search2,
  setSearch2,
  setSearchData2,
  searchHandler2,
  currentSearchedPage2,
  searchData2,
  searchResults2,
  totalSearchedPages2,
  productHandler2,
  currentPage2,
  totalProductPages,
  addProductsHandler
}) => (
  <>
    {!!loading && <Loader color='var(--c-primary)' colorText='var(--c-font-dark)' />}

    {!loading && <div className={s.available}>Available Products</div>}

    {!loading && addProducts.length === 0 && <NoData color='var(--c-font)' />}

    {!loading && addProducts.length !== 0 && (
      <>
        <Input.Search
          type='text'
          placeholder='Search...'
          iconName='search'
          value={search2}
          onChange={e => {
            setSearch2(e.target.value)
            e.target.value === '' && setSearchData2([])
          }}
          onKeyDown={e => {
            if (e.key === 'Enter' && search2 !== '') {
              searchHandler2(currentSearchedPage2)
            }
          }}
          onClick={() => {
            search2 !== '' && searchHandler2(currentSearchedPage2)
          }}
        />

        {searchData2.length !== 0 && <div className={s.searchResults}>{searchResults2} search results found</div>}
        {searchData2.length === 0 ? (
          <Table.CatalogueProducts
            data={addProducts}
            currentPage={currentPage2}
            perPage={perPage}
            productHandler={productHandler2}
            isLastPage={currentPage2 === totalProductPages && totalProductPages === 1}
            add={true}
          />
        ) : (
          <Table.CatalogueProducts
            data={searchData2}
            currentPage={currentSearchedPage2}
            perPage={perPage}
            productHandler={productHandler2}
            isLastPage={currentSearchedPage2 === totalSearchedPages2 && totalSearchedPages2 === 1}
            add={true}
          />
        )}
      </>
    )}

    {!loading && searchData2.length === 0 && totalProductPages > 1 && (
      <Pagination
        currentPage={currentPage2}
        totalPages={totalProductPages}
        pageDataHandler={currPage => {
          addProductsHandler(
            currPage,
            catalogueProducts.map(x => x._id)
          )
        }}
        adminPagination={true}
      />
    )}

    {!loading && searchData2.length !== 0 && totalSearchedPages2 > 1 && (
      <Pagination
        currentPage={currentSearchedPage2}
        totalPages={totalSearchedPages2}
        pageDataHandler={searchHandler2}
        adminPagination={true}
      />
    )}
  </>
)
