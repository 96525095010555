import { Link } from 'react-router-dom'
import s from './styles.module.scss'
import images from 'images'
import { useEffect, useRef, useState } from 'react'
import { ImageTag } from 'components'

const IMAGE_HOST = process.env.REACT_APP_IMAGE_HOST

export default function Main(props) {
  const socialLinksRef = useRef(null)
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    const socialLinksElement = socialLinksRef.current

    const handleScroll = e => {
      e.preventDefault()
      socialLinksElement.scrollLeft += e.deltaY
    }

    socialLinksElement.addEventListener('wheel', handleScroll, { passive: false })

    return () => {
      socialLinksElement.removeEventListener('wheel', handleScroll)
    }
  }, [])

  let count = 0
  if (props.facebookLink) count += 1
  if (props.instagramLink) count += 1
  if (props.pinterestLink) count += 1
  if (props.linkedInLink) count += 1
  if (props.youtubeChannelLink) count += 1
  if (props.websiteLink) count += 1

  return (
    <div className={s.main}>
      <div className={s.profileCard}>
        <div className={s.logo}>
          <ImageTag src={IMAGE_HOST + props.logo} alt='' />
        </div>
        <div className={s.businessName + ' ellipsis'}>{props.businessName.split('-').join(' ')}</div>

        <Link to={'/' + props.businessName} className={s.visitLink}>
          visit
        </Link>

        <div
          className={s.socialLinks}
          style={{
            justifyContent: count <= 3 ? 'center' : 'flex-start',
            display: !!isExpanded ? 'flex' : 'none'
          }}
          ref={socialLinksRef}
        >
          {props.facebookLink && (
            <a
              href={props.facebookLink}
              target='_blank'
              rel='noopener noreferrer'
              className={s.facebookLink}
              style={{
                marginLeft: count > 3 ? '0.25rem' : '0'
              }}
            >
              <img src={images.facebookAlt} alt='' />
            </a>
          )}
          {props.instagramLink && (
            <a href={props.instagramLink} target='_blank' rel='noopener noreferrer' className={s.instagramLink}>
              <img src={images.instagramAlt} alt='' />
            </a>
          )}
          {props.pinterestLink && (
            <a href={props.pinterestLink} target='_blank' rel='noopener noreferrer' className={s.pinterestLink}>
              <img src={images.pinterest} alt='' />
            </a>
          )}
          {props.linkedInLink && (
            <a href={props.linkedInLink} target='_blank' rel='noopener noreferrer' className={s.linkedInLink}>
              <img src={images.linkedInAlt} alt='' />
            </a>
          )}
          {props.youtubeChannelLink && (
            <a
              href={props.youtubeChannelLink}
              target='_blank'
              rel='noopener noreferrer'
              className={s.youtubeChannelLink}
            >
              <img src={images.youtube} alt='' />
            </a>
          )}
          {props.websiteLink && (
            <a href={props.websiteLink} target='_blank' rel='noopener noreferrer' className={s.websiteLink}>
              <img src={images.website} alt='' />
            </a>
          )}
        </div>

        <div
          className={s.expand}
          onClick={() => setIsExpanded(!isExpanded)}
          style={{
            visibility: count >= 1 ? 'visible' : 'hidden'
          }}
        >
          <span className='material-icons-outlined '>{!!isExpanded ? 'expand_less' : 'expand_more'}</span>
        </div>
      </div>
    </div>
  )
}
