import s from './styles.module.scss'
import { getBusinessName, head } from 'helpers'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Alert, Modal } from 'components'
import * as api from 'api'
import { useNavigate } from 'react-router-dom'

export default function Main() {
  const navigate = useNavigate()
  const [user, setUser] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const processing = useRef()
  const userProfile = getBusinessName()

  const getUserInfo = useCallback(async () => {
    if (processing.current) return
    processing.current = true

    const fetchUser = await api.auth.getUser({})

    if (fetchUser.code === 'OK') {
      setUser(fetchUser.user.name)
      setUserEmail(fetchUser.user.email)
    } else {
      Alert.error(fetchUser.error)
    }
    processing.current = false
  }, [])

  useEffect(() => {
    getUserInfo()
  }, [getUserInfo])

  useEffect(() => {
    head({ title: 'Sign In | ' + !userProfile ? ' StashBlog' : userProfile })
  }, [userProfile])

  const logOutHandler = () => {
    Modal.confirm('Are you sure you want to logout?', async () => {
      await new Promise(resolve => {
        window.localStorage.clear()
        resolve()
      })

      navigate('/signIn', { replace: true })
    })
  }

  return (
    <div className={s.main}>
      <div className={s.signOut + ' indent'}>
        <div className={s.header}>
          <div>
            <span className='material-icons-outlined'>logout</span>
            Sign Out
          </div>
        </div>
        <div className={s.content + ' innerScroll'}>
          <div className={s.loggedUser}>{user}</div>
          <div className={s.loggedUserEmail}>{userEmail}</div>
          <div className={s.logOutButton} onClick={logOutHandler}>
            <span className='material-icons-outlined'>logout</span>
            <span className={s.button}>Sign Out</span>
          </div>
        </div>
      </div>
    </div>
  )
}
