const initialState = {
  user: null,
  loading: false
}

export default function Main(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_USER_PROFILE':
      return { ...state, user: action.payload, loading: false }
    case 'FETCH_USER_PROFILE_PENDING':
      return { ...state, loading: true }
    default:
      return state
  }
}
