import s from './styles.module.scss'

export default function Main({ color = 'var(--c-white)' }) {
  return (
    <div className={s.main}>
      <NoData color={color} />
    </div>
  )
}

const NoData = props => (
  <div className={s.image}>
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='512.000000pt'
      height='512.000000pt'
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)' fill={props.color} stroke='none'>
        <path
          d='M2934 3927 c-3 -8 -4 -54 -2 -103 2 -71 6 -89 18 -89 11 0 16 14 18
54 l3 54 32 -53 c31 -52 60 -80 72 -69 10 11 26 120 23 162 -2 29 -8 42 -18
42 -12 0 -16 -15 -18 -67 -2 -38 -7 -68 -11 -68 -5 0 -26 34 -48 75 -36 72
-58 91 -69 62z'
        />
        <path
          d='M3152 3902 c-22 -22 -37 -100 -26 -135 19 -64 76 -76 119 -26 22 27
25 40 23 82 -2 40 -8 53 -28 68 -41 29 -67 32 -88 11z m76 -76 c4 -31 -21 -76
-42 -76 -26 0 -34 57 -16 105 10 26 12 26 33 12 12 -8 23 -26 25 -41z'
        />
        <path
          d='M3353 3913 c-58 -4 -74 -27 -28 -39 23 -6 25 -11 25 -64 0 -67 7 -90
25 -90 10 0 13 19 12 78 -2 73 -1 77 21 80 15 2 22 9 20 19 -4 19 -8 20 -75
16z'
        />
        <path
          d='M3485 3907 c-3 -7 -2 -50 2 -97 6 -68 11 -85 25 -88 14 -3 17 6 20
45 3 46 4 48 33 48 27 0 31 -4 35 -35 3 -23 11 -36 23 -38 17 -4 18 2 11 80
-7 92 -9 98 -30 98 -9 0 -14 -11 -14 -35 0 -33 -2 -35 -34 -35 -31 0 -34 3
-40 35 -7 35 -22 46 -31 22z'
        />
        <path
          d='M4045 3900 c-27 -30 -32 -115 -10 -157 14 -25 25 -33 46 -33 32 0 72
43 67 73 -4 27 -41 39 -65 21 -17 -14 -17 -14 1 -29 22 -17 15 -31 -10 -21
-13 5 -16 16 -12 61 5 65 12 74 36 52 18 -16 32 -14 32 5 0 13 -39 48 -53 48
-8 0 -22 -9 -32 -20z'
        />
        <path
          d='M3691 3863 c1 -79 12 -127 32 -131 15 -3 17 5 16 55 -2 81 -11 117
-32 121 -14 3 -17 -4 -16 -45z'
        />
        <path
          d='M3940 3890 c-5 -10 -10 -35 -10 -54 0 -20 -3 -36 -7 -36 -5 0 -21 23
-36 51 -36 63 -58 63 -69 -1 -11 -62 -6 -105 12 -105 9 0 17 13 20 34 l5 34
25 -39 c45 -70 86 -41 97 70 4 43 2 54 -11 59 -10 4 -19 -1 -26 -13z'
        />
        <path
          d='M1845 3690 c-4 -7 -3 -16 3 -22 14 -14 45 -2 40 15 -6 16 -34 21 -43
7z'
        />
        <path
          d='M4028 3685 c-3 -3 -162 -10 -354 -15 -628 -17 -775 -23 -779 -30 -3
-4 2 -14 11 -21 19 -16 161 -16 694 1 581 17 600 19 568 57 -10 13 -129 19
-140 8z'
        />
        <path
          d='M2363 3580 c-3 -11 -12 -20 -19 -20 -20 0 -17 -13 7 -33 19 -17 22
-17 39 -2 16 15 17 19 3 46 -17 34 -23 36 -30 9z'
        />
        <path
          d='M3525 3517 c-3 -12 -5 -58 -3 -102 l3 -80 50 0 c38 0 50 4 50 15 0
10 -12 16 -32 18 -27 3 -33 7 -33 28 0 20 5 24 31 24 24 0 30 4 27 18 -2 10
-14 18 -31 20 -21 3 -27 9 -27 28 0 20 4 25 22 22 15 -2 24 3 26 15 3 14 -4
17 -37 17 -34 0 -42 -4 -46 -23z'
        />
        <path
          d='M3243 3519 c-12 -4 -27 -17 -33 -29 -12 -23 -5 -39 39 -89 28 -32 27
-39 -11 -49 -38 -10 -44 -35 -9 -40 57 -8 99 26 86 69 -4 11 -21 36 -38 56
l-32 36 23 15 c12 8 22 18 22 22 0 13 -24 18 -47 9z'
        />
        <path
          d='M3390 3523 c-24 -10 -30 -25 -30 -79 0 -109 19 -136 85 -120 43 11
30 43 -14 36 -15 -2 -31 17 -31 37 0 6 12 13 28 15 36 4 36 32 -1 36 -38 5
-33 36 6 40 20 3 27 8 25 20 -3 18 -41 26 -68 15z'
        />
        <path
          d='M4171 3513 c-22 -15 -23 -20 -17 -91 6 -71 18 -98 39 -85 5 3 7 19 4
36 l-4 31 23 -30 c13 -16 24 -32 24 -36 0 -5 7 -8 15 -8 23 0 18 27 -11 65
-15 21 -21 36 -15 40 15 9 14 68 -1 83 -16 16 -29 15 -57 -5z m26 -45 c-3 -8
-6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z'
        />
        <path
          d='M4330 3517 c-23 -11 -25 -19 -28 -84 -4 -87 11 -108 67 -98 20 4 39
12 43 18 11 18 -10 35 -37 29 -18 -3 -25 0 -25 12 0 10 9 16 25 16 16 0 25 6
25 15 0 10 -10 15 -30 15 -23 0 -30 4 -30 20 0 14 7 20 24 20 31 0 50 22 34
38 -15 15 -37 15 -68 -1z'
        />
        <path
          d='M4022 3495 c-16 -34 -15 -93 1 -125 10 -20 20 -25 52 -25 49 0 57 27
10 35 -19 3 -31 12 -33 24 -3 15 0 18 17 13 21 -5 46 14 34 26 -3 4 -17 7 -30
7 -32 0 -27 32 5 40 36 8 31 25 -9 28 -30 3 -37 -1 -47 -23z'
        />
        <path
          d='M3927 3504 c-4 -4 -7 -20 -7 -35 0 -26 -15 -39 -46 -39 -8 0 -14 10
-14 23 0 12 -5 29 -10 37 -18 29 -31 -5 -28 -71 2 -51 6 -64 21 -67 13 -3 17
3 17 23 0 23 4 26 27 23 19 -2 29 -10 33 -28 3 -14 14 -27 24 -28 18 -4 19 1
13 74 -7 75 -16 102 -30 88z'
        />
        <path
          d='M2653 3464 c-6 -17 12 -34 39 -34 21 0 30 -28 27 -78 -4 -47 7 -68
27 -51 9 8 14 33 14 75 l0 64 30 0 c23 0 30 4 30 20 0 18 -7 20 -80 20 -65 0
-82 -3 -87 -16z'
        />
        <path
          d='M2870 3465 c-41 -49 -3 -177 55 -183 40 -5 55 19 55 89 0 42 -5 63
-21 83 -23 29 -69 35 -89 11z m58 -37 c17 -17 16 -111 0 -106 -19 7 -38 49
-38 86 0 33 16 42 38 20z'
        />
        <path
          d='M1631 3429 c-103 -20 -237 -73 -326 -130 -164 -103 -274 -302 -297
-535 -9 -91 -8 -111 11 -183 54 -214 235 -395 451 -450 l71 -18 35 -67 c19
-37 34 -69 34 -71 0 -2 -26 -5 -57 -7 -57 -3 -58 -4 -61 -34 -5 -53 65 -93
165 -94 35 0 55 -5 60 -14 5 -8 41 -51 81 -96 154 -169 478 -343 762 -409 256
-60 524 -39 737 59 l82 38 40 -48 c23 -27 41 -54 41 -61 0 -14 65 -6 86 12 18
15 18 70 0 138 l-14 54 32 25 c17 14 55 50 84 80 l54 53 91 -15 c134 -24 143
-22 157 18 10 32 8 43 -31 128 -50 109 -80 158 -182 293 l-76 100 27 49 c15
27 63 83 108 125 45 42 102 105 127 141 115 167 171 351 116 380 -32 18 -46 6
-64 -56 -40 -137 -130 -258 -270 -364 -60 -45 -112 -94 -131 -122 l-31 -46
-44 30 c-72 50 -158 86 -254 107 -108 24 -341 56 -520 71 -116 10 -311 43
-323 54 -2 2 6 43 18 91 26 104 28 251 5 340 -25 94 -82 191 -160 269 -89 89
-179 138 -294 160 -89 17 -266 20 -340 5z m311 -35 c104 -21 166 -48 246 -109
164 -124 251 -335 222 -543 -6 -45 -17 -99 -24 -119 -13 -37 -13 -37 -21 -13
-15 51 -91 193 -122 229 -28 33 -32 47 -35 102 -3 61 -4 64 -28 64 -20 0 -30
-10 -50 -52 l-25 -53 -53 0 c-110 0 -302 -37 -387 -75 -27 -12 -74 -39 -104
-59 l-54 -37 -22 21 c-42 40 -53 25 -62 -90 -8 -117 12 -281 47 -385 17 -49
33 -98 36 -107 6 -17 3 -17 -47 -2 -90 26 -170 75 -245 149 -79 78 -114 135
-151 245 -23 68 -25 85 -20 185 7 130 36 232 98 345 72 129 177 208 357 271
137 48 304 60 444 33z m283 -585 c42 -58 125 -218 125 -243 0 -7 -38 -45 -85
-85 -212 -180 -395 -274 -638 -325 l-68 -14 -23 47 c-13 25 -33 78 -45 116
-26 83 -47 308 -37 379 l8 49 22 -27 c21 -27 23 -27 36 -10 29 35 135 95 210
119 81 25 267 55 348 55 47 0 47 1 72 53 l24 52 4 -53 c3 -45 10 -63 47 -113z
m1782 -72 c-45 -123 -105 -215 -212 -322 -54 -55 -113 -121 -130 -146 l-31
-46 -32 27 c-18 15 -32 31 -32 37 0 26 76 104 161 166 125 91 232 231 269 352
18 57 25 64 28 29 2 -17 -8 -60 -21 -97z m-1482 -227 c60 -10 171 -24 245 -30
161 -14 364 -43 477 -70 181 -43 329 -152 477 -350 100 -134 135 -194 184
-311 16 -36 17 -48 7 -62 -11 -15 -23 -15 -176 3 -90 11 -177 25 -193 30 -16
6 -32 7 -35 4 -11 -10 30 -32 72 -39 79 -12 81 -14 60 -37 -68 -77 -283 -211
-412 -258 -143 -52 -210 -63 -386 -64 -234 -1 -394 38 -630 154 -277 136 -440
285 -587 537 l-56 98 68 12 c233 40 455 154 664 343 52 46 85 69 95 65 9 -3
65 -15 126 -25z m-867 -606 c13 -20 21 -38 18 -42 -9 -8 -110 17 -134 34 -41
28 -28 44 36 44 54 0 58 -2 80 -36z m1875 -497 c5 -32 2 -40 -20 -54 -26 -17
-26 -17 -64 27 -22 25 -38 48 -36 52 1 4 22 20 47 36 l45 29 11 -26 c6 -14 14
-43 17 -64z'
        />
        <path
          d='M1855 3350 c-7 -11 -5 -12 85 -36 36 -10 101 -35 144 -56 81 -39 122
-44 95 -11 -30 36 -233 113 -297 113 -12 0 -24 -5 -27 -10z'
        />
        <path
          d='M2220 3162 c0 -20 29 -52 48 -52 23 0 23 22 1 46 -27 29 -49 31 -49
6z'
        />
        <path
          d='M2044 2666 c-24 -18 -11 -46 21 -46 20 0 25 5 25 24 0 29 -22 39 -46
22z'
        />
        <path
          d='M1914 2546 c-4 -9 -13 -15 -20 -12 -7 3 -18 -2 -23 -11 -7 -14 -12
-15 -24 -5 -20 17 -34 -3 -17 -23 16 -19 29 -19 69 1 36 18 49 40 33 56 -8 8
-13 7 -18 -6z'
        />
        <path
          d='M1620 2535 c-17 -20 -5 -45 20 -45 11 0 23 7 26 15 6 15 -11 45 -26
45 -4 0 -13 -7 -20 -15z'
        />
        <path
          d='M2125 2133 c-22 -8 -67 -20 -100 -27 -59 -12 -60 -12 -60 -46 0 -31
5 -37 45 -57 52 -27 154 -32 186 -9 28 21 12 29 -36 16 -30 -8 -55 -8 -95 0
-96 20 -100 58 -9 78 71 15 124 37 124 52 0 12 -4 12 -55 -7z'
        />
        <path
          d='M763 3272 c-7 -12 -8 -24 -1 -35 11 -20 17 -21 42 -2 19 14 19 15 1
35 -22 25 -27 25 -42 2z'
        />
        <path
          d='M4521 3136 c-9 -10 -9 -16 -1 -21 16 -10 31 7 21 23 -6 10 -10 10
-20 -2z'
        />
        <path
          d='M2710 2990 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z'
        />
        <path d='M711 2170 c-14 -8 -8 -40 7 -40 5 0 15 6 21 14 15 17 -8 39 -28 26z' />
        <path
          d='M933 1895 c-10 -10 -9 -16 2 -25 16 -14 30 3 18 24 -7 11 -10 12 -20
1z'
        />
        <path
          d='M1640 1455 c0 -8 -9 -15 -20 -15 -11 0 -20 -4 -20 -10 0 -5 7 -10 15
-10 8 0 15 -6 15 -14 0 -23 19 -29 27 -9 4 10 11 21 16 24 5 4 3 15 -4 28 -16
25 -29 27 -29 6z'
        />
      </g>
    </svg>
  </div>
)
