import s from './styles.module.scss'

export default function Main(props) {
  const isMultiple = props.isMultiple ? props.isMultiple : true
  const isDisabled = props.isDisabled ? props.isDisabled : false

  return (
    <div className={props.type === 'checkbox' ? s.fieldAlt1 : s.fieldAdmin}>
      <span className='material-icons-outlined'>{props.iconName}</span>
      {props.type !== 'file' && (
        <input
          className={s.input}
          type={props.type}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
          disabled={isDisabled}
          autoComplete='off'
        />
      )}

      {props.type === 'file' && (
        <div className={s.customInputField}>
          <div className={s.inputButton}>
            Choose Files
            <input
              className={s.input}
              title=''
              type={props.type}
              placeholder={props.placeholder}
              value={props.value}
              onChange={props.onChange}
              accept='image/*'
              autoComplete='off'
              multiple={isMultiple}
            />
          </div>
          <div className={s.inputButtonLabel}>{props.filesSelected}&nbsp;files</div>
        </div>
      )}
      {!!props.isRequired && <span className={s.isRequired}>*</span>}

      {!!props.label && <label className={s.label}>{props.label}</label>}
    </div>
  )
}
