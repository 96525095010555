import Classic from './classic'
import Admin from './admin'
import Search from './search'

const Inputs = {
  Classic,
  Admin,
  Search
}

export default Inputs
