import images from 'images'
import { Alert, Card, Input, Loader, NoData, Pagination } from 'components'
import { useCallback, useEffect, useRef, useState } from 'react'
import s from './styles.module.scss'
import * as api from 'api'

const perPage = 6
const mediaMatch = window.matchMedia('(max-width: 1279px)')

export default function Main() {
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [currentSearchedPage, setCurrentSearchedPage] = useState(1)
  const [totalSearchedPages, setTotalSearchedPages] = useState(0)
  const [searchData, setSearchData] = useState([])
  const [searchResults, setSearchResults] = useState(0)
  const [matches, setMatches] = useState(mediaMatch.matches)
  const processing = useRef(false)

  useEffect(() => {
    const handler = e => setMatches(e.matches)
    mediaMatch.addEventListener('change', handler)
    return () => mediaMatch.removeEventListener('change', handler)
  })

  const pageDataHandler = useCallback(async currPage => {
    if (processing.current) {
      return
    }

    processing.current = true
    setLoading(true)
    setCurrentPage(currPage)

    const promotedProducts = await api.products.fetchAllUsersProducts({ pageNo: currPage, isPromoted: true })

    if (promotedProducts.code === 'OK') {
      setTotalPages(Math.ceil(promotedProducts.count / perPage))
      setProducts(promotedProducts.products)
    } else {
      Alert.error(promotedProducts.error)
    }

    processing.current = false
    setLoading(false)
  }, [])

  useEffect(() => {
    pageDataHandler(currentPage)
  }, [pageDataHandler, currentPage])

  const searchHandler = async currPage => {
    if (processing.current) return
    processing.current = true
    setLoading(true)
    setCurrentSearchedPage(currPage)

    const searchedProduct = await api.products.fetchAllUsersProducts({
      productName: search,
      pageNo: currPage,
      isPromoted: true
    })

    if (searchedProduct.code === 'OK') {
      if (searchedProduct.products.length === 0) {
        setSearchData([])
        setSearch('')
        setCurrentSearchedPage(1)
        Alert.warn('No result found')
      } else {
        setSearchResults(searchedProduct.count)
        setTotalSearchedPages(Math.ceil(searchedProduct.count / perPage))
        setSearchData(searchedProduct.products)
      }
    } else {
      Alert.warn(searchedProduct.error)
    }

    setLoading(false)
    processing.current = false
  }

  return (
    <div className={s.main}>
      <div className={s.promotedSection}>
        <div className={s.content + ' indent'} id='content2'>
          <div className={s.heading}>
            <span>Promoted</span> Products
          </div>

          {!!loading && <Loader />}

          {!loading && products.length === 0 && <NoData />}

          {!loading && products.length !== 0 && (
            <>
              <Input.Search
                type='text'
                placeholder='Search...'
                iconName='search'
                value={search}
                onChange={e => {
                  setSearch(e.target.value)
                  e.target.value === '' && setSearchData([])
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter' && search !== '') {
                    searchHandler(currentSearchedPage)
                  }
                }}
                onClick={() => {
                  search !== '' && searchHandler(currentSearchedPage)
                }}
              />

              {searchData.length !== 0 && <div className={s.searchResults}>{searchResults} search results found</div>}

              {searchData.length === 0 ? (
                <div
                  className={s.carousel}
                  style={{
                    gridTemplateColumns: !matches
                      ? products.length === 1
                        ? 'auto'
                        : products.length === 2
                        ? 'auto auto'
                        : 'auto auto auto'
                      : 'auto'
                  }}
                >
                  {products.map(({ _id, businessName, productName, description, images }, i) => (
                    <Card
                      productId={_id}
                      productName={productName}
                      description={description}
                      link={'/' + businessName + '/products/' + window.btoa(JSON.stringify(_id))}
                      image={images}
                      promoted={true}
                      key={i}
                    />
                  ))}
                </div>
              ) : (
                <div
                  className={s.carousel}
                  style={{
                    gridTemplateColumns: !matches
                      ? searchData.length === 1
                        ? 'auto'
                        : searchData.length === 2
                        ? 'auto auto'
                        : 'auto auto auto'
                      : 'auto'
                  }}
                >
                  {searchData.map(({ _id, businessName, productName, description, images }, i) => (
                    <Card
                      productId={_id}
                      productName={productName}
                      description={description}
                      link={'/' + businessName + '/products/' + window.btoa(JSON.stringify(_id))}
                      image={images}
                      promoted={true}
                      key={i}
                    />
                  ))}
                </div>
              )}

              {searchData.length === 0 ? <Illus products={products} /> : <Illus products={searchData} />}

              {searchData.length === 0 && totalPages > 1 && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  pageDataHandler={pageDataHandler}
                  setTopId={'#content2'}
                />
              )}
              {searchData.length !== 0 && totalSearchedPages > 1 && (
                <Pagination
                  currentPage={currentSearchedPage}
                  totalPages={totalSearchedPages}
                  pageDataHandler={searchHandler}
                  setTopId={'#content2'}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const Illus = ({ products }) =>
  Math.ceil(products.length / 3) === 2 ? <IllusArt iterate={2} /> : <div className={s.illus}></div>

const IllusArt = ({ iterate }) => (
  <div className={s.illus}>
    {Array.apply(0, Array(iterate)).map(function (x, index) {
      return (
        <div key={index}>
          <img src={images.illusDesign3} alt='' />
        </div>
      )
    })}
  </div>
)
