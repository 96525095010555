import Dashboard from './admin/dashboard'
import AddProduct from './admin/addProduct'
import PrivateLinkGenerator from './admin/privateLinkGenerator'
import Products from './admin/products'

import AddCatalogue from './admin/addCatalogue'
import Catalogues from './admin/catalogues'
import CataloguesProducts from './admin/catalogues/cataloguesProducts'

import UserProfileInfo from './admin/userProfileInfo'
import Settings from './admin/settings'
import ChangePassword from './admin/changePassword'
import SignOut from './admin/signOut'
import NotFound from './common/404'
import Search from './common/search'

import Temp from './common/temp'

import * as Layout from 'layouts'
import { isCustomDomain } from 'helpers'

const routes = [
  {
    path: '/admin/dashboard',
    Component: Dashboard,
    Super: Layout.Admin,
    auth: true
  },
  {
    path: '/admin/products/addProduct',
    Component: AddProduct,
    Super: Layout.Admin,
    auth: true
  },
  {
    path: '/admin/products/editProduct/:productId',
    Component: AddProduct,
    Super: Layout.Admin,
    auth: true
  },
  {
    path: '/admin/products/privateLink/:productId',
    Component: PrivateLinkGenerator,
    Super: Layout.Admin,
    auth: true
  },
  {
    path: '/admin/products',
    Component: Products,
    Super: Layout.Admin,
    auth: true
  },
  {
    path: '/admin/catalogues/addCatalogue',
    Component: AddCatalogue,
    Super: Layout.Admin,
    auth: true
  },
  {
    path: '/admin/catalogues/privateLink/:catalogueId',
    Component: PrivateLinkGenerator,
    Super: Layout.Admin,
    auth: true
  },
  {
    path: '/admin/catalogues',
    Component: Catalogues,
    Super: Layout.Admin,
    auth: true
  },
  {
    path: '/admin/catalogues/:catalogueId',
    Component: CataloguesProducts,
    Super: Layout.Admin,
    auth: true
  },
  {
    path: '/admin/userProfileInfo',
    Component: UserProfileInfo,
    Super: Layout.Admin,
    auth: true
  },
  {
    path: '/admin/settings',
    Component: Settings,
    Super: Layout.Admin,
    auth: true
  },
  {
    path: '/admin/changePassword',
    Component: ChangePassword,
    Super: Layout.Admin,
    auth: true
  },
  {
    path: '/admin/signOut',
    Component: SignOut,
    Super: Layout.Admin,
    auth: true
  },
  {
    path: '/Error_404_Not_Found',
    Component: NotFound,
    auth: false
  },
  {
    path: '/search',
    Component: Search,
    Super: isCustomDomain() ? Layout.User : Layout.Home,
    auth: false
  },
  {
    path: '/temp',
    Component: Temp,
    auth: false
  }
]

export default routes
