import { Alert, Loader, NoData, Swiper } from 'components'
import s from './styles.module.scss'
import images from 'images'
import { useCallback, useEffect, useRef, useState } from 'react'
import * as api from 'api'
import { getBusinessName, isCustomDomain } from 'helpers'
import { Link } from 'react-router-dom'

const limit = 10
const perPage = 10
const perPhonePage = 4
const mediaMatch = window.matchMedia('(max-width: 1279px)')

export default function Main() {
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(false)
  const processing = useRef(false)
  const userProfile = getBusinessName()
  const [matches, setMatches] = useState(mediaMatch.matches)

  useEffect(() => {
    const handler = e => setMatches(e.matches)
    mediaMatch.addEventListener('change', handler)
    return () => mediaMatch.removeEventListener('change', handler)
  })

  const pageDataHandler = useCallback(async () => {
    if (processing.current) {
      return
    }

    processing.current = true
    setLoading(true)

    const userCategories = await api.user.fetchUserCategories({
      businessName: userProfile,
      limit: limit
    })

    if (userCategories.code === 'OK') {
      if (!matches) {
        const chunks = Array.from(
          { length: Math.ceil(userCategories.userCategories.length / perPage) },
          (element, index) => userCategories.userCategories.slice(index * perPage, index * perPage + perPage)
        )
        setCategories(chunks)
      } else {
        const chunks = Array.from(
          { length: Math.ceil(userCategories.userCategories.length / perPhonePage) },
          (element, index) =>
            userCategories.userCategories.slice(index * perPhonePage, index * perPhonePage + perPhonePage)
        )
        setCategories(chunks)
      }
    } else {
      Alert.error(userCategories.error)
    }

    processing.current = false
    setLoading(false)
  }, [userProfile, matches])

  useEffect(() => {
    pageDataHandler()
  }, [pageDataHandler])

  return (
    <div className={s.main}>
      <div className={s.categories}>
        <div className={s.heading}>Categories</div>
        {!!loading && <Loader color='var(--c-primary)' colorText='var(--c-font)' />}

        {!loading && categories.length === 0 && <NoData color='var(--c-black)' />}
        {!loading && categories.length !== 0 && (
          <>
            <div className={s.subHeading}>Choose your collection</div>
            <div className={s.categoriesCarousel}>
              {!matches && <Illus />}
              <Swiper
                infinite
                timer={3000}
                carouselType='casualT'
                paginationOn={false}
                navArrowsOn={categories.length > 1 ? true : false}
                arrowsInside={false}
                borderTopRadius={false}
              >
                {categories.map((chunk, index) => (
                  <div
                    className={s.page}
                    key={index}
                    style={{
                      gridTemplateColumns: !matches
                        ? chunk.length === 1
                          ? 'auto'
                          : chunk.length === 2
                          ? 'auto auto'
                          : chunk.length === 3
                          ? 'auto auto auto'
                          : chunk.length === 4
                          ? 'auto auto auto auto'
                          : 'auto auto auto auto auto'
                        : chunk.length <= 1
                        ? 'auto'
                        : 'auto auto'
                    }}
                  >
                    {chunk.map(({ category }, i) => (
                      <CategoryCard category={category} key={i} userProfile={userProfile} />
                    ))}
                  </div>
                ))}
              </Swiper>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const CategoryCard = props => {
  const isUserCustomDomain = isCustomDomain()

  return (
    <Link
      className={s.categoryCardOuter}
      to={
        props.userProfile && !isUserCustomDomain
          ? '/' + props.userProfile + '/search?category=' + props.category
          : '/search?category=' + props.category
      }
      target='_blank'
    >
      <div className={s.categoryCard}>
        <div className={s.image}>
          {/* <span className='material-icons-outlined'>category</span> */}
          <div className={s.categoryName + ' ellipsis'} style={{ WebkitLineClamp: 3 }}>
            {props.category}
          </div>
        </div>
      </div>
    </Link>
  )
}

const Illus = () => (
  <>
    <div className={s.illus1 + ' ' + s.illus}>
      <img src={images.illusDesign1} alt='' />
    </div>
    <div className={s.illus2 + ' ' + s.illus}>
      <img src={images.illusDesign1} alt='' />
    </div>
  </>
)
