import s from './styles.module.scss'
import { useCallback, useEffect, useState } from 'react'

export default function Main({ currentPage, totalPages, pageDataHandler, setTopId, adminPagination = false }) {
  const pagesToDisplay = 5,
    pageCount = totalPages
  const [pages, setPages] = useState([])
  const [page, setPage] = useState(0)

  const buildPagination = useCallback(
    (pageIndex, autoScroll) => {
      if (pageCount >= pagesToDisplay) {
        setPage(pageIndex)
        let newPages = []
        let start = 0
        let end = pagesToDisplay

        if (pageIndex > (pagesToDisplay - 1) / 2) {
          start = pageIndex - (pagesToDisplay - 1) / 2
          end = start + pagesToDisplay
        }

        if (pageIndex > pageCount - (pagesToDisplay + 1) / 2) {
          start = pageCount - pagesToDisplay
          end = pageCount
        }

        for (let i = start; i < end; i++) {
          newPages.push(i)
        }

        setPages(newPages)
      }

      if (pageCount < pagesToDisplay) {
        setPage(pageIndex)
        let newPages = []
        let start = 0
        let end = pageCount

        for (let i = start; i < end; i++) {
          newPages.push(i)
        }

        setPages(newPages)
      }

      if (autoScroll) {
        !adminPagination &&
          window.scroll(
            window.scrollX,
            document.querySelector(setTopId).offsetTop - document.querySelector('#nav').getBoundingClientRect().height
          )
      }
    },
    [pageCount, adminPagination, setTopId]
  )

  useEffect(() => {
    buildPagination(currentPage - 1, false)
  }, [currentPage, buildPagination])

  return (
    <div className={adminPagination ? s.adminPagination : s.pagination}>
      <button
        className={s.prev}
        disabled={page === 0}
        onClick={() => {
          buildPagination(page - 1, true)
          pageDataHandler(page)
        }}
        type='button'
      >
        <span className='material-icons-outlined'>skip_previous</span>
      </button>
      {pages.map(p => {
        return (
          <button
            className={p === page ? s.active : ''}
            onClick={() => {
              buildPagination(p, true)
              pageDataHandler(p + 1)
            }}
            key={p}
            type='button'
          >
            {p + 1}
          </button>
        )
      })}
      <button
        className={s.next}
        disabled={page === pageCount - 1}
        onClick={() => {
          buildPagination(page + 1, true)
          pageDataHandler(page + 2)
          // dummyPageDataHandler()
        }}
        type='button'
      >
        <span className='material-icons-outlined'>skip_next</span>
      </button>
    </div>
  )
}
