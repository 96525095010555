import s from './styles.module.scss'
import { head } from 'helpers'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Alert, Input, Loader, NoData, Pagination, Table, TextArea } from 'components'
import * as api from 'api'

const perPage = 12

export default function Main() {
  const [loading, setLoading] = useState(false)
  const [catalogueProducts, setCatalogueProducts] = useState([])
  const [productsAvailable, setProductsAvailable] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchData, setSearchData] = useState([])
  const [search, setSearch] = useState('')
  const [currentSearchedPage, setCurrentSearchedPage] = useState(1)
  const [searchResults, setSearchResults] = useState(0)
  const [totalSearchedPages, setTotalSearchedPages] = useState(0)
  const [catalogueName, setCatalogueName] = useState('')
  const [description, setDescription] = useState('')

  const processing = useRef()

  const addProductsHandler = useCallback(async (currPage, id) => {
    if (processing.current) {
      return
    }
    processing.current = true
    setLoading(true)
    setCurrentPage(currPage)

    const fetchProducts = await api.products.fetchUserProducts({
      pageNo: currPage
    })

    if (fetchProducts.code === 'OK') {
      setTotalPages(Math.ceil(fetchProducts.count / perPage))
      setProductsAvailable(fetchProducts.products.filter(x => !id.includes(x._id)))
    } else {
      Alert.error(fetchProducts.error)
    }
    processing.current = false
    setLoading(false)
  }, [])

  useEffect(() => {
    addProductsHandler(
      currentPage,
      catalogueProducts.map(x => x._id)
    )
  }, [addProductsHandler, currentPage, catalogueProducts])

  useEffect(() => {
    head({ title: 'Add Catalogue | StashBlog Admin' })
  }, [])

  const searchHandler = async currPage => {
    if (processing.current) return
    processing.current = true
    setLoading(true)
    setCurrentSearchedPage(currPage)

    const searchedCatalogueProduct = await api.products.fetchUserProducts({
      pageNo: currPage,
      productName: search
    })

    if (searchedCatalogueProduct.code === 'OK') {
      if (searchedCatalogueProduct.products.length === 0) {
        setSearchData([])
        setSearch('')
        setCurrentSearchedPage(1)
        Alert.warn('No result found')
      } else {
        let data = searchedCatalogueProduct.products.filter(x => !catalogueProducts.some(y => y._id === x._id))
        // debugger
        if (data.length === 0) {
          Alert.warn('No result found')
          setSearchData([])
          setSearch('')
          setCurrentSearchedPage(1)
        } else {
          setSearchResults(data.length)
          setTotalSearchedPages(Math.ceil(data.length / perPage))
          setSearchData(data)
        }
      }
    } else {
      Alert.warn(searchedCatalogueProduct.error)
    }

    setLoading(false)
    processing.current = false
  }

  const addProduct = id => {
    setCatalogueProducts(catalogueProducts.concat(productsAvailable.find(x => x._id === id)))
    setProductsAvailable(productsAvailable.filter(x => x._id !== id))
  }
  const removeProduct = id => {
    setProductsAvailable(productsAvailable.concat(catalogueProducts.find(x => x._id === id)))
    setCatalogueProducts(catalogueProducts.filter(x => x._id !== id))
  }

  const onSubmit = async () => {
    if (processing.current) return
    const products = catalogueProducts.map(x => x._id)

    if (!catalogueName) return Alert.warn('Catalogue name required!')
    if (!description) return Alert.warn('Catalogue description required!')
    if (products.length < 3) return Alert.warn('Minimum 3 products required')

    processing.current = true

    const addCatalogue = await api.catalogues.addCatalogue({ catalogueName, description, products })

    if (addCatalogue.code === 'OK') {
      Alert.success('New catalogue added')
    } else {
      Alert.error(addCatalogue.error)
    }
    setCatalogueName('')
    setDescription('')
    setCatalogueProducts([])

    processing.current = false
  }

  return (
    <div className={s.main}>
      <div className={s.addCatalogue + ' indent'}>
        <div className={s.header}>
          <div>
            <span className='material-icons-outlined'>add_circle_outline</span>
            Add Catalogue
          </div>
          <div className={s.actionIcons}>
            <div className={s.add} onClick={onSubmit}>
              <span className='material-icons-outlined'>add_circle_outline</span> add
            </div>
          </div>
        </div>
        <div className={s.content + ' innerScroll'}>
          <div className={s.form}>
            <Input.Admin
              type='text'
              iconName='badge'
              placeholder='Enter Catalogue Name'
              value={catalogueName}
              onChange={e => setCatalogueName(e.target.value)}
              isRequired
            />
            <TextArea.Admin
              iconName='description'
              placeholder='Enter Description'
              value={description}
              onChange={e => setDescription(e.target.value)}
              isRequired
            />
          </div>
          <div className={s.available}>Products in Catalogue</div>
          {catalogueProducts.length === 0 ? (
            <NoData color='var(--c-font)' />
          ) : (
            <Table.CatalogueProducts data={catalogueProducts} productHandler={removeProduct} isLastPage={false} />
          )}

          <div className={s.available}>Available Products</div>

          {!!loading && <Loader color='var(--c-primary)' colorText='var(--c-font-dark)' />}

          {!loading && productsAvailable.length === 0 && <NoData color='var(--c-font)' />}

          {!loading && productsAvailable.length !== 0 && (
            <>
              <Input.Search
                type='text'
                placeholder='Search...'
                iconName='search'
                value={search}
                onChange={e => {
                  setSearch(e.target.value)
                  e.target.value === '' && setSearchData([])
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter' && search !== '') {
                    searchHandler(currentSearchedPage)
                  }
                }}
                onClick={() => {
                  search !== '' && searchHandler(currentSearchedPage)
                }}
              />

              {searchData.length !== 0 && <div className={s.searchResults}>{searchResults} search results found</div>}

              {searchData.length === 0 ? (
                <Table.CatalogueProducts
                  data={productsAvailable}
                  productHandler={addProduct}
                  isLastPage={currentPage === totalPages && totalPages === 1}
                  add={true}
                />
              ) : (
                <Table.CatalogueProducts
                  data={searchData}
                  productHandler={addProduct}
                  isLastPage={currentSearchedPage === totalSearchedPages}
                  add={true}
                />
              )}
            </>
          )}

          {!loading && searchData.length === 0 && totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              pageDataHandler={currPage =>
                addProductsHandler(
                  currPage,
                  catalogueProducts.map(x => x._id)
                )
              }
              adminPagination={true}
            />
          )}

          {!loading && searchData.length !== 0 && totalSearchedPages > 1 && (
            <Pagination
              currentPage={currentSearchedPage}
              totalPages={totalSearchedPages}
              pageDataHandler={searchHandler}
              adminPagination={true}
            />
          )}
        </div>
      </div>
    </div>
  )
}
