import { useCallback, useEffect, useRef, useState } from 'react'
import s from './styles.module.scss'
import * as api from 'api'
import { Alert, ImageTag, Loader, NoData } from 'components'
import { getBusinessName, head, isCustomDomain } from 'helpers'

const IMAGE_HOST = process.env.REACT_APP_IMAGE_HOST

export default function Main() {
  const [userAboutUs, setUserAboutUs] = useState()
  const [loading, setLoading] = useState(false)
  const userProfile = getBusinessName()
  const isUserCustomDomain = isCustomDomain()
  const processing = useRef(false)

  const getUserAboutUs = useCallback(async () => {
    if (processing.current) {
      return
    }

    processing.current = true
    setLoading(true)

    const fetchUserAboutUs = await api.user.fetchUserAboutUs({ businessName: userProfile })

    if (fetchUserAboutUs.code === 'OK') {
      setUserAboutUs(fetchUserAboutUs.userAboutUs)
    } else {
      Alert.error(fetchUserAboutUs.error)
    }
    processing.current = false
    setLoading(false)
  }, [userProfile])

  useEffect(() => {
    getUserAboutUs()
  }, [getUserAboutUs])

  useEffect(() => {
    head({ title: isUserCustomDomain ? 'About us | ' + userProfile : userProfile + "'s About us | StashBlog" })
  }, [userProfile, isUserCustomDomain])

  return (
    <div className={s.main}>
      {!!loading && <Loader color='var(--c-primary)' colorText='var(--c-font)' />}

      {!loading && !userAboutUs && <NoData color='var(--c-font)' />}

      {!loading && !!userAboutUs && (
        <div className={s.aboutUs + ' indent'}>
          <div className={s.heading}>About Us</div>
          {!!userAboutUs.images[0] && (
            <div className={s.topImage}>
              <ImageTag src={IMAGE_HOST + userAboutUs.images[0]} alt='' />
            </div>
          )}
          <div className={s.desc}>{userAboutUs.description}</div>
          {!!userAboutUs.images[1] && (
            <div className={s.bottomImage}>
              <ImageTag src={IMAGE_HOST + userAboutUs.images[1]} alt='' />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
