import { useCallback, useEffect, useRef, useState } from 'react'
import s from './styles.module.scss'
import * as api from 'api'
import { Alert, ImageTag, Loader, NoData } from 'components'
import { Link } from 'react-router-dom'
import { getBusinessName, isCustomDomain } from 'helpers'

const IMAGE_HOST = process.env.REACT_APP_IMAGE_HOST
const limit = 2

export default function Main(props) {
  const [products, setProducts] = useState([])
  const [catalogues, setCatalogues] = useState([])
  const [loading, setLoading] = useState(false)
  const userProfile = getBusinessName()
  const isUserCustomDomain = isCustomDomain()
  const processing = useRef(false)

  const pageDataHandler = useCallback(async () => {
    if (processing.current) {
      return
    }

    processing.current = true
    setLoading(true)

    const newArrivals = await api.products.fetchAllUsersProducts({
      limit: limit,
      businessName: userProfile
    })

    if (newArrivals.code === 'OK') {
      setProducts(newArrivals.products)
    } else {
      Alert.error(newArrivals.error)
    }

    processing.current = false
    setLoading(false)
  }, [userProfile])

  const pageDataHandler1 = useCallback(async () => {
    if (processing.current) {
      return
    }

    processing.current = true
    setLoading(true)

    const newArrivals = await api.catalogues.fetchAllUsersCatalogues({
      limit: limit,
      businessName: userProfile
    })

    if (newArrivals.code === 'OK') {
      setCatalogues(newArrivals.catalogues)
    } else {
      Alert.error(newArrivals.error)
    }

    processing.current = false
    setLoading(false)
  }, [userProfile])

  useEffect(() => {
    props.catalogues && pageDataHandler1()
    props.products && pageDataHandler()
  }, [pageDataHandler, pageDataHandler1, props.catalogues, props.products])

  return (
    <div className={s.main}>
      <div className={s.newArrivals}>
        <div className={s.heading}>New Arrivals</div>
        {!!loading && <Loader />}

        {props.products && !loading && products.length === 0 && <NoData color='var(--c-font)' />}

        {props.products && !loading && products.length !== 0 && (
          <div className={s.products}>
            {products.map(({ _id, businessName, productName, images }, i) => (
              <NewArrivalCard
                productLink={
                  (isUserCustomDomain ? '/products/' : '/' + businessName + '/products/') +
                  window.btoa(JSON.stringify(_id))
                }
                productName={productName}
                images={images}
                key={i}
              />
            ))}
          </div>
        )}

        {props.products && products.length !== 0 && props.exploreMore && (
          <Link to={isUserCustomDomain ? '/products/' : '/' + userProfile + '/products/'} className={s.exploreMore}>
            Explore More
          </Link>
        )}

        {props.catalogues && !loading && catalogues.length === 0 && <NoData color='var(--c-font)' />}

        {props.catalogues && !loading && catalogues.length !== 0 && (
          <div className={s.products}>
            {catalogues.map(({ _id, businessName, catalogueName, images }, i) => (
              <NewArrivalCard
                productLink={
                  (isUserCustomDomain ? '/catalogues/' : '/' + businessName + '/catalogues/') +
                  window.btoa(JSON.stringify(_id))
                }
                productName={catalogueName}
                images={images}
                key={i}
              />
            ))}
          </div>
        )}

        {props.catalogues && props.exploreMore && (
          <Link to={isUserCustomDomain ? '/catalogues/' : '/' + userProfile + '/catalogues/'} className={s.exploreMore}>
            Explore More
          </Link>
        )}
      </div>
    </div>
  )
}

const NewArrivalCard = props => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [loadedImages, setLoadedImages] = useState([])
  const imageRef = useRef(null)

  const handleImageChange = useCallback(() => {
    const image = imageRef.current

    if (!image) return

    image.style.opacity = '0'
    image.style.transition = 'opacity 1s ease'

    setTimeout(() => {
      setCurrentIndex((currentIndex + 1) % props.images.length)
      image.style.opacity = '1'
    }, 500)
  }, [currentIndex, props.images.length])

  useEffect(() => {
    setTimeout(handleImageChange, 3000)
  }, [handleImageChange])

  const loadImage = useCallback(
    index => {
      const imageUrl = IMAGE_HOST + props.images[index]
      if (!loadedImages.includes(imageUrl)) {
        const img = new Image()
        img.onload = () => {
          setLoadedImages(prevLoadedImages => [...prevLoadedImages, imageUrl])
        }
        img.src = imageUrl
      }
    },
    [loadedImages, props.images]
  )

  useEffect(() => {
    for (let i = 0; i < props.images.length; i++) {
      loadImage(i)
    }
  }, [loadImage, props.images])

  return (
    <Link className={s.product} to={props.productLink}>
      <div className={s.productImage}>
        <ImageTag
          src={
            loadedImages.includes(IMAGE_HOST + props.images[currentIndex])
              ? IMAGE_HOST + props.images[currentIndex]
              : ''
          }
          ref={imageRef}
          alt=''
        />
      </div>
      <div className={s.productName + ' ellipsis'} style={{ WebkitLineClamp: 3 }}>
        {props.productName}
      </div>
    </Link>
  )
}
