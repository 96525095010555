import PromotedSection from './promotedSection'
import ProductsSection from './productsSection'
import PromotedCataloguesSection from './promotedCataloguesSection'
import CataloguesSection from './cataloguesSection'
import UserProfilesSection from './userProfilesSection'
import PromotedUserProfiles from './promotedUserProfiles'
import { useEffect } from 'react'
import { head } from 'helpers'
import { FAQ } from 'components'

export default function Main() {
  useEffect(() => {
    head({})
  }, [])

  return (
    <>
      <PromotedSection />
      <ProductsSection />
      <PromotedCataloguesSection />
      <CataloguesSection />
      <PromotedUserProfiles />
      <UserProfilesSection />
      {/* <FeaturesSection /> */}
      <FAQ />
    </>
  )
}
