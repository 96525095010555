import { Alert, Form, ImageCropper, ImageTag, Input, Swiper, TextArea } from 'components'
import { useRef, useState } from 'react'
import s from './styles.module.scss'
import * as api from 'api'
import { Link } from 'react-router-dom'

const IMAGE_HOST = process.env.REACT_APP_IMAGE_HOST

var $text,
  $setText,
  $proceedHandler,
  $formHandler,
  $setVisible,
  $setCategory,
  $setName,
  $setUnits,
  $setPricePerUnit,
  $setDesc,
  $setProductId,
  $setTags,
  $setLink,
  $setImages,
  $setCreatedAt,
  $setCanCrop,
  $setIsCropped,
  $setCroppedImages,
  $aspect,
  $queryProducts,
  $businessName,
  $resetQueryProducts

const handler = (text = '', proceedHandler = () => {}) => {
  $setText(text)
  $setVisible(true)
  $proceedHandler = proceedHandler
}

const handler2 = (
  text = '',
  name = '',
  tags = [],
  category = '',
  desc = '',
  link = '',
  images = [],
  createdAt = '',
  pricePerUnit = '',
  totalUnits = ''
) => {
  $setText(text)
  $setName(name)
  $setCategory(category)
  $setTags(tags)
  $setDesc(desc)
  $setVisible(true)
  $setLink(link)
  $setImages(images)
  $setCreatedAt(createdAt)
  $setPricePerUnit(pricePerUnit)
  $setUnits(totalUnits)
  document.body.style.overflow = 'hidden'
}

const handler3 = (text = '', productId = '', name = '', desc = '', formHandler = () => {}) => {
  $setText(text)
  $setProductId(productId)
  $setName(name)
  $setDesc(desc)
  $formHandler = formHandler
  $setVisible(true)
}

const imageViewerHandler = (text = '', images = [], canCrop = false, setCroppedImages = image => {}, aspect = 1) => {
  $setText(text)
  $setImages(images)
  $setCanCrop(canCrop)
  $setVisible(true)
  $setCroppedImages = setCroppedImages
  $aspect = aspect
}

const queryModalHandler = (text = '', businessName = '', queryProducts = [], resetQueryProducts) => {
  $setText(text)
  $setVisible(true)
  $queryProducts = queryProducts
  $resetQueryProducts = resetQueryProducts
  $businessName = businessName
}

const onCloseModal = () => {
  $setVisible(false)
  $setText('')
  $proceedHandler = () => {}
  $formHandler = () => {}
  $setCategory('')
  $setName('')
  $setUnits(0)
  $setPricePerUnit(0)
  $setDesc([])
  $setProductId('')
  $setTags([])
  $setLink('')
  $setImages([])
  $setCreatedAt('')
  $setCanCrop(false)
  $setIsCropped(false)
  $setCroppedImages = image => {}
  document.body.style.overflow = 'unset'
}

window.onclick = function (event) {
  if (event.target === document.getElementById('myModal') && $text !== 'imageViewerModal') onCloseModal()
}

const Main = {
  confirm: (text = 'Are you sure?', proceedHandler = () => {}) => handler(text, proceedHandler),
  product: (text = 'productmodal', name, tags, category, desc, link, images, createdAt, pricePerUnit, totalUnits) =>
    handler2(text, name, tags, category, desc, link, images, createdAt, pricePerUnit, totalUnits),
  catalogue: (text = 'cataloguemodal', productId, name, desc, formHandler) =>
    handler3(text, productId, name, desc, formHandler),
  imageViewer: (text = 'imageViewerModal', images, canCrop = false, setCroppedImages, aspect) =>
    imageViewerHandler(text, images, canCrop, setCroppedImages, aspect),
  queryModal: (text = 'queryModal', businessName, queryProducts, resetQueryProducts) =>
    queryModalHandler(text, businessName, queryProducts, resetQueryProducts)
}

Main.Component = function Component() {
  const [text, setText] = useState('')
  const [visible, setVisible] = useState(false)
  const [category, setCategory] = useState('')
  const [name, setName] = useState('')
  const [units, setUnits] = useState('')
  const [email, setEmail] = useState('')
  const [pricePerUnit, setPricePerUnit] = useState('')
  const [description, setDescription] = useState('')
  const [productId, setProductId] = useState('')
  const [phoneNo, setPhoneNo] = useState('')
  const [businessName, setBusinessName] = useState('')
  const [tags, setTags] = useState([])
  const [link, setLink] = useState('')
  const [images, setImages] = useState([])
  const [createdAt, setCreatedAt] = useState('')
  const [canCrop, setCanCrop] = useState(false)
  const [isCropped, setIsCropped] = useState(false)
  const imgRef = useRef()
  const processing = useRef()

  const onSubmit2 = async e => {
    if (processing.current) return

    if (!name) return Alert.warn('Catalogue Name required!')
    if (!description) return Alert.warn('Catalogue Description required!')

    processing.current = true

    const updateCatalogue = await api.catalogues.updateCatalogue({ id: productId, catalogueName: name, description })

    if (updateCatalogue.code === 'OK') {
      Alert.success('Catalogue updated')
      $formHandler()
    } else {
      Alert.error(updateCatalogue.error)
    }

    setName('')
    setDescription('')

    processing.current = false
    setVisible(false)
  }

  const onQuerySubmit = async () => {
    if (processing.current) return

    if (!email) return Alert.warn('Email is required!')
    if (!name) return Alert.warn('Name is required!')
    if (phoneNo.toString().length !== 10) return Alert.warn('Enter correct phoneno!')
    if (!$queryProducts.length) return Alert.warn('Query products required!')

    processing.current = true

    const sendMail = await api.mail.sendQuery({
      name,
      email,
      phoneNo,
      queryUserbusinessName: businessName,
      businessName: $businessName,
      queryProducts: $queryProducts
    })

    if (sendMail.code === 'OK') {
      Alert.success('Query sent')
    } else {
      Alert.error(sendMail.error)
    }

    setName('')
    setEmail('')
    setPhoneNo('')
    setBusinessName('')
    $resetQueryProducts()
    setVisible(false)
    processing.current = false
  }

  $text = text
  $setProductId = setProductId
  $setText = setText
  $setVisible = setVisible
  $setCategory = setCategory
  $setName = setName
  $setUnits = setUnits
  $setPricePerUnit = setPricePerUnit
  $setDesc = setDescription
  $setTags = setTags
  $setLink = setLink
  $setImages = setImages
  $setCreatedAt = setCreatedAt
  $setCanCrop = setCanCrop
  $setIsCropped = setIsCropped

  return text === 'productmodal' ? (
    <div className={s.main} style={{ display: visible ? 'flex' : 'none' }} id='myModal'>
      <div className={s.modalProduct}>
        <div className={s.images}>
          <Swiper
            infinite
            timer={3000}
            carouselType='productmodal'
            paginationOn={false}
            navArrowsOn={false}
            arrowsInside={true}
            borderTopRadius={false}
          >
            {images.map((image, i) => (
              <ImageTag src={IMAGE_HOST + image} alt='' key={i} />
            ))}
          </Swiper>
        </div>
        <div className={s.content}>
          <div className={s.contentTop}>
            <div className={s.productName + ' ellipsis'}>{name}</div>
            {tags.length > 0 && (
              <div className={s.tags}>
                {tags.length > 8
                  ? tags.map((tag, i) =>
                      i < 7 ? (
                        <div className={s.tag} key={i}>
                          #{tag}&nbsp;
                        </div>
                      ) : (
                        i === tags.length - 1 && (
                          <div className={s.tag} key={i}>
                            +{tags.length - 7} more &nbsp;
                          </div>
                        )
                      )
                    )
                  : tags.map((tag, i) => (
                      <div className={s.tag} key={i}>
                        #{tag}&nbsp;
                      </div>
                    ))}
              </div>
            )}
            <div className={s.category}>
              <span>Category:</span>
              {category}
            </div>
            {pricePerUnit && (
              <div className={s.category}>
                <span>Price per unit:</span>
                {pricePerUnit}
              </div>
            )}
            {units && (
              <div className={s.category}>
                <span>Total units:</span>
                {units}
              </div>
            )}
            <div className={s.date}>
              <span>Product listed on:</span>
              {new Date(createdAt).toLocaleString('en-US', {
                timeZone: 'Asia/Kolkata'
              })}
            </div>
            <div className={s.desc}>
              <div>Description</div>
              <div className='ellipsis' style={{ WebkitLineClamp: 5 }}>
                {description}
              </div>
            </div>
          </div>
          <div className={s.productLink}>
            <Link to={link} target='_blank'>
              View Product
            </Link>
          </div>
        </div>
      </div>
    </div>
  ) : text === 'cataloguemodal' ? (
    <div className={s.main} style={{ display: visible ? 'flex' : 'none' }} id='myModal'>
      <div className={s.modalContentAlt + ' innerScroll ' + s.modalCatalogue}>
        <Form onSubmit={onSubmit2} className={s.form}>
          <Input.Admin
            type='text'
            iconName='badge'
            placeholder='Enter Catalogue Name'
            value={name}
            onChange={e => setName(e.target.value)}
            isRequired
          />
          <TextArea.Admin
            iconName='description'
            placeholder='Enter Catalogue Description'
            value={description}
            onChange={e => setDescription(e.target.value)}
            isRequired
          />
          <div className={s.button}>
            <button onClick={onSubmit2}>Update Catalogue</button>
          </div>
        </Form>
      </div>
    </div>
  ) : text === 'imageViewerModal' ? (
    <div
      className={s.main}
      style={{ display: visible ? 'flex' : 'none', background: 'rgba(0, 0, 0, 0.3)', padding: '2rem' }}
      id='myModal'
    >
      <div className={s.imageViewerModal}>
        <div className={s.header}>
          {!!canCrop && (
            <div
              onClick={() => {
                // console.log(imgRef.current)
                $setCroppedImages(imgRef.current)
                onCloseModal()
              }}
            >
              <span className='material-icons-outlined'>done</span> Save
            </div>
          )}
          <div onClick={() => setVisible(false)}>
            <span className='material-icons-outlined'>close</span> Close
          </div>
        </div>
        <div className={s.images}>
          {!!canCrop ? (
            <div className={s.viewPort}>
              <ImageCropper image={imgRef} setIsCropped={setIsCropped} aspect={$aspect}>
                {!isCropped && (
                  <div className={s.clickToCrop}>
                    <span>Click and hold to select area</span>
                  </div>
                )}
                <ImageTag src={URL.createObjectURL(images[0])} alt='' ref={imgRef} />
              </ImageCropper>
            </div>
          ) : (
            <Swiper
              infinite
              // timer={3000}
              carouselType='imageViewerModal'
              paginationOn={false}
              navArrowsOn={images.length > 1}
              arrowsInside={false}
              borderTopRadius={false}
              arrowsWhite
            >
              {images.map((image, i) => (
                <ImageTag src={URL.createObjectURL(image)} alt='' key={i} />
              ))}
            </Swiper>
          )}
        </div>
      </div>
    </div>
  ) : text === 'queryModal' ? (
    <div className={s.main} style={{ display: visible ? 'flex' : 'none' }} id='myModal'>
      <div className={s.modalContentAlt + ' innerScroll '} style={{ height: '30rem' }}>
        <Form onSubmit={onQuerySubmit} className={s.form}>
          <Input.Admin
            type='text'
            iconName='person'
            placeholder='Enter your name'
            value={name}
            onChange={e => setName(e.target.value)}
            isRequired
          />
          <Input.Admin
            type='email'
            iconName='email'
            placeholder='Enter your email here...'
            value={email}
            onChange={e => setEmail(e.target.value)}
            isRequired
          />
          <Input.Admin
            type='number'
            iconName='call'
            placeholder='Enter your phoneNo'
            value={phoneNo}
            onKeyDown={e => {
              if (e.keyCode === 38 || e.keyCode === 40) {
                e.preventDefault()
              }
            }}
            onChange={e => setPhoneNo(e.target.value)}
            isRequired
          />
          <Input.Admin
            type='text'
            iconName='business'
            placeholder='Enter your business name'
            value={businessName}
            onChange={e => setBusinessName(e.target.value)}
          />
          <div className={s.button}>
            <button type='submit' onClick={onQuerySubmit}>
              Send Query
            </button>
          </div>
        </Form>
      </div>
    </div>
  ) : (
    <div className={s.main} style={{ display: visible ? 'flex' : 'none' }} id='myModal'>
      <div className={s.modalContent}>
        <div className={s.modalMessage}>{$text}</div>
        <div className={s.actionHandlers}>
          <div className={s.goBack} onClick={() => setVisible(false)}>
            <span className='material-icons-outlined'>arrow_back</span>Go Back
          </div>
          <div
            className={s.proceed}
            onClick={() => {
              $proceedHandler()
              setVisible(false)
            }}
          >
            Proceed
            <span className='material-icons-outlined'>arrow_forward</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main
