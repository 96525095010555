import { useState } from 'react'
import s from './styles.module.scss'
import { useNavigate } from 'react-router-dom'
import { getBusinessName, isCustomDomain } from 'helpers'

export default function Main({ style }) {
  const [search, setSearch] = useState('')
  const Navigate = useNavigate()
  const userProfile = getBusinessName()
  const isUserCustomDomain = isCustomDomain()

  let searchSuggestion = JSON.parse(window.localStorage.getItem('searchSuggestion')) || []

  const userSearch = search => {
    if (searchSuggestion) {
      if (!searchSuggestion.includes(search.trim())) searchSuggestion.push(search.trim())
    } else {
      searchSuggestion.push(search.trim())
    }
    window.localStorage.setItem('searchSuggestion', JSON.stringify(searchSuggestion))
    Navigate(
      userProfile && !isUserCustomDomain
        ? '/' + userProfile + '/search?searchQuery=' + search
        : '/search?searchQuery=' + search
    )
    setSearch('')
  }

  return (
    <div className={s.main}>
      <div className={s.navSearch}>
        <div className={s.searchField}>
          <span className='material-icons-outlined' onClick={() => !!search && userSearch(search)}>
            search
          </span>
          <input
            type='text'
            placeholder='Search...'
            value={search}
            onChange={e => setSearch(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter' && search !== '') userSearch(search)
            }}
            style={style}
            onClick={() => {}}
          />
        </div>
        {!!search && searchSuggestion.length > 0 && (
          <div className={s.searchSuggestions}>
            <ol>
              {searchSuggestion
                .slice(-10)
                .reverse()
                .map((search, i) => (
                  <li
                    onClick={() => {
                      setSearch(search)
                      userSearch(search)
                    }}
                    key={i}
                  >
                    {search}
                  </li>
                ))}
            </ol>
          </div>
        )}
      </div>
    </div>
  )
}
