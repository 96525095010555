import { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import s from './styles.module.scss'
import * as api from 'api'
import { Alert, ImageTag, Modal } from 'components'

const IMAGE_HOST = process.env.REACT_APP_IMAGE_HOST

export default function Main(props) {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [loadedImages, setLoadedImages] = useState([])
  const imageRef = useRef(null)
  const imageRef1 = useRef(null)
  const imageRef2 = useRef(null)
  const [status, setStatus] = useState(props.isPublic)
  const processing = useRef()

  const statusHandler = async () => {
    if (processing.current) {
      return
    }
    processing.current = true

    const catalogueStatus = await api.catalogues.updateCatalogueStatus({ id: props.catalogueId })
    if (catalogueStatus.code === 'OK') {
      setStatus(!status)
      status ? Alert.error(catalogueStatus.message) : Alert.success(catalogueStatus.message)
    } else {
      Alert.error(catalogueStatus.error)
    }

    processing.current = false
  }

  const modalHandler = () => {
    Modal.confirm('Are you sure you want to delete ' + props.name + '?', async () => {
      if (processing.current) {
        return
      }
      processing.current = true

      const catalogueDelete = await api.catalogues.deleteCatalogue({ id: props.catalogueId })
      if (catalogueDelete.code === 'OK') {
        props.setCatalogues(props.catalogues.filter(catalogue => catalogue._id !== props.catalogueId))
        Alert.warn(catalogueDelete.success)
      } else {
        Alert.error(catalogueDelete.error)
      }

      processing.current = false
    })
  }

  const handleImageChange = useCallback(() => {
    const image = imageRef.current
    const image1 = imageRef1.current
    const image2 = imageRef2.current

    if (image) {
      image.style.opacity = '0'
      image.style.transition = 'opacity 1s ease'
    }

    if (image1) {
      image1.style.opacity = '0'
      image1.style.transition = 'opacity 1s ease'
    }

    if (image2) {
      image2.style.opacity = '0'
      image2.style.transition = 'opacity 1s ease'
    }

    setTimeout(() => {
      setCurrentIndex((currentIndex + 1) % props.images.length)
      if (image) image.style.opacity = '1'
      if (image1) image1.style.opacity = '1'
      if (image2) image2.style.opacity = '1'
    }, 500)
  }, [currentIndex, props.images.length])

  useEffect(() => {
    setTimeout(handleImageChange, 3000)
  }, [handleImageChange])

  const loadImage = useCallback(
    index => {
      const imageUrl = IMAGE_HOST + props.images[index]
      if (!loadedImages.includes(imageUrl)) {
        const img = new Image()
        img.onload = () => {
          setLoadedImages(prevLoadedImages => [...prevLoadedImages, imageUrl])
        }
        img.src = imageUrl
      }
    },
    [loadedImages, props.images]
  )

  useEffect(() => {
    for (let i = 0; i < props.images.length; i++) {
      loadImage(i)
    }
  }, [loadImage, props.images])

  return (
    <div className={s.main}>
      <div className={s.catalogue} style={{ width: props.admin && '12rem' }}>
        <div className={s.imageCollage}>
          <div className={s.majorImage}>
            <ImageTag
              src={
                loadedImages.includes(IMAGE_HOST + props.images[currentIndex])
                  ? IMAGE_HOST + props.images[currentIndex]
                  : ''
              }
              ref={imageRef}
              alt=''
            />
          </div>
          <div className={s.minorImage}>
            <div className={s.image}>
              <ImageTag
                src={
                  loadedImages.includes(IMAGE_HOST + props.images[(currentIndex + 1) % props.images.length])
                    ? IMAGE_HOST + props.images[(currentIndex + 1) % props.images.length]
                    : ''
                }
                ref={imageRef1}
                alt=''
              />
            </div>
            <div className={s.image}>
              <ImageTag
                src={
                  loadedImages.includes(IMAGE_HOST + props.images[(currentIndex + 2) % props.images.length])
                    ? IMAGE_HOST + props.images[(currentIndex + 2) % props.images.length]
                    : ''
                }
                ref={imageRef2}
                alt=''
              />
            </div>
          </div>
        </div>
        <div className={s.catalogueName + ' ellipsis'}>{props.name}</div>
        <div className={s.tags}>
          {props.categories.length > 6
            ? props.categories.map((category, i) =>
                i < 5 ? (
                  <div className={s.category} key={i}>
                    #{category}&nbsp;
                  </div>
                ) : (
                  i === props.categories.length - 1 && (
                    <div className={s.category} key={i}>
                      +{props.categories.length - 5} more &nbsp;
                    </div>
                  )
                )
              )
            : props.categories.map((category, i) => (
                <div className={s.category} key={i}>
                  #{category}&nbsp;
                </div>
              ))}
        </div>
        {!!props.admin && (
          <div className={s.cardActions}>
            <div className={s.action} onClick={statusHandler}>
              <span className='material-icons' style={{ color: status ? 'var(--c-primary)' : 'var(--c-red)' }}>
                {status ? 'toggle_on' : 'toggle_off'}
              </span>
              <span>Status</span>
            </div>
            <div className={s.action} onClick={modalHandler}>
              <span className='material-icons-outlined'>delete</span>
              <span>Delete</span>
            </div>
          </div>
        )}
        <div className={s.totalProducts}>
          <span>Total Products:</span>
          {props.totalProducts}
        </div>
        <Link to={props.link}>View Catalogue</Link>
      </div>
    </div>
  )
}
