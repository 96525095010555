import axios from 'axios'

const API_HOST = process.env.REACT_APP_API_HOST
const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL
// const API_HOST = 'http://' + window.location.hostname + ':5000/api'

const handler = ({ baseURL = API_HOST, url = null, method = 'get', args = {}, isFile = false }) => {
  const request = {}

  request.url = url
  request.baseURL = baseURL
  request.method = method.toLowerCase()
  request.headers = {
    authentication: window.localStorage.getItem('authentication'),
    'Content-Type': isFile ? 'multipart/form-data' : 'application/json'
  }

  if (request.method === 'get') request.params = args
  else request.data = args

  return axios(request).then(
    response => {
      return response.data
    },
    async error => {
      if (error?.response) {
        if (error.response.data.error === 'token expired') {
          localStorage.clear()
          window.location.href = PUBLIC_URL + '/signIn'
        }
        return error.response.data
      } else {
        return {}
      }
    }

    // (error?.response ? error.response.data : {})
  )
}

export default handler
