import s from './styles.module.scss'
import { useState } from 'react'
import { Link } from 'react-router-dom'

const questions = []
// const questions = [
//   {
//     question: "",
//     answer:"",
//   },
//   {
//     question: "",
//     answer:"",
//     links: [
//       {
//         label: '',
//         link: ''
//       }
//     ]
//   },
//   {
//     question: "",
//     answer:"",
//     links: [
//       {
//         label: '',
//         link: ''
//       },
//       {
//         label: '',
//         link: ''
//       }
//     ]
//   },
//   {
//     question: "",
//     answer:"",
//     link: '',
//     links: [
//       {
//         label: '',
//         link: ''
//       }
//     ]
//   },
//   {
//     question: "",
//     answer:"",
//     links: [
//       {
//         label: '',
//         link: ''
//       }
//     ]
//   },
//   {
//     question: "",
//     answer:"",
//   },
//   {
//     question: "",
//     answer:"",
//   },
//   {
//     question: "",
//     answer:"",
//   },
//   {
//     question: "",
//     answer:"",
//     link: '',
//     links: [
//       {
//         label: '',
//         link: ''
//       }
//     ]
//   },
//   {
//     question: "",
//     answer:"",
//     links: [
//       {
//         label: '',
//         link: ''
//       }
//     ]
//   }
// ]

export default function Main() {
  const [selectedQuestion, setSelectedQuestion] = useState(0)
  const [selectedQuestionClass, setSelectedQuestionClass] = useState(0)

  return (
    <div className={s.faqSection + ' indent'}>
      <div className={s.title}>Frequently Asked Questions</div>
      <div className={s.faqBody}>
        {questions.length > 0 ? (
          <>
            <div className={s.faqColumn}>
              {questions.map(
                ({ question, answer, links = [] }, i) =>
                  questions.length / 2 > i && (
                    <div
                      className={
                        i === selectedQuestion
                          ? selectedQuestionClass === 1
                            ? `${s.faq} ${s.activeFAQ}`
                            : s.faq
                          : s.faq
                      }
                      onClick={event => {
                        setSelectedQuestion(i)
                        setSelectedQuestionClass(event.currentTarget.classList.length)
                      }}
                      key={i}
                    >
                      <div className={s.question}>
                        {question}
                        <span className='material-icons-outlined'>
                          {i === selectedQuestion ? (selectedQuestionClass === 1 ? 'remove' : 'add') : 'add'}
                        </span>
                      </div>
                      <div className={s.answer}>
                        {answer}
                        <div className={s.links}>
                          {links.map(({ label, link }, i) => (
                            <Link key={i} onClick={() => window.scroll(0, 0)} to={link}>
                              {label}
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
            <div className={s.faqColumn}>
              {questions.map(
                ({ question, answer, links = [] }, i) =>
                  questions.length / 2 <= i && (
                    <div
                      className={
                        i === selectedQuestion
                          ? selectedQuestionClass === 1
                            ? `${s.faq} ${s.activeFAQ}`
                            : s.faq
                          : s.faq
                      }
                      onClick={event => {
                        setSelectedQuestion(i)
                        setSelectedQuestionClass(event.currentTarget.classList.length)
                      }}
                      key={i}
                    >
                      <div className={s.question}>
                        {question}
                        <span className='material-icons-outlined'>
                          {i === selectedQuestion ? (selectedQuestionClass === 1 ? 'remove' : 'add') : 'add'}
                        </span>
                      </div>
                      <div className={s.answer}>
                        {answer}
                        <div className={s.links}>
                          {links.map(({ label, link }, i) => (
                            <Link key={i} onClick={() => window.scroll(0, 0)} to={link}>
                              {label}
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          </>
        ) : (
          <div className={s.noQuestion}>No questions yet!!</div>
        )}
      </div>
    </div>
  )
}
