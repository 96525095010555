import { useCallback, useEffect, useState } from 'react'

export default function Main({ children, filter, currentPage, next, hasMore, loader }) {
  const [isFetching, setIsFetching] = useState(false)

  const handleScroll = useCallback(() => {
    const footerElement = document.getElementById('footer')
    const footerHeight = footerElement ? footerElement.offsetHeight : 0
    if (
      window.innerHeight + document.documentElement.scrollTop + footerHeight < document.documentElement.offsetHeight ||
      isFetching
    )
      return

    setIsFetching(true)
  }, [isFetching])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  useEffect(() => {
    if (currentPage === 1) setIsFetching(true)
    if (!isFetching) return
    if (currentPage === 1 || hasMore) {
      next(filter, currentPage).then(() => {
        setIsFetching(false)
      })
    }
  }, [isFetching, next, filter, currentPage, hasMore])

  return (
    <>
      {children}
      {hasMore && isFetching && loader}
    </>
  )
}
