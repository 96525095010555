import s from './styles.module.scss'
import { Alert, Input } from 'components'
import { Link, useNavigate } from 'react-router-dom'
import { useRef, useState } from 'react'
import md5 from 'md5'
import * as api from 'api'

export default function Main(props) {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [visible, setVisible] = useState(false)
  const processing = useRef()
  const refiq = useRef()
  const Navigate = useNavigate()

  const signInHandler = async e => {
    e.preventDefault()
    if (processing.current) return
    if (!email) return Alert.warn('Email required!')
    if (!password) return Alert.warn('Password required!')
    processing.current = true

    const loginUser = await api.auth.loginUser({ email, password: md5(password.trim()) })

    if (loginUser.code === 'OK') {
      Alert.success('Signed In!')
      window.localStorage.setItem('authentication', loginUser.authentication)
      Navigate('/admin/dashboard', { replace: true })
    } else {
      Alert.warn(loginUser.error)
    }

    setEmail('')
    setPassword('')
    processing.current = false
  }

  const signUpHandler = e => {
    e.preventDefault()
    if (processing.current) return
    if (!name) return Alert.warn('Name required!')
    if (!email) return Alert.warn('Email required!')
    if (!password) return Alert.warn('Password required!')
    if (!confirmPassword) return Alert.warn('Confirm password required!')
    if (password !== confirmPassword) return Alert.warn('Password & Confirm password does not match')

    processing.current = true
    Alert.success('Signed Up!')
    setName('')
    setEmail('')
    setPassword('')
    setConfirmPassword('')
    processing.current = false
  }

  return (
    <div className={s.main}>
      <div className={s.signIn + ' indent'}>
        <div className={s.heading}>{props.formType === 'Signin' ? 'Sign In' : 'Sign Up'}</div>
        <form
          className={s.signForm}
          onSubmit={props.formType === 'Signin' ? signInHandler : signUpHandler}
          onKeyDown={e => {
            // On enter
            if (e.key === 'Enter') {
              // If not textarea
              if (e.target.tagName !== 'TEXTAREA') {
                // Prevent calling first button "onClick" method
                e.preventDefault()
                // Call "onClick" of button having attribute "submit"
                refiq.current.querySelector('button[type="submit"]')?.click()
              }
            }
          }}
          spellCheck='false'
          autoComplete='off'
          autoCorrect='off'
          autoCapitalize='off'
          ref={refiq}
        >
          {props.formType === 'Signup' && (
            <div>
              <Input.Classic
                label='Name'
                iconName='person'
                type='text'
                placeholder='Enter your name here...'
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </div>
          )}
          <div>
            <Input.Classic
              label='Email'
              type='email'
              iconName='email'
              placeholder='Enter your email here...'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className={s.password}>
            <Input.Classic
              label='Password'
              iconName='lock'
              type={visible ? 'text' : 'password'}
              placeholder='Enter password here...'
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <span className='material-icons-outlined' onClick={() => setVisible(!visible)}>
              {!!visible ? 'visibility_off' : 'visibility'}
            </span>
          </div>
          {props.formType === 'Signup' && (
            <div className={s.password}>
              <Input.Classic
                iconName='lock'
                label='Confirm Password'
                type={visible ? 'text' : 'password'}
                placeholder='Enter password here...'
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
              />
              {!!confirmPassword && !!password && (
                <span className='material-icons-outlined'>{password === confirmPassword ? 'done' : 'close'}</span>
              )}
            </div>
          )}
          <div className={s.lastSec}>
            <Button
              buttonOnClick={props.formType === 'Signin' ? signInHandler : signUpHandler}
              buttonText={props.formType === 'Signin' ? 'Sign in' : 'Sign up'}
              type='submit'
            />
            {props.formType === 'Signin' && <Link to='/'>Forgot Password?</Link>}
          </div>
        </form>
      </div>
    </div>
  )
}

const Button = props => (
  <div className={s.button}>
    <button type={props.type} onClick={props.buttonOnClick}>
      {props.buttonText}
    </button>
  </div>
)
