import s from './styles.module.scss'
import images from 'images'

export default function Main() {
  return (
    <div className={s.main}>
      <div className={s.introSection + ' indent'}>
        <div className={s.illusion}>
          <div className={s.art}>
            <div className={s.title}>About Us</div>
            <div className={s.context}>
              Welcome to StashBlog, your ultimate destination for crafting a stunning online showcase tailored to your
              unique style. We understand the importance of a captivating digital presence for showcasing and cataloging
              your products. Our platform empowers you to create a custom website that not only highlights your
              offerings but also provides a comprehensive toolkit to manage and optimize your online business.
            </div>
          </div>
          <div className={s.title}>About Us</div>
          <div className={s.context}>
            Welcome to StashBlog, your ultimate destination for crafting a stunning online showcase tailored to your
            unique style. We understand the importance of a captivating digital presence for showcasing and cataloging
            your products. Our platform empowers you to create a custom website that not only highlights your offerings
            but also provides a comprehensive toolkit to manage and optimize your online business.
          </div>
        </div>
        <div className={s.illus1}>
          <img src={images.illusDesign4} alt='' />
        </div>
        <div className={s.illus2}>
          <img src={images.illusDesign5} alt='' />
        </div>
      </div>
    </div>
  )
}
