import s from './styles.module.scss'
import { head, imageCompressor } from 'helpers'
import { useEffect, useRef, useState, useCallback } from 'react'
import { Input, Form, Alert, Select, TextArea, Modal, ImageTag, ProgressBar } from 'components'
import { useParams } from 'react-router-dom'
import * as api from 'api'

const IMAGE_HOST = process.env.REACT_APP_IMAGE_HOST

export default function Main() {
  const [loading, setLoading] = useState(false)
  const [category, setCategory] = useState('')
  const [currentCategory, setCurrentCategory] = useState('')
  const [selectOther, setSelectOther] = useState(false)
  const [name, setName] = useState('')
  const [tag, setTag] = useState('')
  const [tags, setTags] = useState([])
  const [disclaimer, setDisclaimer] = useState('')
  const [disclaimers, setDisclaimers] = useState([])
  const [specification, setSpecification] = useState('')
  const [specifications, setSpecifications] = useState([])
  const [units, setUnits] = useState('')
  const [pricePerUnit, setPricePerUnit] = useState('')
  const [description, setDescription] = useState('')
  const [files, setFiles] = useState([])
  const [uploadingImages, setUploadingImages] = useState(0)
  const [isCurrentlyUploaded, setIsCurrentlyUploaded] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [isNewCategoryIntroduced, setIsNewCategoryIntroduced] = useState(false)
  const processing = useRef()
  const { productId } = useParams()

  useEffect(() => {
    head({ title: (!productId ? 'Add Product' : 'Update Product') + ' | StashBlog Admin' })
  }, [productId])

  const onChangeHandler = e => {
    setCategory(e.target.value)
    if (e.target.value === 'Other') {
      setSelectOther(true)
      setCategory('')
    } else {
      setSelectOther(false)
    }
  }

  const onSubmit = async e => {
    if (processing.current) return

    if (!category) return Alert.warn('Category required!')
    if (!name) return Alert.warn('Name required!')
    // if (!units) return Alert.warn('Units required!')
    // if (!pricePerUnit) return Alert.warn('Price per unit required!')
    if (!description) return Alert.warn('Description required!')
    // if (!files.length) return Alert.warn('Image required!')
    // if (files.length < 1) return Alert.warn('Atleast 1 image required!')

    const data = new FormData()

    data.append('productName', name)
    data.append('description', description)
    data.append('category', category)
    if (units) data.append('totalUnits', units)
    if (pricePerUnit) data.append('pricePerUnit', pricePerUnit)

    if (tags.length) {
      tags.forEach((x, i) => {
        data.append('tags', x)
      })
    } else {
      data.append('tags', '')
    }

    specifications.forEach((x, i) => {
      data.append('specifications', x)
    })

    disclaimers.forEach((x, i) => {
      data.append('disclaimer', x)
    })

    files.forEach((file, i) => {
      data.append(`productImages`, file, file.name)
    })

    processing.current = true

    const addProduct = await api.products.addProduct(data)

    if (addProduct.code === 'OK') {
      Alert.success('New product added')
    } else {
      Alert.error(addProduct.error)
    }

    setCurrentCategory(category)
    setIsNewCategoryIntroduced(true)
    setCategory('')
    setName('')
    setUnits('')
    setPricePerUnit('')
    setDescription('')
    setTag('')
    setTags([])
    setDisclaimer('')
    setDisclaimers([])
    setSpecification('')
    setSpecifications([])
    setFiles([])

    processing.current = false
  }

  const onEditSubmit = async e => {
    if (processing.current) return

    if (!category) return Alert.warn('Category required!')
    if (!name) return Alert.warn('Name required!')
    // if (!units) return Alert.warn('Units required!')
    // if (!pricePerUnit) return Alert.warn('Price per unit required!')
    if (!description) return Alert.warn('Description required!')
    if (!files.length) return Alert.warn('Images required!')

    const data = new FormData()

    data.append('id', JSON.parse(window.atob(productId)))
    data.append('productName', name)
    data.append('description', description)
    data.append('category', category)
    if (units) data.append('totalUnits', units)
    if (pricePerUnit) data.append('pricePerUnit', pricePerUnit)
    // data.append('updatedAt', Date.now())

    tags.forEach((x, i) => {
      data.append('tags', x)
    })

    specifications.forEach((x, i) => {
      data.append('specifications', x)
    })

    disclaimers.forEach((x, i) => {
      data.append('disclaimer', x)
    })

    files.forEach((file, i) => {
      data.append(`productImages1`, file, file.name)
    })

    processing.current = true

    const updateProduct = await api.products.updateProduct(data)

    if (updateProduct.code === 'OK') {
      Alert.success('Product updated')
    } else {
      Alert.error(updateProduct.error)
    }

    processing.current = false
  }

  const addTag = () => {
    !!tag && !tags.find(x => x === tag) && setTags(tags.concat(tag.toLowerCase()))
    setTag('')
  }

  const addDisclaimer = () => {
    !!disclaimer && !disclaimers.find(x => x === disclaimer) && setDisclaimers(disclaimers.concat(disclaimer))
    setDisclaimer('')
  }

  const addSpecification = () => {
    !!specification &&
      !specifications.find(x => x === specification) &&
      setSpecifications(specifications.concat(specification))
    setSpecification('')
  }

  const getEditData = useCallback(async () => {
    if (processing.current) {
      return
    }

    processing.current = true
    setLoading(true)

    const editProduct = await api.products.fetchUserProducts({
      id: JSON.parse(window.atob(productId))
    })

    if (editProduct.code === 'OK') {
      const product = editProduct.products[0]
      const imageUrls = product.images.map(img => `${IMAGE_HOST}${img}`)
      const imageResponses = await Promise.all(imageUrls.map(url => fetch(url)))
      const files = await Promise.all(imageResponses.map(res => res.blob()))
      const imageFiles = files.map((blob, index) => {
        const filename = product.images[index]
        const fileType = blob.type
        return new File([blob], filename, { type: fileType })
      })

      setCategory(product.category)
      setName(product.productName)
      setUnits(product.totalUnits)
      setPricePerUnit(product.pricePerUnit)
      setDescription(product.description)
      setTags(product.tags)
      setDisclaimers(product.disclaimer)
      setSpecifications(product.specifications)
      setFiles(imageFiles)
    } else {
      Alert.warn(editProduct.error)
    }

    processing.current = false
    setLoading(false)
  }, [productId])

  useEffect(() => {
    if (productId) getEditData()
  }, [productId, getEditData])

  return (
    !loading && (
      <div className={s.main}>
        <div className={s.addProduct + ' indent'}>
          <div className={s.header} id='nav'>
            <div>
              {/* <span className='material-icons-outlined'>{productId ? 'edit' : 'add_circle_outline'}</span> */}
              {productId ? 'Update Product' : 'Add Product'}
            </div>
            <div className={s.actionIcons}>
              <div title={productId ? 'update product' : 'add product'} onClick={productId ? onEditSubmit : onSubmit}>
                <span className='material-icons-outlined'>{productId ? 'edit' : 'add_circle_outline'}</span>
                {productId ? 'update' : 'add'}
              </div>
            </div>
          </div>

          <div className={s.content + ' innerScroll'}>
            <Form onSubmit={productId ? onEditSubmit : onSubmit} className={s.form}>
              <Select
                onChange={onChangeHandler}
                currentCategory={currentCategory.toLowerCase()}
                isNewCategoryIntroduced={isNewCategoryIntroduced}
                setIsNewCategoryIntroduced={setIsNewCategoryIntroduced}
                defaultValue={productId && category}
              />

              {!!selectOther && (
                <Input.Admin
                  type='text'
                  iconName='category'
                  placeholder='Enter Category'
                  value={category}
                  onChange={e => setCategory(e.target.value)}
                  isRequired
                />
              )}

              <Input.Admin
                type='text'
                iconName='badge'
                placeholder='Enter Name'
                value={name}
                onChange={e => setName(e.target.value)}
                isRequired
              />

              <div className='row'>
                <Input.Admin
                  type='number'
                  iconName='numbers'
                  placeholder='Enter Units'
                  value={units}
                  onChange={e => setUnits(e.target.value)}
                  onKeyDown={e => {
                    if (e.keyCode === 38 || e.keyCode === 40) {
                      e.preventDefault()
                    }
                  }}
                />
                <Input.Admin
                  type='number'
                  iconName='currency_rupee'
                  placeholder='Enter Price Per Unit'
                  value={pricePerUnit}
                  onChange={e => setPricePerUnit(e.target.value)}
                  onKeyDown={e => {
                    if (e.keyCode === 38 || e.keyCode === 40) {
                      e.preventDefault()
                    }
                  }}
                />
              </div>

              <TextArea.Admin
                iconName='description'
                placeholder='Enter Description'
                value={description}
                onChange={e => setDescription(e.target.value)}
                isRequired
              />

              <div className={s.tagSection}>
                <Input.Admin
                  type='text'
                  iconName='sell'
                  placeholder='Enter Tag'
                  value={tag}
                  onChange={e => setTag(e.target.value)}
                  onKeyDown={e => {
                    // On enter
                    if (e.key === 'Enter') {
                      // If not textarea
                      if (e.target.tagName !== 'TEXTAREA') {
                        // Prevent calling first button "onClick" method
                        e.preventDefault()
                        // Call "onClick" of button having attribute "submit"
                        addTag()
                      }
                    }
                  }}
                />
                <Icon add onClick={addTag} />
              </div>

              {tags.length !== 0 && (
                <div className={s.tags}>
                  <div className={s.tagHeading}>Tags</div>
                  <div className={s.addedTags}>
                    {tags.map((y, i) => (
                      <div className={s.tag} key={i}>
                        {y}
                        <Icon onClick={() => setTags(tags.filter(x => x !== y))} />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div className={s.disclaimerSection}>
                <TextArea.Admin
                  iconName='priority_high'
                  placeholder='Enter Disclaimer'
                  value={disclaimer}
                  onChange={e => setDisclaimer(e.target.value)}
                  onKeyDown={e => {
                    // On enter
                    if (e.key === 'Enter') {
                      // If not textarea
                      // if (e.target.tagName !== 'TEXTAREA') {
                      // Prevent calling first button "onClick" method
                      e.preventDefault()
                      // Call "onClick" of button having attribute "submit"
                      addDisclaimer()
                      // }
                    }
                  }}
                />
                <Icon add onClick={addDisclaimer} />
              </div>

              {disclaimers.length !== 0 && (
                <div className={s.disclaimers}>
                  <div className={s.disclaimerHeading}>Disclaimer</div>
                  <div className={s.addedDisclaimers}>
                    {disclaimers.map((y, i) => (
                      <div className={s.disclaimer} key={i}>
                        - {y}
                        <Icon onClick={() => setDisclaimers(disclaimers.filter(x => x !== y))} />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div className={s.specificationSection}>
                <TextArea.Admin
                  iconName='workspace_premium'
                  placeholder='Enter Specification'
                  value={specification}
                  onChange={e => setSpecification(e.target.value)}
                  onKeyDown={e => {
                    // On enter
                    if (e.key === 'Enter') {
                      // If not textarea
                      // if (e.target.tagName !== 'TEXTAREA') {
                      // Prevent calling first button "onClick" method
                      e.preventDefault()
                      // Call "onClick" of button having attribute "submit"
                      addSpecification()
                      // }
                    }
                  }}
                />
                <Icon add onClick={addSpecification} />
              </div>

              {specifications.length !== 0 && (
                <div className={s.specifications}>
                  <div className={s.specificationHeading}>Specification</div>
                  <div className={s.addedSpecifications}>
                    {specifications.map((y, i) => (
                      <div className={s.specification} key={i}>
                        - {y}
                        <Icon onClick={() => setSpecifications(specifications.filter(x => x !== y))} />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div className={s.uploadFiles}>
                <div className={s.uploadLabel}>
                  <span className='material-icons-outlined'>cloud_upload</span>
                  Upload Images
                </div>
                <Input.Admin
                  type='file'
                  filesSelected={files.length}
                  onChange={async e => {
                    setIsCurrentlyUploaded(true)
                    setUploadProgress(0)
                    setUploadingImages(e.target.files.length)
                    let compressed = Array.prototype.slice.call(e.target.files)
                    // console.log('original', files.concat(compressed))
                    compressed = await imageCompressor(compressed, 'products')
                    setUploadProgress(50)
                    compressed = files.concat(compressed)
                    // console.log('compressed', compressed)
                    setUploadProgress(100)
                    setFiles(compressed)
                    Alert.success(e.target.files.length + ' images uploaded')
                    setIsCurrentlyUploaded(false)
                  }}
                />
              </div>

              {!!isCurrentlyUploaded && !!uploadingImages && (
                <div className={s.uploadProgress}>
                  <div>Uploading {uploadingImages} images</div>
                  <ProgressBar progress={uploadProgress} color={'var(--c-green)'} />
                </div>
              )}

              {files.length !== 0 && (
                <div className={s.imagesUploaded}>
                  {files.map((file, i) => (
                    <div className={s.image} key={i}>
                      <div className={s.imageActions}>
                        <span
                          className='material-icons-outlined'
                          onClick={async () => Modal.imageViewer('imageViewerModal', [file])}
                          title='Open full image'
                        >
                          open_in_full
                        </span>
                        <span
                          className='material-icons-outlined'
                          onClick={async () =>
                            Modal.imageViewer('imageViewerModal', [file], true, image => {
                              const updatedFiles = [...files]
                              updatedFiles[i] = image
                              setFiles(updatedFiles)
                            })
                          }
                          title='Crop image'
                        >
                          crop
                        </span>
                        <span
                          className='material-icons-outlined'
                          onClick={async () => {
                            const updatedFiles = [...files]
                            updatedFiles.splice(i, 1)
                            setFiles(updatedFiles)
                          }}
                          title='Remove image'
                        >
                          delete
                        </span>
                      </div>
                      <ImageTag src={URL.createObjectURL(file)} alt='' />
                    </div>
                  ))}
                </div>
              )}
            </Form>
          </div>
        </div>
      </div>
    )
  )
}

const Icon = props => (
  <span className='material-icons-outlined' onClick={props.onClick}>
    {props.add ? 'add_circle_outline' : 'close'}
  </span>
)
