import s from './styles.module.scss'
import images from 'images'
import { Alert, Catalogue, Loader, NoData, ProductCard, Swiper } from 'components'
import * as api from 'api'
import { useCallback, useEffect, useRef, useState } from 'react'
import { getBusinessName, isCustomDomain } from 'helpers'

const limit = 10
const perPage = 4
const mediaMatch = window.matchMedia('(max-width: 1279px)')

export default function Main(props) {
  const userProfile = getBusinessName()
  const [matches, setMatches] = useState(mediaMatch.matches)

  useEffect(() => {
    const handler = e => setMatches(e.matches)
    mediaMatch.addEventListener('change', handler)
    return () => mediaMatch.removeEventListener('change', handler)
  })

  return (
    <div className={s.main}>
      {props.products && <TopProducts matches={matches} userProfile={userProfile} />}
      {props.catalogues && <TopCatalogues matches={matches} userProfile={userProfile} />}
    </div>
  )
}

const TopProducts = props => {
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState([])
  const isUserCustomDomain = isCustomDomain()
  const processing = useRef(false)

  const pageDataHandler = useCallback(async () => {
    if (processing.current) {
      return
    }

    processing.current = true
    setLoading(true)

    const promotedProducts = await api.products.fetchAllUsersProducts({
      limit: limit,
      isPromoted: true,
      businessName: props.userProfile
    })

    if (promotedProducts.code === 'OK') {
      if (props.matches) {
        setProducts(promotedProducts.products)
      } else {
        const chunks = Array.from({ length: Math.ceil(promotedProducts.products.length / perPage) }, (element, index) =>
          promotedProducts.products.slice(index * perPage, index * perPage + perPage)
        )
        setProducts(chunks)
      }
    } else {
      Alert.error(promotedProducts.error)
    }

    processing.current = false
    setLoading(false)
  }, [props.userProfile, props.matches])

  useEffect(() => {
    pageDataHandler()
  }, [pageDataHandler])

  return (
    <div className={s.topProducts}>
      {products.length !== 0 && !props.matches && <Illus matches={props.matches} />}

      <div className={s.heading}>Top Products</div>

      {!!loading && <Loader />}

      {!loading && products.length === 0 && <NoData color='var(--c-font)' />}

      {!loading && products.length !== 0 && (
        <div className={s.topProductsCarousel}>
          {!props.matches ? (
            <Swiper
              infinite
              // timer={3000}
              carouselType='topProducts'
              paginationOn={false}
              navArrowsOn={products.length > 1 ? true : false}
              arrowsInside={false}
              borderTopRadius={false}
            >
              {products.map((chunk, index) => (
                <div
                  className={s.productCards}
                  key={index}
                  style={{
                    gridTemplateColumns: chunk.length === 1 ? 'auto' : 'auto auto'
                  }}
                >
                  {chunk.map(({ _id, productName, businessName, description, images }, i) => (
                    <ProductCard
                      name={productName}
                      desc={description}
                      images={images}
                      productLink={
                        (isUserCustomDomain ? '/products/' : '/' + businessName + '/products/') +
                        window.btoa(JSON.stringify(_id))
                      }
                      key={i}
                    />
                  ))}
                </div>
              ))}
            </Swiper>
          ) : (
            <Swiper
              infinite
              // timer={3000}
              carouselType='topProducts'
              paginationOn={false}
              navArrowsOn={products.length > 1 ? true : false}
              arrowsInside={false}
              borderTopRadius={false}
            >
              {products.map(({ _id, productName, businessName, description, images }, i) => (
                <ProductCard
                  name={productName}
                  desc={description}
                  images={images}
                  productLink={
                    (isUserCustomDomain ? '/products/' : '/' + businessName + '/products/') +
                    window.btoa(JSON.stringify(_id))
                  }
                  key={i}
                />
              ))}
            </Swiper>
          )}
        </div>
      )}
    </div>
  )
}

const TopCatalogues = props => {
  const [catalogues, setCatalogues] = useState([])
  const [loading, setLoading] = useState(false)
  const isUserCustomDomain = isCustomDomain()
  const processing = useRef(false)

  const pageDataHandler = useCallback(async () => {
    if (processing.current) {
      return
    }

    processing.current = true
    setLoading(true)

    const promotedCatalogues = await api.catalogues.fetchAllUsersCatalogues({
      limit: limit,
      isPromoted: true,
      businessName: props.userProfile
    })

    if (promotedCatalogues.code === 'OK') {
      if (props.matches) {
        setCatalogues(promotedCatalogues.catalogues)
      } else {
        const chunks = Array.from(
          { length: Math.ceil(promotedCatalogues.catalogues.length / perPage) },
          (element, index) => promotedCatalogues.catalogues.slice(index * perPage, index * perPage + perPage)
        )
        setCatalogues(chunks)
      }
    } else {
      Alert.error(promotedCatalogues.error)
    }

    processing.current = false
    setLoading(false)
  }, [props.userProfile, props.matches])

  useEffect(() => {
    pageDataHandler()
  }, [pageDataHandler])

  return (
    <div className={s.topCatalogues}>
      {catalogues.length !== 0 && !props.matches && <Illus matches={props.matches} />}

      <div className={s.heading}>Top Catalogues</div>

      {!!loading && <Loader />}

      {!loading && catalogues.length === 0 && <NoData color='var(--c-font)' />}

      {!loading && catalogues.length !== 0 && (
        <div className={s.topCataloguesCarousel}>
          {!props.matches ? (
            <Swiper
              infinite
              // timer={3000}
              carouselType='topCatalogues'
              paginationOn={false}
              navArrowsOn={catalogues.length > 1 ? true : false}
              arrowsInside={false}
              borderTopRadius={false}
            >
              {catalogues.map((chunk, index) => (
                <div
                  className={s.catalogueCards}
                  key={index}
                  style={{
                    gridTemplateColumns: chunk.length === 1 ? 'auto' : 'auto auto'
                  }}
                >
                  {chunk.map(({ _id, businessName, catalogueName, images, categories, totalProducts }, i) => (
                    <Catalogue
                      images={images}
                      name={catalogueName}
                      categories={categories}
                      totalProducts={totalProducts}
                      link={
                        (isUserCustomDomain ? '/catalogues/' : '/' + businessName + '/catalogues/') +
                        window.btoa(JSON.stringify(_id))
                      }
                      key={i}
                    />
                  ))}
                </div>
              ))}
            </Swiper>
          ) : (
            <Swiper
              infinite
              // timer={3000}
              carouselType='topCatalogues'
              paginationOn={false}
              navArrowsOn={catalogues.length > 1 ? true : false}
              arrowsInside={false}
              borderTopRadius={false}
            >
              {catalogues.map(({ _id, businessName, catalogueName, images, categories, totalProducts }, i) => (
                <Catalogue
                  images={images}
                  name={catalogueName}
                  categories={categories}
                  totalProducts={totalProducts}
                  link={
                    (isUserCustomDomain ? '/catalogues/' : '/' + businessName + '/catalogues/') +
                    window.btoa(JSON.stringify(_id))
                  }
                  key={i}
                />
              ))}
            </Swiper>
          )}
        </div>
      )}
    </div>
  )
}

const Illus = () => (
  <>
    <div className={s.illus1 + ' ' + s.illus}>
      <img src={images.illusDesign1} alt='' />
    </div>
    <div className={s.illus2 + ' ' + s.illus}>
      <img src={images.illusDesign1} alt='' />
    </div>
    <div className={s.illus3 + ' ' + s.illus}>
      <img src={images.illusDesign1} alt='' />
    </div>
  </>
)
