import logo from './logo/logo.png'
import logoWhite from './logo/logoWhite.png'
import instagram from './logo/instagram.svg'
import twitter from './logo/twitter.svg'
import facebook from './logo/facebook.svg'
import linkedIn from './logo/linkedIn.svg'
import illusIntro from './illus/illus1.png'
import illusDesign1 from './illus/illus2.png'
import illusDesign2 from './illus/illus3.png'
import illusDesign3 from './illus/illus4.png'
import illusDesign4 from './illus/illus5.png'
import illusDesign5 from './illus/illus6.png'
import illusDesign6 from './illus/illus7.png'
import fast from './features/fast.png'
import reliable from './features/reliable.png'
import simple from './features/simple.png'
import scalable from './features/scalable.png'
import secure from './features/secure.png'
import innovate from './illus/innovate.png'
import client from './client/client.png'
import showcase from './client/showcase.png'
import support from './client/support.png'
import manage from './client/manage.png'
import formImage from './form/form.png'
import dashboard from './illus/dashboard.gif'
import facebookAlt from './logo/facebook.png'
import instagramAlt from './logo/instagram.png'
import pinterest from './logo/pinterest.png'
import linkedInAlt from './logo/linkedin.png'
import youtube from './logo/youtube.png'
import website from './logo/website.png'
import noImage from './placeholders/noImage.png'
import categoryCard from './illus/category.png'
import listing from './illus/listing.png'
import management from './illus/management.png'
import insights from './illus/insights.png'
import marketing from './illus/marketing.png'
import responsive from './illus/responsive.png'

const main = {
  logo,
  logoWhite,
  instagram,
  twitter,
  facebook,
  linkedIn,
  illusIntro,
  illusDesign1,
  illusDesign2,
  illusDesign3,
  illusDesign4,
  illusDesign5,
  illusDesign6,
  fast,
  reliable,
  simple,
  scalable,
  secure,
  innovate,
  client,
  showcase,
  support,
  manage,
  formImage,
  dashboard,
  facebookAlt,
  instagramAlt,
  linkedInAlt,
  pinterest,
  youtube,
  website,
  noImage,
  categoryCard,
  listing,
  management,
  insights,
  marketing,
  responsive
}

export default main
