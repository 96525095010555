import { useCallback, useEffect, useRef, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'
import { Alert, Loader, Modal } from 'components'
import defaultRoutes from './routes'
import customDomainRoutes from './customDomainRoutes'
import commonRoutes from './commonRoutes'
import * as api from 'api'
import { isCustomDomain, setCustomResources } from 'helpers'
const PUBLIC_URL = process.env.PUBLIC_URL

export default function Main() {
  const processing = useRef()
  let routes = [...customDomainRoutes, ...commonRoutes]

  let isUserCustomDomain = isCustomDomain()

  if (!isUserCustomDomain) routes = [...defaultRoutes, ...commonRoutes]

  setCustomResources({ origin: PUBLIC_URL })

  const checkDomain = useCallback(async () => {
    if (processing.current) return

    processing.current = true
    const domainAuthenticity = await api.user.checkDomain({})

    if (domainAuthenticity.code === 'OK') {
      window.localStorage.setItem('businessName', domainAuthenticity.businessName)

      const userLogo = await api.user.fetchUserProfiles({ businessName: domainAuthenticity.businessName })
      if (userLogo.code === 'OK') {
        setCustomResources({
          origin: window.location.origin,
          logo: process.env.REACT_APP_IMAGE_HOST + userLogo.userProfiles[0].logo,
          shortName: domainAuthenticity.businessName,
          name: domainAuthenticity.businessName + ' | A showcase site for products'
        })
      }
    } else {
      window.location.href = '/Error_404_Not_Found'
    }
    processing.current = false
  }, [])

  useEffect(() => {
    if (isUserCustomDomain && window.location.pathname !== '/Error_404_Not_Found') checkDomain()
  }, [isUserCustomDomain, checkDomain])

  return (
    <Router>
      <Alert.Component />
      <Modal.Component />

      <Routes>
        <Route path='/' element={<Redirect />} />

        {routes.map(({ path, Component, Super, auth }, i) => (
          <Route
            key={i}
            path={path}
            element={
              auth ? (
                <Auth>
                  <Super>
                    <Component />
                  </Super>
                </Auth>
              ) : Super ? (
                <Super>
                  <Component />
                </Super>
              ) : (
                <Component />
              )
            }
          />
        ))}

        <Route path='*' element={<Redirect />} />
      </Routes>
    </Router>
  )
}

const Redirect = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/home', { replace: true })
  }, [navigate])
  return null
}

const Auth = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const authenticated = useRef(false)
  const processing = useRef()

  const authTest = useCallback(async () => {
    if (processing.current) {
      return
    }
    processing.current = true

    if (!authenticated.current) {
      const signInAPI = await api.auth.getUser({})

      if (signInAPI.code === 'OK') {
        authenticated.current = true
        setLoading(false)
      } else {
        window.localStorage.clear()
        navigate('/signIn', { replace: true })
      }
    } else setLoading(false)
    processing.current = false
  }, [navigate])

  useEffect(() => {
    if (!window.localStorage.getItem('authentication')) navigate('/signIn', { replace: true })
    else authTest()
  }, [authTest, navigate])

  return loading ? <Loader /> : <div>{children}</div>
}
