import s from './styles.module.scss'
import { head } from 'helpers'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Alert } from 'components'
import { Link } from 'react-router-dom'
import * as api from 'api'

export default function Main() {
  const [status, setStatus] = useState(true)
  const [catalogueStatus, setCatalogueStatus] = useState(true)
  const processing = useRef()
  // const navigate = useNavigate()

  const userProductStatusHandler = useCallback(async () => {
    if (processing.current) return
    processing.current = true

    const userProductStatus = await api.auth.getUser({})

    if (userProductStatus.code === 'OK') {
      setStatus(userProductStatus.user.isAllProductsActive)
      setCatalogueStatus(userProductStatus.user.isAllCataloguesActive)
    } else {
      Alert.error(userProductStatus.error)
    }

    processing.current = false
  }, [])

  const statusHandler = async () => {
    if (processing.current) return
    processing.current = true

    const toggleProductStatus = await api.auth.activateUserProducts({})

    if (toggleProductStatus.code === 'OK') {
      setStatus(!status)
      status ? Alert.warn(toggleProductStatus.success) : Alert.success(toggleProductStatus.success)
    } else {
      Alert.error(toggleProductStatus.error)
    }

    processing.current = false
  }

  const catalogueStatusHandler = async () => {
    if (processing.current) return
    processing.current = true

    const toggleCataloguesStatus = await api.auth.activateUserCatalogues({})

    if (toggleCataloguesStatus.code === 'OK') {
      setCatalogueStatus(!catalogueStatus)
      catalogueStatus ? Alert.warn(toggleCataloguesStatus.success) : Alert.success(toggleCataloguesStatus.success)
    } else {
      Alert.error(toggleCataloguesStatus.error)
    }

    processing.current = false
  }

  // const modalHandler = () => {
  //   Modal.confirm('Are you sure you want to proceed?', async () => {
  //     if (processing.current) return
  //     processing.current = true

  //     const deleteAccount = await api.auth.deleteUser({})

  //     if (deleteAccount.code === 'OK') {
  //       Alert.warn(deleteAccount.success)

  //       setTimeout(() => {
  //         navigate('/admin/users', { replace: true })
  //       }, 2000)
  //     } else {
  //       Alert.error(deleteAccount.error)
  //     }

  //     processing.current = false
  //   })
  // }

  useEffect(() => {
    head({ title: 'Settings | StashBlog Admin' })
    userProductStatusHandler()
  }, [userProductStatusHandler])

  return (
    <div className={s.main}>
      <div className={s.settings + ' indent'}>
        <div className={s.header}>
          <div>
            <span className='material-icons-outlined'>settings</span>
            Settings
          </div>
        </div>
        <div className={s.content + ' innerScroll'}>
          <Setting
            settingName={(status ? 'Deactivate ' : 'Activate ') + 'all your products'}
            color={status ? 'var(--c-green)' : 'var(--c-red)'}
            onClick={statusHandler}
            iconName={status ? 'toggle_on' : 'toggle_off'}
            filledIcon
          />
          <Setting
            settingName={(catalogueStatus ? 'Deactivate ' : 'Activate ') + 'all your catalogues'}
            color={catalogueStatus ? 'var(--c-green)' : 'var(--c-red)'}
            onClick={catalogueStatusHandler}
            iconName={catalogueStatus ? 'toggle_on' : 'toggle_off'}
            filledIcon
          />
          {/* <Setting settingName='Delete Account' onClick={modalHandler} iconName='delete' /> */}
          <Setting settingName='Change Password' color='var(--c-golden)' iconName='lock' link='/admin/changePassword' />
        </div>
      </div>
    </div>
  )
}

const Setting = props => {
  const setting = (
    <div className={s.setting} style={{ borderColor: props.color }}>
      <div className={s.settingName}>{props.settingName}</div>
      <span
        className={props.filledIcon ? 'material-icons' : 'material-icons-outlined'}
        onClick={props.onClick ? props.onClick : () => {}}
        style={{ color: props.color }}
      >
        {props.iconName}
      </span>
    </div>
  )
  return props.link ? <Link to={props.link}>{setting}</Link> : setting
}
