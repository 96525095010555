import React, { useState } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/src/ReactCrop.scss'

// function createImage(file) {
//   return new Promise((resolve, reject) => {
//     const image = new Image()
//     image.src = URL.createObjectURL(file)
//     image.onload = () => resolve(image)
//     image.onerror = reject
//   })
// }

function dataURLtoBlob(dataUrl) {
  const arr = dataUrl.split(',')
  // const mime = arr[0].match(/:(.*?);/)[1]
  const mime = 'image/jpeg'
  const bstr = window.atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

async function imageCrop(file, width, height, x, y) {
  // const image = await createImage(file)
  const image = file.current
  // console.log(image.current)
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  // canvas.width = width
  // canvas.height = height
  context.fillStyle = '#fff'
  canvas.width = Math.round(width)
  canvas.height = Math.round(height)
  context.scale(window.devicePixelRatio, window.devicePixelRatio)
  // context.scale(1 / window.devicePixelRatio, 1 / window.devicePixelRatio)
  context.fillRect(0, 0, canvas.width, canvas.height) // fill the entire canvas with white
  context.drawImage(image, -x, -y)
  const dataUrl = canvas.toDataURL('image/jpeg', 1)
  const blob = dataURLtoBlob(dataUrl)

  let newFile = new File([blob], 'Cropped-' + Date.now() + '.jpeg', {
    type: 'image/jpeg',
    lastModified: Date.now()
  })

  // console.log(dataUrl, image.width, image.height, width, height, canvas.width, canvas.height, window.devicePixelRatio)
  return newFile
}

export default function Main({ children, image, setIsCropped, aspect }) {
  const [crop, setCrop] = useState(null)

  // console.log(crop)
  return (
    <ReactCrop
      crop={crop}
      onDragStart={() => setIsCropped(true)}
      onDragEnd={async () => {
        const cropped = await imageCrop(image, crop.width, crop.height, crop.x, crop.y)
        image.current = cropped
      }}
      onChange={async e => setCrop(e)}
      aspect={aspect}
    >
      {children}
    </ReactCrop>
  )
}
