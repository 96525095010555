import s from './styles.module.scss'

export default function Main(props) {
  return (
    <div className={s.main}>
      <div className={s.progressBar}>
        <div
          className={s.barInner}
          style={{
            width: `${props.progress}%`,
            backgroundColor: props.color
          }}
        >
          <span>{`${props.progress}%`}</span>
        </div>
      </div>
    </div>
  )
}
