import handler from 'api/handler'

const prefix = '/api/message'
const prefix1 = '/mail'
const token = process.env.REACT_APP_CONNECT_TOKEN

const methods = {
  sendMessage: args => {
    args.token = token
    return handler({ method: 'post', baseURL: 'https://connect-api.joahquin.com', url: prefix + '/send', args })
  },
  sendQuery: args => handler({ method: 'post', url: prefix1 + '/sendMail', args })
}

export default methods
