import { Alert, InfiniteScroll, Loader, NoData, Catalogue, Filter } from 'components'
import { useCallback, useRef, useState } from 'react'
import { getBusinessName, isCustomDomain } from 'helpers'
import images from 'images'
import s from './styles.module.scss'
import * as api from 'api'

const perPage = 12

const filterFields = [
  { field: 'Date, new to old' },
  { field: 'Date, old to new' },
  { field: 'Alphabetically A-Z' },
  { field: 'Alphabetically Z-A' }
]

export default function Main() {
  const [heading, setHeading] = useState('Date, new to old')
  const [catalogues, setCatalogues] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const processing = useRef(false)
  const userProfile = getBusinessName()
  const isUserCustomDomain = isCustomDomain()

  const pageDataHandler = useCallback(
    async (sort, currPage) => {
      if (processing.current) return

      processing.current = true
      setLoading(true)
      setCurrentPage(currPage + 1)

      const userCatalogues = await api.catalogues.fetchAllUsersCatalogues({
        pageNo: currPage,
        isPromoted: false,
        businessName: userProfile,
        sort: sort
      })

      if (userCatalogues.code === 'OK') {
        setTotalPages(Math.ceil(userCatalogues.count / perPage))
        setCatalogues([...catalogues, ...userCatalogues.catalogues])
      } else {
        Alert.error(userCatalogues.error)
      }

      processing.current = false
      setLoading(false)
    },
    [catalogues, userProfile]
  )

  const filterHandler = field => {
    setCatalogues([])
    setCurrentPage(1)
    setHeading(field)
  }

  return (
    <div className={s.main}>
      <div className={s.heading}>Catalogues</div>
      <Filter title='sort' heading={heading} filterFields={filterFields} filterHandler={filterHandler} />

      {!loading && catalogues.length === 0 && <NoData color='var(--c-font)' />}

      <InfiniteScroll
        next={pageDataHandler}
        filter={heading}
        currentPage={currentPage}
        hasMore={currentPage <= totalPages}
        loader={<Loader color='var(--c-primary)' colorText='var(--c-font)' />}
      >
        {!loading && catalogues.length !== 0 && (
          <div className={s.cataloguesBody}>
            {catalogues.map(({ _id, businessName, catalogueName, images, categories, totalProducts }, i) => (
              <Catalogue
                images={images}
                name={catalogueName}
                categories={categories}
                totalProducts={totalProducts}
                link={
                  (isUserCustomDomain ? '/catalogues/' : '/' + businessName + '/catalogues/') +
                  window.btoa(JSON.stringify(_id))
                }
                key={i}
              />
            ))}
            <Illus catalogues={catalogues} />
          </div>
        )}
      </InfiniteScroll>

      {!!loading && <Loader color='var(--c-primary)' colorText='var(--c-font)' />}
    </div>
  )
}

const Illus = ({ catalogues }) => <IllusArt iterate={Math.ceil(catalogues.length / 2) - 1} />

const IllusArt = ({ iterate }) => (
  <div className={s.illus}>
    {Array.apply(0, Array(iterate)).map(function (x, index) {
      return (
        <div
          key={index}
          style={
            index % 2 === 0
              ? { top: `${12 + 36 * Math.floor(index / 2)}rem` }
              : { top: `${28 + 36 * Math.floor(index / 2)}rem` }
          }
        >
          <img src={images.illusDesign1} alt='' />
        </div>
      )
    })}
  </div>
)
