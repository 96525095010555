import s from './styles.module.scss'
import images from 'images'

export default function Main() {
  return (
    <div className={s.main}>
      <div className={s.innovationSection + ' indent'}>
        <div className={s.heading}>Our Mission</div>
        <div className={s.content}>
          <div className={s.image}>
            <img src={images.innovate} alt='' />
          </div>
          <div className={s.description}>
            Our mission is to provide individuals, entrepreneurs, and businesses of all sizes with the tools they need
            to build a professional-grade website that perfectly represents their brand and products. We're committed to
            simplifying the process of establishing an online presence, ensuring that creativity knows no bounds, and
            business operations run seamlessly.
            <br />
            <br /> we're not just offering a platform, we're fostering a community of creators and entrepreneurs. We're
            committed to providing exceptional customer support, ensuring that your journey from concept to a fully
            functional website is as smooth as possible. Your success is our success.
          </div>
        </div>
      </div>
    </div>
  )
}
