import { Alert, Input, NoData, TextArea } from 'components'
import { useEffect, useRef, useState } from 'react'
import s from './styles.module.scss'
import images from 'images'
import { getBusinessName, head, isCustomDomain } from 'helpers'
import { connect } from 'react-redux'
import { fetchUserProfile } from '../../../state/actions/userActions'

const Main = ({ dispatch, loading, user }) => {
  const [name, setName] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [contact, setContact] = useState('')
  const userProfile = getBusinessName()
  const isUserCustomDomain = isCustomDomain()
  const processing = useRef()

  useEffect(() => {
    dispatch(fetchUserProfile(userProfile))
  }, [dispatch, userProfile])

  useEffect(() => {
    head({ title: isUserCustomDomain ? 'Contact Us | ' + userProfile : userProfile + "'s Contact Us | StashBlog" })
  }, [userProfile, isUserCustomDomain])

  let count = 0
  if (user.user && user.user.facebookLink) count += 1
  if (user.user && user.user.instagramLink) count += 1
  if (user.user && user.user.pinterestLink) count += 1
  if (user.user && user.user.linkedInLink) count += 1
  if (user.user && user.user.youtubeChannelLink) count += 1
  if (user.user && user.user.websiteLink) count += 1

  const onSubmit = e => {
    e.preventDefault()
    if (processing.current) return
    if (!name) return Alert.warn('Name required!')
    if (!subject) return Alert.warn('Subject required!')
    if (!message) return Alert.warn('Message required!')
    if (!contact) return Alert.warn('Email required!')
    processing.current = true
    Alert.success('Message recieved!')
    setContact('')
    setMessage('')
    setSubject('')
    setName('')
    processing.current = false
  }

  return (
    <div className={s.main}>
      {!!user.user ? (
        <div className={s.userContact + ' indent'}>
          <div className={s.left}>
            {/* <img src={images.formImage} alt='' /> */}
            <div className={s.contactField}>
              <div className={s.fieldHeading}>address</div>
              <div className={s.fieldInfo}>{user.user.address}</div>
            </div>
            <div className={s.contactField}>
              <div className={s.fieldHeading}>call us</div>
              <div className={s.fieldInfo}>
                <a className={s.fieldSubInfo} href={'tel:+91-' + user.user.phoneNo}>
                  +91-{user.user.phoneNo}
                </a>
              </div>
              <div className={s.fieldHeading}>mail us</div>
              <div className={s.fieldInfo}>
                <a
                  className={s.fieldSubInfo}
                  // href={
                  //   'https://mail.google.com/mail/?view=cm&fs=1&to=' + encodeURIComponent(user.user.email) + '&ui=2'
                  // }
                  href={'mailto:' + user.user.email}
                  // href='https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=new'
                  // target='_blank'
                  // rel='noopener noreferrer'
                >
                  {user.user.email}
                </a>
              </div>
            </div>
            <div className={s.contactField}>
              <div className={s.fieldHeading}>social media</div>
              <div
                className={s.fieldSocial}
                style={{
                  gridTemplateColumns: count === 1 ? 'auto' : count === 2 ? 'auto auto' : 'auto auto auto'
                }}
              >
                {user.user.facebookLink && (
                  <a href={user.user.facebookLink} target='_blank' rel='noopener noreferrer' className={s.facebookLink}>
                    <img src={images.facebookAlt} alt='' />
                  </a>
                )}
                {user.user.instagramLink && (
                  <a
                    href={user.user.instagramLink}
                    target='_blank'
                    rel='noopener noreferrer'
                    className={s.instagramLink}
                  >
                    <img src={images.instagramAlt} alt='' />
                  </a>
                )}
                {user.user.pinterestLink && (
                  <a
                    href={user.user.pinterestLink}
                    target='_blank'
                    rel='noopener noreferrer'
                    className={s.pinterestLink}
                  >
                    <img src={images.pinterest} alt='' />
                  </a>
                )}
                {user.user.linkedInLink && (
                  <a href={user.user.linkedInLink} target='_blank' rel='noopener noreferrer' className={s.linkedInLink}>
                    <img src={images.linkedInAlt} alt='' />
                  </a>
                )}
                {user.user.youtubeChannelLink && (
                  <a
                    href={user.user.youtubeChannelLink}
                    target='_blank'
                    rel='noopener noreferrer'
                    className={s.youtubeChannelLink}
                  >
                    <img src={images.youtube} alt='' />
                  </a>
                )}
                {user.user.websiteLink && (
                  <a href={user.user.websiteLink} target='_blank' rel='noopener noreferrer' className={s.websiteLink}>
                    <img src={images.website} alt='' />
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className={s.right}>
            <div className={s.heading}>Get In touch</div>
            <form
              className={s.contactForm}
              onSubmit={onSubmit}
              onKeyDown={e => {
                // On enter
                if (e.key === 'Enter') {
                  // If not textarea
                  if (e.target.tagName !== 'TEXTAREA') {
                    // Prevent calling first button "onClick" method
                    e.preventDefault()
                    // Call "onClick" of button having attribute "submit"
                    // ref.current.querySelector('button[type="submit"]')?.click()
                  }
                }
              }}
              spellCheck='false'
              autoComplete='off'
              autoCorrect='off'
              autoCapitalize='off'
            >
              <Input.Classic
                label='Name'
                iconName='person'
                type='text'
                placeholder='Enter name here...'
                value={name}
                onChange={e => {
                  setName(e.target.value)
                }}
              />
              <Input.Classic
                label='Subject'
                iconName='subject'
                type='text'
                placeholder='Enter subject here...'
                value={subject}
                onChange={e => {
                  setSubject(e.target.value)
                }}
              />
              <TextArea.Classic
                label='Message'
                iconName='message'
                placeholder='Enter your message here...'
                value={message}
                onChange={e => {
                  setMessage(e.target.value)
                }}
              />
              <Input.Classic
                label='Contact'
                iconName='contact_support'
                type='email'
                placeholder='Enter your email here...'
                value={contact}
                onChange={e => {
                  setContact(e.target.value)
                }}
              />
              <Button buttonText='Submit' buttonOnClick={onsubmit} />
            </form>
          </div>
        </div>
      ) : (
        <NoData color='var(--c-font)' />
      )}
    </div>
  )
}

const Button = props => (
  <div className={s.button} onClick={props.buttonOnClick}>
    <button type={props.type}>{props.buttonText}</button>
  </div>
)

const mapStateToProps = state => ({
  user: state.user,
  loading: state.loading
})

export default connect(mapStateToProps)(Main)
