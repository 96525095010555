import { Alert, ImageTag, Modal, Swiper } from 'components'
import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import s from './styles.module.scss'
import * as api from 'api'

const IMAGE_HOST = process.env.REACT_APP_IMAGE_HOST

export default function Main({
  productId,
  category,
  productName,
  description,
  link,
  image,
  products,
  setProducts,
  isPublic,
  totalUnits,
  pricePerUnit,
  cardActions = false,
  promoted = false
}) {
  const [status, setStatus] = useState(isPublic)
  // const [currentIndex, setCurrentIndex] = useState(0)
  const processing = useRef()
  // const imageRef = useRef(null)

  // console.log(image, typeof image)

  const statusHandler = async () => {
    if (processing.current) {
      return
    }
    processing.current = true

    const productStatus = await api.products.updateProductStatus({ id: productId })
    if (productStatus.code === 'OK') {
      setStatus(!status)
      status ? Alert.error(productStatus.message) : Alert.success(productStatus.message)
    } else {
      Alert.error(productStatus.error)
    }

    processing.current = false
  }

  const modalHandler = () => {
    Modal.confirm('Are you sure you want to delete ' + productName + '?', async () => {
      if (processing.current) {
        return
      }
      processing.current = true

      const productDelete = await api.products.deleteProduct({ id: productId })
      if (productDelete.code === 'OK') {
        setProducts(products.filter(product => product._id !== productId))
        Alert.warn(productDelete.success)
      } else {
        Alert.error(productDelete.error)
      }

      processing.current = false
    })
  }

  const formHandler = async () => {
    const imageUrls = image.map(img => `${IMAGE_HOST}/${img}`)
    const imageResponses = await Promise.all(imageUrls.map(url => fetch(url)))
    const files = await Promise.all(imageResponses.map(res => res.blob()))

    Modal.form('form', productId, category, productName, totalUnits, pricePerUnit, description, files, async () => {
      if (processing.current) {
        return
      }
      processing.current = true

      const updatedProduct = await api.products.fetchProduct({ id: productId })

      if (updatedProduct.code === 'OK') {
        setProducts(
          products.map(product => {
            if (product._id === updatedProduct.product._id) {
              return {
                ...product,
                category: updatedProduct.product.category,
                productName: updatedProduct.product.productName,
                description: updatedProduct.product.description,
                totalUnits: updatedProduct.product.totalUnits,
                pricePerUnit: updatedProduct.product.pricePerUnit,
                images: updatedProduct.product.images
              }
            }
            return product
          })
        )
      } else {
        Alert.error(updatedProduct.error)
      }

      processing.current = false
    })
  }

  return (
    <div className={s.card} style={{ width: cardActions && '12.21rem' }}>
      <div className={cardActions ? s.cardImageAdmin : s.cardImage}>
        {!image.length && <ImageTag src='' />}
        {image.length === 1 && <ImageTag src={IMAGE_HOST + image[0]} alt='' style={{ height: '90%' }} />}
        {image.length > 1 && (
          <Swiper
            infinite
            timer={3000}
            carouselType={'casual' + productId}
            paginationOn={true}
            arrowsInside={true}
            navArrowsOn={false}
            borderTopRadius={false}
          >
            {image.map((img, i) => (
              <ImageTag src={IMAGE_HOST + img} key={i} alt='' />
            ))}
          </Swiper>
        )}
      </div>
      {/* <div className={cardActions ? s.imagePaginationAdmin : s.imagePagination}>
        {image.map((img, index) => (
          <button
            key={index}
            onClick={() => {
              const image = imageRef.current
              image.style.opacity = 0
              image.style.transition = 'opacity 1s ease'
              setTimeout(() => {
                image.style.opacity = 1
                setCurrentIndex(index)
              }, 400)
            }}
            className={index === currentIndex ? s.active : ''}
          ></button>
        ))}
      </div> */}
      {!!promoted && (
        <div className={cardActions ? s.promotedBadgeBodyAdmin : s.promotedBadgeBody}>
          <div className={s.promotedBadge}>
            <div className={s.title}>Promoted</div>
            <div className={s.star}>
              <span className='material-icons-outlined'>star</span>
            </div>
          </div>
        </div>
      )}
      <div className={cardActions ? s.cardContentAdmin : s.cardContent}>
        <div className={s.name + ' ellipsis'}>{productName}</div>
        {!!cardActions && (
          <div className={s.cardActions}>
            <div className={s.action} onClick={statusHandler}>
              <span className='material-icons' style={{ color: status ? 'var(--c-primary)' : 'var(--c-red)' }}>
                {status ? 'toggle_on' : 'toggle_off'}
              </span>
              <span>Status</span>
            </div>
            <div className={s.action} onClick={modalHandler}>
              <span className='material-icons-outlined'>delete</span>
              <span>Delete</span>
            </div>
            <div className={s.action} onClick={formHandler}>
              <span className='material-icons-outlined'>edit</span>
              <span>Edit</span>
            </div>
          </div>
        )}
        <div
          className={s.description + ' ellipsis'}
          style={{ fontSize: cardActions ? '0.7rem' : '0.9rem', WebkitLineClamp: 4 }}
        >
          {description}
          {/* {description.length > 133 ? description.substring(0, 133) + '...' : description} */}
        </div>
        <div className={s.link}>
          <Link to={link} target='_blank'>
            View Product
          </Link>
        </div>
      </div>
    </div>
  )
}
