import s from './styles.module.scss'
import images from 'images'
import { useRef, useState } from 'react'
import { Alert, Input, TextArea } from 'components'
import { sendMail } from 'helpers'
// import * as api from 'api'

export default function Main() {
  const [name, setName] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [contact, setContact] = useState('')
  const processing = useRef()

  const onSubmit = async e => {
    e.preventDefault()
    if (processing.current) return
    if (!name) return Alert.warn('Name required!')
    // if (!subject) return Alert.warn('Subject required!')
    if (!message) return Alert.warn('Message required!')
    if (!contact) return Alert.warn('Email required!')
    processing.current = true

    // const mail = await api.mail.sendMessage({ name, message, contact })

    const mail = await sendMail({ name, subject, message, email: contact })

    if (mail.code === 'OK') {
      Alert.success(mail.message)
    } else {
      Alert.success(mail.message)
    }
    setContact('')
    setMessage('')
    setSubject('')
    setName('')
    processing.current = false
  }

  return (
    <div className={s.main}>
      <div className={s.supportForm + ' indent'}>
        <div className={s.left}>
          <img src={images.formImage} alt='' />
        </div>
        <div className={s.right}>
          <div className={s.heading}>Get In touch</div>
          <form
            className={s.contactForm}
            onSubmit={onSubmit}
            onKeyDown={e => {
              // On enter
              if (e.key === 'Enter') {
                // If not textarea
                if (e.target.tagName !== 'TEXTAREA') {
                  // Prevent calling first button "onClick" method
                  e.preventDefault()
                  // Call "onClick" of button having attribute "submit"
                  // ref.current.querySelector('button[type="submit"]')?.click()
                }
              }
            }}
            spellCheck='false'
            autoComplete='off'
            autoCorrect='off'
            autoCapitalize='off'
          >
            <Input.Classic
              label='Name'
              iconName='person'
              type='text'
              placeholder='Enter name here...'
              value={name}
              onChange={e => {
                setName(e.target.value)
              }}
            />
            <Input.Classic
              label='Subject'
              iconName='subject'
              type='text'
              placeholder='Enter subject here...'
              value={subject}
              onChange={e => {
                setSubject(e.target.value)
              }}
            />
            <TextArea.Classic
              label='Message'
              iconName='message'
              placeholder='Enter your message here...'
              value={message}
              onChange={e => {
                setMessage(e.target.value)
              }}
            />
            <Input.Classic
              label='Contact'
              iconName='contact_support'
              type='email'
              placeholder='Enter your email here...'
              value={contact}
              onChange={e => {
                setContact(e.target.value)
              }}
            />
            <Button buttonText='Submit' buttonOnClick={onsubmit} />
          </form>
        </div>
      </div>
    </div>
  )
}

const Button = props => (
  <div className={s.button} onClick={props.buttonOnClick}>
    <button type={props.type}>{props.buttonText}</button>
  </div>
)
