import handler from 'api/handler'

const prefix = '/products'
// const updatedAt = Date.now()

const methods = {
  addProduct: args => handler({ method: 'post', url: prefix + '/addProduct', args, isFile: true }),
  deleteProduct: args => {
    // args.updatedAt = updatedAt
    args.deletedAt = new Date(Date.now()).toISOString()
    args.isActive = false
    return handler({ method: 'delete', url: prefix + '/deleteProduct', args })
  },
  deleteGeneratedLink: args => {
    args.deletedAt = new Date(Date.now()).toISOString()
    args.isActive = false
    return handler({ method: 'delete', url: prefix + '/deleteGeneratedLink', args })
  },
  generatePrivateLink: args => handler({ method: 'post', url: prefix + '/generatePrivateLink', args }),
  privateLinkVisits: args => handler({ method: 'put', url: prefix + '/privateLinkVisits', args }),
  promoteUserProduct: args => handler({ method: 'put', url: prefix + '/promoteUserProduct', args }),
  updateGeneratedLink: args => handler({ method: 'put', url: prefix + '/updatePrivateLink', args }),
  fetchPrivateLink: args => handler({ method: 'get', url: prefix + '/fetchPrivateLink', args }),
  fetchUserProducts: args => handler({ method: 'get', url: prefix + '/fetchAllProducts', args }),
  fetchAllUsersProducts: args => handler({ method: 'get', url: prefix + '/fetchAllUsersProducts', args }),
  updateProduct: args => handler({ method: 'put', url: prefix + '/updateProduct', args, isFile: true }),
  updateProductStatus: args => handler({ method: 'put', url: prefix + '/updateProductStatus', args }),
  updateProductVisits: args => handler({ method: 'put', url: prefix + '/productVisits', args })
}

export default methods
