export default function Main({ origin, logo = "/logo.png", shortName="StashBlog", name="StashBlog | A showcase site for products" }) {
  var canonicalLink = document.querySelector("link[rel='canonical']") || document.createElement("link");
  var appleTouchIcon = document.querySelector("link[rel='apple-touch-icon']") || document.createElement("link");
  var icon = document.querySelector("link[rel='icon']") || document.createElement("link");
  var manifestElement = document.querySelector("link[rel='manifest']") || document.createElement("link");

  canonicalLink.rel = "canonical";
  canonicalLink.href = origin + window.location.pathname;

  appleTouchIcon.rel = "apple-touch-icon"
  appleTouchIcon.href = logo

  icon.rel = "icon"
  icon.href = logo


  const manifest = {
      short_name: shortName,
      name: name,
      icons: [
        {
          src: logo === "/logo.png" ? origin + logo : logo,
          sizes: "48x48 72x72 96x96 128x128 144x144 168x168 192x192 256x256 512x512",
          type: logo === "/logo.png" ? "image/png" : "image/jpeg"
        }
      ],
      start_url: origin,
      display: "standalone",
      theme_color: "#181818",
      orientation: "portrait",
      background_color: "#ffffff"
  }

  const manifestBlob = new Blob([JSON.stringify(manifest)], { type: "application/json" });
  const manifestBlobUrl = URL.createObjectURL(manifestBlob);

  manifestElement.rel = "manifest"
  manifestElement.href=manifestBlobUrl

  var headElement = document.head;

  headElement.appendChild(canonicalLink);
  headElement.appendChild(appleTouchIcon);
  headElement.appendChild(icon);
  headElement.appendChild(manifestElement);

  manifestElement.parentNode.removeChild(manifestElement);
  URL.revokeObjectURL(manifestBlobUrl);
}
