import { Form, Input } from 'components'
import s from './styles.module.scss'
import { head } from 'helpers'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export default function Main() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNo, setPhoneNo] = useState()
  const [address, setAddress] = useState('')
  const [businessName, setBusinessName] = useState('')
  const { planType } = useParams()

  useEffect(() => {
    head({ title: 'Get Started | StashBlog' })
  }, [])

  const planTypeOnly = JSON.parse(window.atob(planType)).split('-')[1]

  const planTypesTheme = {
    Basic: 'var(--c-font)',
    Advanced: 'var(--c-primary)',
    'Advanced +': 'var(--c-golden)',
    Pro: 'var(--c-red)'
  }

  return (
    <div className={s.main}>
      <div className={s.getStarted}>
        <div className={s.content + ' indent'}>
          <div className={s.title}>Tell Us About You</div>
          <Form onSubmit={() => {}} className={s.form}>
            <div className='row'>
              <Input.Admin
                type='text'
                iconName='perm_identity'
                placeholder='Enter your name'
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <Input.Admin
                type='number'
                iconName='call'
                placeholder='Enter your phoneNo'
                value={phoneNo}
                onChange={e => setPhoneNo(e.target.value)}
              />
            </div>
            <Input.Admin
              type='email'
              iconName='email'
              placeholder='Enter your email'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <Input.Admin
              type='text'
              iconName='place'
              placeholder='Enter your full address'
              value={address}
              onChange={e => setAddress(e.target.value)}
            />
            <Input.Admin
              type='text'
              iconName='business'
              placeholder='Enter your business name'
              value={businessName}
              onChange={e => setBusinessName(e.target.value)}
            />
            <div className={s.planType}>
              <span>
                <span className='material-icons-outlined'>content_paste</span>
                Plan Type:
              </span>
              <div style={{ backgroundColor: planTypesTheme[planTypeOnly] }}>{JSON.parse(window.atob(planType))}</div>
            </div>
            <div className={s.proceed}>Next</div>
          </Form>
        </div>
      </div>
    </div>
  )
}
