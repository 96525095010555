import handler from 'api/handler'

const prefix = '/user'

const methods = {
  fetchUserAboutUs: args => handler({ method: 'get', url: prefix + '/fetchUserAboutUs', args }),
  fetchUserCategories: args => handler({ method: 'get', url: prefix + '/fetchUserCategories', args }),
  fetchUserHome: args => handler({ method: 'get', url: prefix + '/fetchUserHome', args }),
  fetchUserProfiles: args => handler({ method: 'get', url: prefix + '/fetchUserProfiles', args }),
  checkDomain: args => handler({ method: 'get', url: prefix + '/checkDomain', args }),
  updateProfileVisits: args => handler({ method: 'put', url: prefix + '/profileVisits', args })
}

export default methods
