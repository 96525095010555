import s from './styles.module.scss'
import images from 'images'
import { Alert, Catalogue, Loader, NoData } from 'components'
import { useCallback, useEffect, useRef, useState } from 'react'
import * as api from 'api'
import { Link } from 'react-router-dom'
import { getBusinessName, isCustomDomain } from 'helpers'

const limit = 6
const mediaMatch = window.matchMedia('(max-width: 1279px)')

export default function Main() {
  const [catalogues, setCatalogues] = useState([])
  const [loading, setLoading] = useState(false)
  const processing = useRef(false)
  const userProfile = getBusinessName()
  const isUserCustomDomain = isCustomDomain()
  const [matches, setMatches] = useState(mediaMatch.matches)

  useEffect(() => {
    const handler = e => setMatches(e.matches)
    mediaMatch.addEventListener('change', handler)
    return () => mediaMatch.removeEventListener('change', handler)
  })

  const pageDataHandler = useCallback(async () => {
    if (processing.current) {
      return
    }

    processing.current = true
    setLoading(true)

    const fetchUserCatalogues = await api.catalogues.fetchAllUsersCatalogues({
      businessName: userProfile,
      limit: limit
    })

    if (fetchUserCatalogues.code === 'OK') {
      setCatalogues(fetchUserCatalogues.catalogues)
    } else {
      Alert.error(fetchUserCatalogues.error)
    }

    processing.current = false
    setLoading(false)
  }, [userProfile])

  useEffect(() => {
    pageDataHandler()
  }, [pageDataHandler])

  return (
    <div className={s.main}>
      <div className={s.catalogues}>
        <div className={s.heading}>Catalogues</div>

        {!!loading && <Loader color='var(--c-primary)' colorText='var(--c-font)' />}

        {!loading && catalogues.length === 0 && <NoData color='var(--c-black)' />}

        {!loading && catalogues.length !== 0 && (
          <>
            <div
              className={s.cataloguesBody}
              style={{
                gridTemplateColumns: !matches
                  ? catalogues.length === 1
                    ? 'auto'
                    : catalogues.length === 2
                    ? 'auto auto'
                    : 'auto auto auto'
                  : 'auto'
              }}
            >
              {!matches && <Illus />}
              {catalogues.map(({ _id, businessName, catalogueName, images, categories, totalProducts }, i) => (
                <Catalogue
                  images={images}
                  name={catalogueName}
                  categories={categories}
                  totalProducts={totalProducts}
                  link={
                    (isUserCustomDomain ? '/catalogues/' : '/' + businessName + '/catalogues/') +
                    window.btoa(JSON.stringify(_id))
                  }
                  key={i}
                />
              ))}
            </div>
            <Link
              to={isUserCustomDomain ? '/catalogues/' : '/' + userProfile + '/catalogues/'}
              className={s.exploreMore}
            >
              Explore More
            </Link>
          </>
        )}
      </div>
    </div>
  )
}

const Illus = () => (
  <>
    <div className={s.illus1 + ' ' + s.illus}>
      <img src={images.illusDesign1} alt='' />
    </div>
    <div className={s.illus2 + ' ' + s.illus}>
      <img src={images.illusDesign1} alt='' />
    </div>
    <div className={s.illus3 + ' ' + s.illus}>
      <img src={images.illusDesign1} alt='' />
    </div>
  </>
)
