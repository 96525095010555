import { Link } from 'react-router-dom'
import s from './styles.module.scss'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fetchUserProfile } from '../../../state/actions/userActions'
import { getBusinessName, isCustomDomain } from 'helpers'
import { ImageTag, NavSearch } from 'components'

const Main = ({ dispatch, loading, user, fullscreen, setFullscreen }) => {
  const userProfile = getBusinessName()
  const isUserCustomDomain = isCustomDomain()
  const [isScrolled, setIsScrolled] = useState(false)
  const IMAGE_HOST = process.env.REACT_APP_IMAGE_HOST

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    dispatch(fetchUserProfile(userProfile))
  }, [dispatch, userProfile])

  return (
    !!user.user && (
      <div id='nav' className={isScrolled ? s.outerNav + ' ' + s.outerNavScrolled : s.outerNav}>
        <nav className={isScrolled ? s.nav + ' ' + s.scrolled : s.nav}>
          <div className={s.left}>
            <Link to={isUserCustomDomain ? '/home' : '/' + user.user.businessName + '/home'}>
              <ImageTag src={IMAGE_HOST + user.user.logo} alt='' />
              <div className={s.businessName}>{user.user.businessName}</div>
            </Link>

            <div className={s.searchField}>
              <NavSearch />
            </div>
          </div>

          <div className={s.right}>
            <NavLinks user={user.user} onClickHandler={() => setFullscreen(false)} />
          </div>

          <div className={s.hamburger + ' material-icons'} onClick={() => setFullscreen(true)}>
            menu
          </div>
        </nav>

        {!!fullscreen && (
          <div className={s.fullscreenMenu}>
            <div className={s.close + ' material-icons'} onClick={() => setFullscreen(false)}>
              close
            </div>
            <div className={s.navSearch}>
              <NavSearch />
            </div>
            <NavLinks user={user.user} onClickHandler={() => setFullscreen(false)} />
          </div>
        )}
      </div>
    )
  )
}

const NavLinks = user => {
  const userProfile = getBusinessName()
  const isUserCustomDomain = isCustomDomain()

  return (
    <div className={s.navLinks}>
      {navLinks.map(({ name, path }, i) =>
        path !== '/catalogues' ? (
          <Link
            key={i}
            to={path === '/signIn' ? path : isUserCustomDomain ? path : '/' + userProfile + path}
            className={
              window.location.pathname.startsWith(
                path === '/signIn' ? path : isUserCustomDomain ? path : '/' + userProfile + path
              )
                ? s.active
                : ''
            }
            onClick={user.onClickHandler}
            target={path === '/signIn' ? '_blank' : ''}
          >
            <div>{name}</div>
          </Link>
        ) : (
          user.user.totalCatalogues > 0 && (
            <Link
              key={i}
              to={path === '/signIn' ? path : isUserCustomDomain ? path : '/' + userProfile + path}
              className={
                window.location.pathname.startsWith(
                  path === '/signIn' ? path : isUserCustomDomain ? path : '/' + userProfile + path
                )
                  ? s.active
                  : ''
              }
              onClick={user.onClickHandler}
              target={path === '/signIn' ? '_blank' : ''}
            >
              <div>{name}</div>
            </Link>
          )
        )
      )}
    </div>
  )
}

const navLinks = [
  {
    name: 'Home',
    path: '/home'
  },
  {
    name: 'Products',
    path: '/products'
  },
  {
    name: 'Catalogues',
    path: '/catalogues'
  },
  {
    name: 'About Us',
    path: '/aboutus'
  },
  {
    name: 'Contact Us',
    path: '/contactus'
  }
  // {
  //   name: 'Login',
  //   path: '/signIn'
  // }
]

const mapStateToProps = state => ({
  user: state.user,
  loading: state.loading
})

export default connect(mapStateToProps)(Main)
