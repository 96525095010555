import s from './styles.module.scss'
import { head } from 'helpers'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Alert, Input, Loader, Modal, NoData, Pagination, Table } from 'components'
import * as api from 'api'
import { Link } from 'react-router-dom'

const perPage = 12

export default function Main() {
  const [currentPage, setCurrentPage] = useState(1)
  const [currentSearchedPage, setCurrentSearchedPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [totalSearchedPages, setTotalSearchedPages] = useState(0)
  const [products, setProducts] = useState([])
  const [searchData, setSearchData] = useState([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [activeProducts, setActiveProducts] = useState('casual')
  const [searchResults, setSearchResults] = useState(0)

  const processing = useRef()

  const pageDataHandler = useCallback(
    async currPage => {
      if (processing.current) {
        return
      }
      processing.current = true
      setLoading(true)
      setCurrentPage(currPage)

      const fetchAllProducts = await api.products.fetchUserProducts({
        pageNo: currPage,
        isPromoted: activeProducts === 'casual' ? false : true
      })

      if (fetchAllProducts.code === 'OK') {
        setTotalPages(Math.ceil(fetchAllProducts.count / perPage))
        setProducts(fetchAllProducts.products)
      } else {
        Alert.error(fetchAllProducts.error)
      }
      processing.current = false
      setLoading(false)
    },
    [activeProducts]
  )

  useEffect(() => {
    head({ title: 'Products | StashBlog Admin' })
  }, [])

  useEffect(() => {
    pageDataHandler(currentPage)
  }, [pageDataHandler, currentPage])

  const searchHandler = async currPage => {
    if (processing.current) return
    processing.current = true
    setLoading(true)
    setCurrentSearchedPage(currPage)

    const searchedProduct = await api.products.fetchUserProducts({
      productName: search,
      pageNo: currPage,
      isPromoted: activeProducts === 'casual' ? false : true
    })

    if (searchedProduct.code === 'OK') {
      if (searchedProduct.products.length === 0) {
        setSearchData([])
        setSearch('')
        setCurrentSearchedPage(1)
        Alert.warn('No result found')
      } else {
        setSearchResults(searchedProduct.count)
        setTotalSearchedPages(Math.ceil(searchedProduct.count / perPage))
        setSearchData(searchedProduct.products)
      }
    } else {
      Alert.warn(searchedProduct.error)
    }

    setLoading(false)
    processing.current = false
  }

  const statusHandler = async id => {
    const product = products.find(x => x._id === id)
    if (product) {
      if (processing.current) {
        return
      }
      processing.current = true
      const productStatus = await api.products.updateProductStatus({ id })
      if (productStatus.code === 'OK') {
        product.isPublic = !product.isPublic

        !product.isPublic ? Alert.error(productStatus.message) : Alert.success(productStatus.message)
      } else {
        Alert.error(productStatus.error)
      }
      processing.current = false
    } else {
      Alert.error('Some error occured')
    }
  }

  const promoteHandler = async id => {
    const product = products.find(x => x._id === id)
    if (product) {
      if (processing.current) return

      processing.current = true

      const promoteProduct = await api.products.promoteUserProduct({ id })

      if (promoteProduct.code === 'OK') {
        product.isPromoted = !product.isPromoted

        !promoteProduct.product.isPromoted
          ? Alert.warn(promoteProduct.product.productName + ' is Demoted')
          : Alert.success(promoteProduct.product.productName + ' is Promoted')
      } else {
        Alert.error(promoteProduct.error)
      }

      processing.current = false
    } else {
      Alert.error('Some error occured')
    }
  }

  const deleteHandler = async id => {
    const product = products.find(x => x._id === id)
    if (product) {
      Modal.confirm('Are you sure you want to delete ' + product.productName + '?', async () => {
        if (processing.current) {
          return
        }
        processing.current = true

        const productDelete = await api.products.deleteProduct({ id })
        if (productDelete.code === 'OK') {
          setProducts(products.filter(product => product._id !== id))
          Alert.warn(productDelete.success)
        } else {
          Alert.error(productDelete.error)
        }

        processing.current = false
      })
    } else {
      Alert.error('Some error occured')
    }
  }

  // const editHandler = async id => {
  //   if (processing.current) {
  //     return
  //   }
  //   processing.current = true

  //   const product = products.find(x => x._id === id)

  //   if (product) {
  //     const imageUrls = product.images.map(img => `${IMAGE_HOST}${img}`)
  //     const imageResponses = await Promise.all(imageUrls.map(url => fetch(url)))
  //     const files = await Promise.all(imageResponses.map(res => res.blob()))
  //     const imageFiles = files.map((blob, index) => {
  //       const filename = product.images[index]
  //       const fileType = blob.type
  //       return new File([blob], filename, { type: fileType })
  //     })

  //     if (files) {
  //       Modal.form(
  //         'form',
  //         product._id,
  //         product.category,
  //         product.productName,
  //         product.totalUnits,
  //         product.pricePerUnit,
  //         product.description,
  //         product.tags,
  //         product.disclaimer,
  //         product.specifications,
  //         imageFiles,
  //         async () => {
  //           const updatedProduct = await api.products.fetchUserProducts({ id })

  //           if (updatedProduct.code === 'OK') {
  //             setProducts(
  //               products.map(x => {
  //                 if (x._id === updatedProduct.product._id) {
  //                   return {
  //                     ...x,
  //                     category: updatedProduct.product.category,
  //                     productName: updatedProduct.product.productName,
  //                     description: updatedProduct.product.description,
  //                     totalUnits: updatedProduct.product.totalUnits,
  //                     pricePerUnit: updatedProduct.product.pricePerUnit,
  //                     tags: updatedProduct.product.tags,
  //                     disclaimer: updatedProduct.product.disclaimer,
  //                     specifications: updatedProduct.product.specifications,
  //                     images: updatedProduct.product.images
  //                   }
  //                 }
  //                 return x
  //               })
  //             )
  //           } else {
  //             Alert.error(updatedProduct.error)
  //           }
  //         }
  //       )
  //     }
  //   } else {
  //     Alert.error('Some error occured')
  //   }
  //   processing.current = false
  // }

  return (
    <div className={s.main}>
      <div className={s.products + ' indent'}>
        <div className={s.header} id='nav'>
          <div>
            <span className='material-icons-outlined'>view_carousel</span>
            Products
          </div>
          <div className={s.actionIcons}>
            <Link className={s.add} to='/admin/products/addProduct'>
              <span className='material-icons-outlined'>add_circle_outline</span> add
            </Link>
          </div>
        </div>
        <div
          className={s.content + ' innerScroll'}
          id='carousel'
          style={{ justifyContent: loading ? 'center' : 'flex-start' }}
        >
          {!loading && (
            <>
              <Input.Search
                type='text'
                placeholder='Search...'
                iconName='search'
                value={search}
                onChange={e => {
                  setSearch(e.target.value)
                  e.target.value === '' && setSearchData([])
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter' && search !== '') {
                    searchHandler(currentSearchedPage)
                  }
                }}
                onClick={() => {
                  search !== '' && searchHandler(currentSearchedPage)
                }}
              />

              {searchData.length !== 0 && <div className={s.searchResults}>{searchResults} search results found</div>}

              <div className={s.tags} id='content'>
                <div
                  className={activeProducts === 'casual' ? s.tag + ' ' + s.active : s.tag}
                  onClick={() => {
                    setActiveProducts('casual')
                    setSearchData([])
                    setSearch('')
                    setCurrentPage(1)
                  }}
                >
                  Casual
                </div>
                <div
                  className={activeProducts === 'promoted' ? s.tag + ' ' + s.active : s.tag}
                  onClick={() => {
                    setActiveProducts('promoted')
                    setSearchData([])
                    setSearch('')
                    setCurrentPage(1)
                  }}
                >
                  Promoted
                </div>
              </div>
            </>
          )}

          {!!loading && <Loader color='var(--c-primary)' colorText='var(--c-font-dark)' />}

          {!loading && products.length === 0 && <NoData color='var(--c-font)' />}

          {!loading && products.length !== 0 && (
            <>
              {searchData.length === 0 ? (
                <Table.Products
                  data={products}
                  perPage={perPage}
                  currentPage={currentPage}
                  statusHandler={statusHandler}
                  promoteHandler={promoteHandler}
                  deleteHandler={deleteHandler}
                />
              ) : (
                <Table.Products
                  data={searchData}
                  perPage={perPage}
                  currentPage={currentSearchedPage}
                  statusHandler={statusHandler}
                  promoteHandler={promoteHandler}
                  deleteHandler={deleteHandler}
                />
              )}

              {searchData.length === 0 && totalPages > 1 && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  pageDataHandler={pageDataHandler}
                  adminPagination={true}
                />
              )}

              {searchData.length !== 0 && totalSearchedPages > 1 && (
                <Pagination
                  currentPage={currentSearchedPage}
                  totalPages={totalSearchedPages}
                  pageDataHandler={searchHandler}
                  adminPagination={true}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
