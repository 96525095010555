import s from './styles.module.scss'

export default function Main(props) {
  return (
    <div className={s.main}>
      <div className={s.textField}>
        <span className={'material-icons-outlined ' + (props.dark ? s.icon + ' ' + s.iconAlt : s.icon)}>
          {props.iconName}
        </span>
        <textarea
          className={props.dark ? s.textarea + ' ' + s.textareaAlt : s.textarea}
          rows='5'
          cols='50'
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
        />
        {!!props.label && <label className={props.dark ? s.label + ' ' + s.labelAlt : s.label}>{props.label}</label>}
      </div>
    </div>
  )
}
