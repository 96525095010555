import UserProfile from './user/userProfile'
import ProductPage from './user/productPage'
import ProductsPage from './user/productsPage'
import CataloguesPage from './user/cataloguesPage'
import CataloguePage from './user/cataloguePage'
import UserAboutUs from './user/userAboutUs'
import UserContactUs from './user/userContactUs'

import PrivateLink from './common/privateLink'
import SignIn from './admin/signIn'

import * as Layout from 'layouts'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const customDomain = [
  {
    path: '/',
    Component: () => {
      const navigate = useNavigate()

      useEffect(() => {
        navigate('/home', { replace: true })
      }, [navigate])
    },
    Super: Layout.User,
    auth: false
  },
  {
    path: '/home',
    Component: UserProfile,
    Super: Layout.User,
    auth: false
  },
  {
    path: '/products/:productId',
    Component: ProductPage,
    Super: Layout.User,
    auth: false
  },
  {
    path: '/products',
    Component: ProductsPage,
    Super: Layout.User,
    auth: false
  },
  {
    path: '/catalogues/:catalogueId',
    Component: CataloguePage,
    Super: Layout.User,
    auth: false
  },
  {
    path: '/catalogues',
    Component: CataloguesPage,
    Super: Layout.User,
    auth: false
  },
  {
    path: '/aboutus',
    Component: UserAboutUs,
    Super: Layout.User,
    auth: false
  },
  {
    path: '/contactus',
    Component: UserContactUs,
    Super: Layout.User,
    auth: false
  },
  {
    path: '/privateLink',
    Component: PrivateLink,
    Super: Layout.User,
    auth: false
  },
  {
    path: '/signIn',
    Component: () => {
      const isAuthenticated = !!window.localStorage.getItem('authentication')

      const navigate = useNavigate()

      useEffect(() => {
        if (isAuthenticated) navigate('/admin/dashboard', { replace: true })
      }, [isAuthenticated, navigate])

      return isAuthenticated ? null : <SignIn />
    },
    auth: false
  }
]
export default customDomain
