import { useCallback, useEffect, useRef, useState } from 'react'
import s from './styles.module.scss'
import * as api from 'api'
import { Alert } from 'components'

export default function Main({
  onChange,
  defaultValue,
  currentCategory,
  isNewCategoryIntroduced,
  setIsNewCategoryIntroduced
}) {
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const processing = useRef()
  const selectRef = useRef()

  const categoryDataHandler = useCallback(async () => {
    if (processing.current) {
      return
    }
    processing.current = true
    setLoading(true)

    const fetchCategories = await api.user.fetchUserCategories({})

    if (fetchCategories.code === 'OK') {
      setCategories(fetchCategories.userCategories)
    } else {
      Alert.error(fetchCategories.error)
    }
    processing.current = false
    setLoading(false)
  }, [])

  useEffect(() => {
    categoryDataHandler()
  }, [categoryDataHandler])

  useEffect(() => {
    if (isNewCategoryIntroduced) {
      selectRef.current.value = 'Select Category*'
    }
    if (isNewCategoryIntroduced && currentCategory && !categories.includes(currentCategory)) {
      setCategories(prevCategories => [...prevCategories, currentCategory])
      setIsNewCategoryIntroduced(false)
    }
  }, [isNewCategoryIntroduced, currentCategory, categories, setIsNewCategoryIntroduced])

  return (
    !loading && (
      <div className={s.fieldAdmin}>
        <select
          onChange={onChange}
          defaultValue={!defaultValue ? 'Select Category*' : defaultValue}
          className={s.select}
          ref={selectRef}
        >
          <option className={s.option} disabled>
            Select Category*
          </option>

          {categories.map((value, i) => {
            return (
              <option className={s.option} value={value} key={i}>
                {value}
              </option>
            )
          })}
          <option className={s.option}>Other</option>
        </select>
      </div>
    )
  )
}
