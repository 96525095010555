import s from './styles.module.scss'
import { head } from 'helpers'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Alert, Input, Loader, Modal, NoData, Pagination, Table } from 'components'
import * as api from 'api'
import { Link } from 'react-router-dom'

const perPage = 12

export default function Main() {
  const [currentPage, setCurrentPage] = useState(1)
  const [currentSearchedPage, setCurrentSearchedPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [totalSearchedPages, setTotalSearchedPages] = useState(0)
  const [catalogues, setCatalogues] = useState([])
  const [searchData, setSearchData] = useState([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [activeCatalogues, setActiveCatalogues] = useState('casual')
  const [searchResults, setSearchResults] = useState(0)

  const processing = useRef()

  const pageDataHandler = useCallback(
    async currPage => {
      if (processing.current) {
        return
      }
      processing.current = true
      setLoading(true)
      setCurrentPage(currPage)

      const fetchAllCatalogues = await api.catalogues.fetchUserCatalogues({
        pageNo: currPage,
        isPromoted: activeCatalogues === 'casual' ? false : true
      })

      if (fetchAllCatalogues.code === 'OK') {
        setTotalPages(Math.ceil(fetchAllCatalogues.count / perPage))
        setCatalogues(fetchAllCatalogues.catalogues)
      } else {
        Alert.error(fetchAllCatalogues.error)
      }
      processing.current = false
      setLoading(false)
    },
    [activeCatalogues]
  )

  useEffect(() => {
    head({ title: 'Catalogues | StashBlog Admin' })
  }, [])

  useEffect(() => {
    pageDataHandler(currentPage)
  }, [pageDataHandler, currentPage])

  const searchHandler = async currPage => {
    if (processing.current) return
    processing.current = true
    setLoading(true)
    setCurrentSearchedPage(currPage)

    const searchedCatalogue = await api.catalogues.fetchUserCatalogues({
      catalogueName: search,
      pageNo: currPage,
      isPromoted: activeCatalogues === 'casual' ? false : true
    })

    if (searchedCatalogue.code === 'OK') {
      if (searchedCatalogue.catalogues.length === 0) {
        setSearchData([])
        setSearch('')
        setCurrentSearchedPage(1)
        Alert.warn('No result found')
      } else {
        setSearchResults(searchedCatalogue.count)
        setTotalSearchedPages(Math.ceil(searchedCatalogue.count / perPage))
        setSearchData(searchedCatalogue.catalogues)
      }
    } else {
      Alert.warn(searchedCatalogue.error)
    }

    setLoading(false)
    processing.current = false
  }

  const statusHandler = async id => {
    const catalogue = catalogues.find(x => x._id === id)

    if (catalogue) {
      if (processing.current) {
        return
      }
      processing.current = true

      const catalogueStatus = await api.catalogues.updateCatalogueStatus({ id })
      if (catalogueStatus.code === 'OK') {
        catalogue.isPublic = !catalogue.isPublic
        !catalogue.isPublic ? Alert.error(catalogueStatus.message) : Alert.success(catalogueStatus.message)
      } else {
        Alert.error(catalogueStatus.error)
      }

      processing.current = false
    } else {
      Alert.error('Some error occured')
    }
  }

  const promoteHandler = async id => {
    const catalogue = catalogues.find(x => x._id === id)

    if (catalogue) {
      if (processing.current) return

      processing.current = true

      const promoteCatalogue = await api.catalogues.promoteUserCatalogue({ id })

      if (promoteCatalogue.code === 'OK') {
        catalogue.isPromoted = !catalogue.isPromoted
        !promoteCatalogue.catalogue.isPromoted
          ? Alert.warn(promoteCatalogue.catalogue.catalogueName + ' is Demoted')
          : Alert.success(promoteCatalogue.catalogue.catalogueName + ' is Promoted')
      } else {
        Alert.error(promoteCatalogue.error)
      }

      processing.current = false
    } else {
      Alert.error('Some error occured')
    }
  }

  const deleteHandler = id => {
    const catalogue = catalogues.find(x => x._id === id)

    if (catalogue) {
      Modal.confirm('Are you sure you want to delete ' + catalogue.catalogueName + '?', async () => {
        if (processing.current) {
          return
        }
        processing.current = true

        const catalogueDelete = await api.catalogues.deleteCatalogue({ id })
        if (catalogueDelete.code === 'OK') {
          setCatalogues(catalogues.filter(x => x._id !== id))
          Alert.warn(catalogueDelete.success)
        } else {
          Alert.error(catalogueDelete.error)
        }

        processing.current = false
      })
    } else {
      Alert.error('Some error occured')
    }
  }

  const editHandler = id => {
    if (processing.current) {
      return
    }
    processing.current = true
    const catalogue = catalogues.find(x => x._id === id)

    if (catalogue) {
      Modal.catalogue('cataloguemodal', catalogue._id, catalogue.catalogueName, catalogue.description, async () => {
        const updatedCatalogue = await api.catalogues.fetchUserCatalogues({ id })
        if (updatedCatalogue.code === 'OK') {
          setCatalogues(
            catalogues.map(x => {
              if (x._id === updatedCatalogue.catalogues._id) {
                return {
                  ...x,
                  catalogueName: updatedCatalogue.catalogue.catalogueName,
                  description: updatedCatalogue.catalogue.description
                }
              }
              return x
            })
          )
        } else {
          Alert.error(updatedCatalogue.error)
        }
      })
    }
    processing.current = false
  }

  return (
    <div className={s.main}>
      <div className={s.catalogues + ' indent'}>
        <div className={s.header} id='nav'>
          <div>
            <span className='material-icons-outlined'>view_carousel</span>
            Catalogues
          </div>
          <div className={s.actionIcons}>
            <Link className={s.add} to='/admin/catalogues/addCatalogue'>
              <span className='material-icons-outlined'>add_circle_outline</span> add
            </Link>
          </div>
        </div>
        <div
          className={s.content + ' innerScroll'}
          id='carousel'
          style={{ justifyContent: loading ? 'center' : 'flex-start' }}
        >
          {!loading && (
            <>
              <Input.Search
                type='text'
                placeholder='Search...'
                iconName='search'
                value={search}
                onChange={e => {
                  setSearch(e.target.value)
                  e.target.value === '' && setSearchData([])
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter' && search !== '') {
                    searchHandler(currentSearchedPage)
                  }
                }}
                onClick={() => {
                  search !== '' && searchHandler(currentSearchedPage)
                }}
              />

              {searchData.length !== 0 && <div className={s.searchResults}>{searchResults} search results found</div>}

              <div className={s.tags} id='content'>
                <div
                  className={activeCatalogues === 'casual' ? s.tag + ' ' + s.active : s.tag}
                  onClick={() => {
                    setActiveCatalogues('casual')
                    setSearchData([])
                    setSearch('')
                    setCurrentPage(1)
                  }}
                >
                  Casual
                </div>
                <div
                  className={activeCatalogues === 'promoted' ? s.tag + ' ' + s.active : s.tag}
                  onClick={() => {
                    setActiveCatalogues('promoted')
                    setSearchData([])
                    setSearch('')
                    setCurrentPage(1)
                  }}
                >
                  Promoted
                </div>
              </div>
            </>
          )}

          {!!loading && <Loader color='var(--c-primary)' colorText='var(--c-font-dark)' />}

          {!loading && catalogues.length === 0 && <NoData color='var(--c-font)' />}

          {!loading && catalogues.length !== 0 && (
            <>
              {searchData.length === 0 ? (
                // activeCatalogues === 'casual' ? (
                // <CardCarousel data={catalogues} setCatalogues={setCatalogues} promoted={false} />
                <Table.Catalogue
                  data={catalogues}
                  perPage={perPage}
                  currentPage={currentPage}
                  statusHandler={statusHandler}
                  promoteHandler={promoteHandler}
                  deleteHandler={deleteHandler}
                  editHandler={editHandler}
                />
              ) : (
                // ) : (
                //   <CardCarousel data={catalogues} setCatalogues={setCatalogues} promoted={true} />
                // )
                // searchData.length !== 0 && activeCatalogues === 'casual' ? (
                //   <CardCarousel data={searchData} setCatalogues={setSearchData} promoted={false} />
                // ) : (
                //   <CardCarousel data={searchData} setCatalogues={setSearchData} promoted={true} />
                // )
                <Table.Catalogue
                  data={searchData}
                  perPage={perPage}
                  currentPage={currentSearchedPage}
                  statusHandler={statusHandler}
                  promoteHandler={promoteHandler}
                  deleteHandler={deleteHandler}
                  editHandler={id => {}}
                />
              )}

              {searchData.length === 0 && totalPages > 1 && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  pageDataHandler={pageDataHandler}
                  adminPagination={true}
                />
              )}

              {searchData.length !== 0 && totalSearchedPages > 1 && (
                <Pagination
                  currentPage={currentSearchedPage}
                  totalPages={totalSearchedPages}
                  pageDataHandler={searchHandler}
                  adminPagination={true}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

// const CardCarousel = props => (
//   <div className={s.carousel}>
//     {props.data.map(({ _id, businessName, catalogueName, images, categories, totalProducts, isPublic }, i) => (
//       <Catalogue
//         catalogueId={_id}
//         images={images}
//         name={catalogueName}
//         categories={categories}
//         totalProducts={totalProducts}
//         isPublic={isPublic}
//         catalogues={props.data}
//         setCatalogues={props.setCatalogues}
//         admin={true}
//         link={'/' + businessName + '/catalogues/' + window.btoa(JSON.stringify(_id))}
//         key={i}
//       />
//     ))}
//   </div>
// )
