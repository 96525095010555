import { Link } from 'react-router-dom'
import images from 'images'
import s from './styles.module.scss'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import { fetchUserProfile } from 'state/actions/userActions'
import { getBusinessName, isCustomDomain } from 'helpers'
import { ImageTag } from 'components'

const Main = ({ dispatch, loading, user }) => {
  const userProfile = getBusinessName()
  const isUserCustomDomain = isCustomDomain()
  const IMAGE_HOST = process.env.REACT_APP_IMAGE_HOST

  useEffect(() => {
    dispatch(fetchUserProfile(userProfile))
  }, [dispatch, userProfile])

  return (
    !!user.user && (
      <div className={s.main} id='footer'>
        <div className={s.footer}>
          <div
            className={s.row}
            style={{
              borderBottom: !isUserCustomDomain && '0.1rem solid var(--c-white)'
            }}
          >
            <div className={s.column}>
              <div className={s.image}>
                <Link to={isUserCustomDomain ? '/home' : '/' + user.user.businessName + '/home'}>
                  <ImageTag src={IMAGE_HOST + user.user.logo} alt='' />
                  <div className={s.businessName}>{user.user.businessName}</div>
                </Link>
              </div>
              <div className={s.content}>
                <Link to={isUserCustomDomain ? '/aboutUs' : '/' + user.user.businessName + '/aboutUs'}>About us</Link>
                <Link to={isUserCustomDomain ? '/support' : '/' + user.user.businessName + '/support'}>Support</Link>
              </div>
            </div>
            <div className={s.column}>
              <div className={s.title}>Company</div>
              <div className={s.content}>
                <Link
                  to={isUserCustomDomain ? '/terms&Conditions' : '/' + user.user.businessName + '/terms&Conditions'}
                >
                  Terms & Conditions
                </Link>
                <Link to={isUserCustomDomain ? '/privacyPolicy' : '/' + user.user.businessName + '/privacyPolicy'}>
                  Privacy policy
                </Link>
              </div>
            </div>
            <div className={s.column}>
              <div className={s.title}>Get in Touch</div>
              <div className={s.content}>
                <div>
                  <span className='material-icons-outlined'>location_on</span>
                  <span>{user.user.address}</span>
                </div>
                <div>
                  <span className='material-icons-outlined'>email</span>
                  <a href={'mailto:' + user.user.email}>{user.user.email}</a>
                </div>
                <div className={s.socialIcons}>
                  {user.user.facebookLink && (
                    <a href={user.user.facebookLink} target='_blank' rel='noopener noreferrer'>
                      <img src={images.facebook} alt='' />
                    </a>
                  )}
                  {user.user.instagramLink && (
                    <a href={user.user.instagramLink} target='_blank' rel='noopener noreferrer'>
                      <img src={images.instagram} alt='' />
                    </a>
                  )}
                  {user.user.linkedInLink && (
                    <a href={user.user.linkedInLink} target='_blank' rel='noopener noreferrer'>
                      <img src={images.linkedIn} alt='' />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
          {!isUserCustomDomain && <div className={s.row}>© 2022 All rights Reserved by StashBlog Pvt. Ltd.</div>}
        </div>
      </div>
    )
  )
}

const mapStateToProps = state => ({
  user: state.user,
  loading: state.loading
})

export default connect(mapStateToProps)(Main)
