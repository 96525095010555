import Catalogue from './tableCatalogues'
import CatalogueProducts from './tableCatalogueProducts'
import Products from './tableProducts'

const Layouts = {
  Catalogue,
  CatalogueProducts,
  Products
}

export default Layouts
