import images from 'images'
import { Link } from 'react-router-dom'
import s from './styles.module.scss'

export default function Main() {
  return (
    <div className={s.main}>
      <div className={s.introSection + ' indent'}>
        <div className={s.left}>
          <div className={s.header}>Your Appearance Shows Your Quality!</div>
          <div className={s.info}>
            Stashblog showcases the incredible products made by our talented customers. From handmade jewelry to unique
            paintings, our community of artisans creates truly one-of-a-kind pieces. Browse our selection and support
            our community of creators today!
          </div>
          <div className={s.button}>
            <Link to='/'>Get Started</Link>
          </div>
        </div>
        <div className={s.right}>
          <img src={images.illusIntro} alt='' />
        </div>
        <div className={s.illusDesign}>
          <img src={images.illusDesign1} alt='' />
        </div>
      </div>
    </div>
  )
}
