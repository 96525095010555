import s from './styles.module.scss'
import { useCallback, useEffect, useRef, useState } from 'react'
import { head, isCustomDomain } from 'helpers'
import { Link, useParams } from 'react-router-dom'
import * as api from 'api'
import { Alert, NoData } from 'components'

const durationFields = ['Hours', 'Days', 'Weeks']

export default function Main() {
  const [loading, setLoading] = useState(false)
  const [isPublic, setIsPublic] = useState(false)
  const [isLinkAlreadyGenerated, setIsLinkAlreadyGenerated] = useState(false)
  const [showLink, setShowLink] = useState(false)
  const [allowMultipleUsers, setAllowMultipleUsers] = useState(false)
  const [text, setText] = useState('')
  const [duration, setDuration] = useState('')
  const [durationField, setDurationField] = useState('Hours')
  const [expirationTime, setExpirationTime] = useState(null)
  const [remainingTime, setRemainingTime] = useState(null)
  const [visits, setVisits] = useState(0)
  const [isViewed, setIsViewed] = useState(false)
  const isUserCustomDomain = isCustomDomain()
  const processing = useRef()
  let { productId, catalogueId } = useParams()
  const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL

  if (productId) productId = JSON.parse(window.atob(productId))
  if (catalogueId) catalogueId = JSON.parse(window.atob(catalogueId))

  useEffect(() => {
    head({ title: 'Private Link | StashBlog Admin' })
  }, [])

  const convertExpirationDate = (creationTime, expDate) => {
    const [number, unit] = expDate.split('-')
    const num = parseInt(number.trim(), 10)

    // Calculate the milliseconds for the given unit
    let milliseconds = 0
    if (unit.includes('Hours')) {
      milliseconds = num * 60 * 60 * 1000
    } else if (unit.includes('Days')) {
      milliseconds = num * 24 * 60 * 60 * 1000
    } else if (unit.includes('Weeks')) {
      milliseconds = num * 7 * 24 * 60 * 60 * 1000
    }

    // Calculate the expiration time based on the creation time
    const expirationDate = new Date(new Date(creationTime).getTime() + milliseconds).toISOString()
    return expirationDate
  }

  const handleDurationChange = e => {
    if (e.target.value === '') setDuration('')

    const inputValue = parseInt(e.target.value, 10)

    if (durationField === 'Hours' && inputValue >= 1 && inputValue < 24) setDuration(inputValue)
    else if (durationField === 'Days' && inputValue >= 1 && inputValue <= 7) setDuration(inputValue)
    else if (durationField === 'Weeks' && inputValue >= 1 && inputValue <= 4) setDuration(inputValue)
  }

  const onSubmit = async () => {
    if (processing.current) return
    processing.current = true
    setLoading(true)

    let expTime = convertExpirationDate(new Date(Date.now()).toISOString(), duration + '-' + durationField)
    setExpirationTime(expTime)
    setRemainingTime(Math.max(new Date(expTime) - new Date(), 0))

    if (productId) {
      const generateLink = await api.products.generatePrivateLink({
        fkProductId: productId,
        privateLink: text,
        linkExpiresAt: expTime,
        allowMultipleUsers: allowMultipleUsers
      })

      if (generateLink.code === 'OK') {
        setShowLink(true)
        setIsLinkAlreadyGenerated(true)
      } else {
        Alert.warn(generateLink.error)
      }
    } else if (catalogueId) {
      const generateLink = await api.catalogues.generatePrivateLink({
        fkCatalogueId: catalogueId,
        privateLink: text,
        linkExpiresAt: expTime,
        allowMultipleUsers: allowMultipleUsers
      })

      if (generateLink.code === 'OK') {
        setShowLink(true)
        setIsLinkAlreadyGenerated(true)
      } else {
        Alert.warn(generateLink.error)
      }
    }

    processing.current = false
    setLoading(false)
  }

  const deleteHandler = async () => {
    if (processing.current) return
    processing.current = true
    setLoading(true)

    if (productId) {
      const deleteLink = await api.products.deleteGeneratedLink({
        fkProductId: productId
      })

      if (deleteLink.code === 'OK') {
        setShowLink(false)
        setIsLinkAlreadyGenerated(false)
        setDuration('')
        setDurationField('Hours')
        setExpirationTime(null)
        setRemainingTime(null)
        setAllowMultipleUsers(false)
        setIsViewed(false)
        setVisits(0)
        Alert.success('Private link has been deleted')
      } else {
        Alert.warn(deleteLink.error)
      }
    } else if (catalogueId) {
      const deleteLink = await api.catalogues.deleteGeneratedLink({
        fkCatalogueId: catalogueId
      })

      if (deleteLink.code === 'OK') {
        setShowLink(false)
        setIsLinkAlreadyGenerated(false)
        setDuration('')
        setDurationField('Hours')
        setExpirationTime(null)
        setAllowMultipleUsers(false)
        Alert.success('Private link has been deleted')
      } else {
        Alert.warn(deleteLink.error)
      }
    }

    processing.current = false
    setLoading(false)
  }

  const getProductEditData = useCallback(async () => {
    if (processing.current) return

    processing.current = true
    setLoading(true)

    const editProduct = await api.products.fetchUserProducts({
      id: productId
    })

    if (editProduct.code === 'OK') {
      setIsPublic(editProduct.products[0].isPublic)
      setText(
        PUBLIC_URL +
          (isUserCustomDomain ? '/privateLink' : '/' + editProduct.products[0].businessName + '/privateLink') +
          '?queryLink=' +
          window.btoa(JSON.stringify({ type: 'product', id: productId }))
      )

      const isGeneratedLink = await api.products.fetchPrivateLink({
        fkProductId: productId
      })

      if (isGeneratedLink.code === 'OK') {
        setExpirationTime(isGeneratedLink.generatedLink.linkExpiresAt)
        setRemainingTime(Math.max(new Date(isGeneratedLink.generatedLink.linkExpiresAt) - new Date(), 0))
        setIsLinkAlreadyGenerated(true)
        setShowLink(true)
        setVisits(isGeneratedLink.generatedLink.visits)
        setIsViewed(isGeneratedLink.generatedLink.linkViewed)
        setAllowMultipleUsers(isGeneratedLink.generatedLink.allowMultipleUsers)
      }
    }
    processing.current = false
    setLoading(false)
  }, [productId, isUserCustomDomain, PUBLIC_URL])

  const getCatalogueEditData = useCallback(async () => {
    if (processing.current) return

    processing.current = true
    setLoading(true)

    const editCatalogue = await api.catalogues.fetchUserCatalogues({
      id: catalogueId
    })

    if (editCatalogue.code === 'OK') {
      setIsPublic(editCatalogue.catalogues[0].isPublic)
      setText(
        PUBLIC_URL +
          (isUserCustomDomain ? '/privateLink' : '/' + editCatalogue.catalogues[0].businessName + '/privateLink') +
          '?queryLink=' +
          window.btoa(JSON.stringify({ type: 'catalogue', id: catalogueId }))
      )

      const isGeneratedLink = await api.catalogues.fetchPrivateLink({
        fkCatalogueId: catalogueId
      })

      if (isGeneratedLink.code === 'OK') {
        setExpirationTime(isGeneratedLink.generatedLink.linkExpiresAt)
        setRemainingTime(Math.max(new Date(isGeneratedLink.generatedLink.linkExpiresAt) - new Date(), 0))
        setIsLinkAlreadyGenerated(true)
        setShowLink(true)
        setVisits(isGeneratedLink.generatedLink.visits)
        setIsViewed(isGeneratedLink.generatedLink.linkViewed)
        setAllowMultipleUsers(isGeneratedLink.generatedLink.allowMultipleUsers)
      }
    }

    processing.current = false
    setLoading(false)
  }, [catalogueId, isUserCustomDomain, PUBLIC_URL])

  useEffect(() => {
    if (productId) getProductEditData()
    if (catalogueId) getCatalogueEditData()
  }, [productId, getProductEditData, catalogueId, getCatalogueEditData])

  useEffect(() => {
    if (remainingTime) {
      const timer = setInterval(() => {
        setRemainingTime(Math.max(new Date(expirationTime) - new Date(), 0))
      }, 1000)

      return () => {
        clearInterval(timer)
      }
    } else {
      setShowLink(false)
      setIsLinkAlreadyGenerated(false)
      setDuration('')
      setDurationField('Hours')
      setExpirationTime(null)
      setRemainingTime(null)
    }
  }, [remainingTime, expirationTime])

  const formatTime = ms => {
    const totalSeconds = Math.floor(ms / 1000)
    const weeks = Math.floor(totalSeconds / (7 * 24 * 60 * 60))
    const days = Math.floor((totalSeconds % (7 * 24 * 60 * 60)) / (24 * 60 * 60))
    const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60))
    const minutes = Math.floor((totalSeconds % (60 * 60)) / 60)
    const seconds = totalSeconds % 60

    const timeParts = []

    if (weeks > 0) timeParts.push(`${weeks}w`)
    if (days > 0) timeParts.push(`${days}d`)
    timeParts.push(`${hours}h`)
    timeParts.push(`${minutes}m`)
    timeParts.push(`${seconds}s`)

    return timeParts.join(' ')
  }

  const statusHandler = async () => {
    if (processing.current) return
    processing.current = true

    let allowMultipleUsersStatus = {}

    if (productId) {
      allowMultipleUsersStatus = await api.products.updateGeneratedLink({
        fkProductId: productId,
        allowMultipleUsers: !allowMultipleUsers
      })
    } else {
      allowMultipleUsersStatus = await api.catalogues.updateGeneratedLink({
        fkCatalogueId: catalogueId,
        allowMultipleUsers: !allowMultipleUsers
      })
    }

    if (allowMultipleUsersStatus.code === 'OK') {
      setAllowMultipleUsers(!allowMultipleUsers)
      !allowMultipleUsers
        ? Alert.warn('Link is visible to all user')
        : Alert.success('Link is visible to first user only')
    } else {
      Alert.error(allowMultipleUsersStatus.error)
    }

    processing.current = false
  }

  return (
    !loading && (
      <div className={s.main}>
        <div className={s.privateLink + ' indent'}>
          <div className={s.header} id='nav'>
            <div>Private Link</div>
            {!isLinkAlreadyGenerated && (
              <div className={s.actionIcons}>
                <div title='Generate Link' onClick={onSubmit}>
                  <span className='material-icons-outlined'>add_link</span>
                  Generate Link
                </div>
              </div>
            )}
          </div>
          <div className={s.content + ' innerScroll'}>
            {!!isPublic ? (
              <NoData color='var(--c-font)' />
            ) : (
              <div className={s.linkOptions}>
                {!!isLinkAlreadyGenerated && (
                  <>
                    <div className={s.heading}>Link Statistics</div>
                    <div className={s.statistics}>
                      <StatisticsCard icon='visibility' type='visits' visits={visits} />
                      <StatisticsCard
                        icon={isViewed ? 'visibility' : 'visibility_off'}
                        type='views'
                        viewed={isViewed}
                      />
                      <StatisticsCard
                        icon='hourglass_top'
                        type='remaining'
                        remainingTime={remainingTime ? formatTime(remainingTime) : 0}
                      />
                      <StatisticsCard icon='delete' type='delete' deleteHandler={deleteHandler} />
                    </div>
                  </>
                )}
                {!isLinkAlreadyGenerated && (
                  <>
                    <div className={s.heading}>Set Expiration Time</div>
                    <div className={s.timer}>
                      <input
                        type='number'
                        placeholder={'Enter ' + durationField}
                        value={duration}
                        onChange={handleDurationChange}
                      />
                      <select
                        onChange={e => setDurationField(e.target.value)}
                        defaultValue='Hours'
                        className={s.select}
                      >
                        {durationFields.map((value, i) => (
                          <option className={s.option} value={value} key={i}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
                {!!showLink && (
                  <>
                    <div className={s.heading}>Generated Link</div>
                    <div className={s.generatedLink}>
                      <span className='ellipsis'>{text}</span>
                      <span
                        className='material-icons-outlined'
                        onClick={() => {
                          navigator.clipboard.writeText(text)
                          Alert.success('Link copied to clipboard!')
                        }}
                      >
                        content_copy
                      </span>
                    </div>
                  </>
                )}
                {!!showLink && (
                  <Setting
                    settingName='Allow multiple users to view private link'
                    color={allowMultipleUsers ? 'var(--c-primary)' : 'var(--c-red)'}
                    onClick={statusHandler}
                    iconName={allowMultipleUsers ? 'toggle_on' : 'toggle_off'}
                    filledIcon
                  />
                )}
                {/* privateLinkToken, linkExpiresAt, linkViewed, deletedAt, visits, fingerPrint */}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  )
}

const StatisticsCard = props => (
  <div
    className={s.statisticsCard}
    style={{ background: props.type === 'delete' && 'var(--c-red)' }}
    onClick={props.type === 'delete' ? props.deleteHandler : () => {}}
  >
    <span className='material-icons-outlined' style={{ color: props.type === 'delete' && 'var(--c-white)' }}>
      {props.icon}
    </span>
    {props.type === 'visits' && <div className={s.info}>{props.visits} visits</div>}
    {props.type === 'views' && <div className={s.info}>{props.viewed ? 'Link viewed' : 'Link not opened'}</div>}
    {props.type === 'remaining' && (
      <>
        <div className={s.info}>{props.remainingTime ? 'Expires in' : 'Expired'} </div>
        {!!props.remainingTime && <div className={s.timeLeft}>{props.remainingTime}</div>}
      </>
    )}

    {props.type === 'delete' && (
      <div className={s.info} style={{ color: 'var(--c-white)' }}>
        Delete
      </div>
    )}
  </div>
)

const Setting = props => {
  const setting = (
    <div className={s.setting} style={{ borderColor: props.color }}>
      <div className={s.settingName}>{props.settingName}</div>
      <span
        className={props.filledIcon ? 'material-icons' : 'material-icons-outlined'}
        onClick={props.onClick ? props.onClick : () => {}}
        style={{ color: props.color }}
      >
        {props.iconName}
      </span>
    </div>
  )
  return props.link ? <Link to={props.link}>{setting}</Link> : setting
}
