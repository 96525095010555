export default function Main() {
  let origin = window.location.origin
  origin = origin.split('/')[2]
  if (!origin.startsWith('www.')) origin = 'www.' + origin

  if (origin === 'www.stashblog.com' || origin === 'www.dev.stashblog.com' || origin === 'www.localhost:5004')
    return false

  return true
}
