import { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import s from './styles.module.scss'
import { ImageTag } from 'components'

const IMAGE_HOST = process.env.REACT_APP_IMAGE_HOST

export default function Main(props) {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [loadedImages, setLoadedImages] = useState([])
  const imageRef = useRef(null)
  const imageRef1 = useRef(null)
  const imageRef2 = useRef(null)
  const imageRef3 = useRef(null)
  const imageRef4 = useRef(null)

  const handleImageChange = useCallback(() => {
    const image = imageRef.current
    const image1 = imageRef1.current
    const image2 = imageRef2.current
    const image3 = imageRef3.current
    const image4 = imageRef4.current

    if (image) {
      image.style.opacity = '0'
      image.style.transition = 'opacity 1s ease'
    }

    if (image1) {
      image1.style.opacity = '0'
      image1.style.transition = 'opacity 1s ease'
    }

    if (image2) {
      image2.style.opacity = '0'
      image2.style.transition = 'opacity 1s ease'
    }

    if (image3) {
      image3.style.opacity = '0'
      image3.style.transition = 'opacity 1s ease'
    }

    if (image4) {
      image4.style.opacity = '0'
      image4.style.transition = 'opacity 1s ease'
    }

    setTimeout(() => {
      setCurrentIndex((currentIndex + 1) % props.images.length)
      if (image) image.style.opacity = '1'
      if (image1) image1.style.opacity = '1'
      if (image2) image2.style.opacity = '1'
      if (image3) image3.style.opacity = '1'
      if (image4) image4.style.opacity = '1'
    }, 500)
  }, [currentIndex, props.images.length])

  useEffect(() => {
    setTimeout(handleImageChange, 3000)
  }, [handleImageChange])

  const loadImage = useCallback(
    index => {
      const imageUrl = IMAGE_HOST + props.images[index]
      if (!loadedImages.includes(imageUrl)) {
        const img = new Image()
        img.onload = () => {
          setLoadedImages(prevLoadedImages => [...prevLoadedImages, imageUrl])
        }
        img.src = imageUrl
      }
    },
    [loadedImages, props.images]
  )
  useEffect(() => {
    for (let i = 0; i < props.images.length; i++) {
      loadImage(i)
    }
  }, [loadImage, props.images])

  return (
    <div className={s.main}>
      <div className={s.productCard}>
        <div className={s.header}>
          <div className={s.logo}>
            <div className={s.logoImage}>
              <ImageTag
                src={
                  loadedImages.includes(IMAGE_HOST + props.images[currentIndex])
                    ? IMAGE_HOST + props.images[currentIndex]
                    : ''
                }
                ref={imageRef}
                alt=''
              />
            </div>
          </div>
          <div className={s.details}>
            <div className={s.productName + ' ellipsis'}>{props.name}</div>
            <div className={s.productDetails + ' ellipsis'} style={{ WebkitLineClamp: 4 }}>
              {props.desc}
            </div>
          </div>
        </div>
        {/* <div className={s.images} style={{ justifyContent: props.images.length > 3 ? 'space-between' : 'flex-start' }}>
          <>
            {
              props.images.length > 1 && (
                <div className={s.image} style={{ marginRight: props.images.length > 3 ? '0' : '0.5rem' }}>
                  <ImageTag
                    src={
                      loadedImages.includes(IMAGE_HOST + props.images[(currentIndex + 1) % props.images.length])
                        ? IMAGE_HOST + props.images[(currentIndex + 1) % props.images.length]
                        : ''
                    }
                    ref={imageRef1}
                    alt=''
                  />
                </div>
              )
              // : (
              //   Array(1)
              //     .fill(0)
              //     .map(i => (
              //       <div className={s.image} style={{ marginRight: props.images.length > 3 ? '0' : '0.5rem' }} key={i}>
              //         <ImageTag src='' alt='' />
              //       </div>
              //     ))
              // )
            }

            {props.images.length > 2 && (
              <div className={s.image} style={{ marginRight: props.images.length > 3 ? '0' : '0.5rem' }}>
                <ImageTag
                  src={
                    loadedImages.includes(IMAGE_HOST + props.images[(currentIndex + 2) % props.images.length])
                      ? IMAGE_HOST + props.images[(currentIndex + 2) % props.images.length]
                      : ''
                  }
                  ref={imageRef2}
                  alt=''
                />
              </div>
            )}

            {props.images.length > 3 && (
              <div className={s.image} style={{ marginRight: props.images.length > 3 ? '0' : '0.5rem' }}>
                <ImageTag
                  src={
                    loadedImages.includes(IMAGE_HOST + props.images[(currentIndex + 3) % props.images.length])
                      ? IMAGE_HOST + props.images[(currentIndex + 3) % props.images.length]
                      : ''
                  }
                  ref={imageRef3}
                  alt=''
                />
              </div>
            )}

            {props.images.length > 4 && (
              <div className={s.image}>
                <ImageTag
                  src={
                    loadedImages.includes(IMAGE_HOST + props.images[(currentIndex + 4) % props.images.length])
                      ? IMAGE_HOST + props.images[(currentIndex + 4) % props.images.length]
                      : ''
                  }
                  ref={imageRef4}
                  alt=''
                />
              </div>
            )}
          </>
        </div> */}

        <div
          className={s.productLink}
          style={{
            marginBottom: props.images.length <= 1 && '0',
            marginTop: props.images.length <= 1 && 'auto'
          }}
        >
          <Link to={props.productLink} target='_blank'>
            View Product
          </Link>
        </div>
      </div>
    </div>
  )
}
