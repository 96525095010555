import handler from 'api/handler'

const prefix = '/catalogues'
// const updatedAt = Date.now()

const methods = {
  addCatalogue: args => handler({ method: 'post', url: prefix + '/addCatalogue', args }),
  deleteCatalogue: args => {
    // args.updatedAt = updatedAt
    args.deletedAt = new Date(Date.now()).toISOString()
    args.isActive = false
    return handler({ method: 'delete', url: prefix + '/deleteCatalogue', args })
  },
  deleteGeneratedLink: args => {
    args.deletedAt = new Date(Date.now()).toISOString()
    args.isActive = false
    return handler({ method: 'delete', url: prefix + '/deleteGeneratedLink', args })
  },
  generatePrivateLink: args => handler({ method: 'post', url: prefix + '/generatePrivateLink', args }),
  privateLinkVisits: args => handler({ method: 'put', url: prefix + '/privateLinkVisits', args }),
  promoteUserCatalogue: args => handler({ method: 'put', url: prefix + '/promoteUserCatalogue', args }),
  updateGeneratedLink: args => handler({ method: 'put', url: prefix + '/updatePrivateLink', args }),
  fetchPrivateLink: args => handler({ method: 'get', url: prefix + '/fetchPrivateLink', args }),
  fetchUserCatalogues: args => handler({ method: 'get', url: prefix + '/fetchAllCatalogues', args }),
  fetchAllUsersCatalogues: args => handler({ method: 'get', url: prefix + '/fetchAllUsersCatalogues', args }),
  updateCatalogue: args => handler({ method: 'put', url: prefix + '/updateCatalogue', args }),
  updateCatalogueStatus: args => handler({ method: 'put', url: prefix + '/updateCatalogueStatus', args }),
  updateCatalogueVisits: args => handler({ method: 'put', url: prefix + '/catalogueVisits', args })
}

export default methods
