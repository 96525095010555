import s from './styles.module.scss'
import images from 'images'
import { head } from 'helpers'
import { useEffect } from 'react'

export default function Main() {
  useEffect(() => {
    head({})
  }, [])

  return (
    <div className={s.main}>
      <div className={s.dashboard + ' indent'}>
        <div className={s.header}>
          <div>
            <span className='material-icons-outlined'>dashboard</span>
            Dashboard
          </div>
        </div>
        <div className={s.content + ' innerScroll'}>
          <div className={s.imageIllus}>
            <img src={images.dashboard} alt='' />
          </div>
          <div className={s.desc}>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book.
          </div>
        </div>
      </div>
    </div>
  )
}
