import { Link } from 'react-router-dom'
import images from 'images'
import s from './styles.module.scss'

export default function Main() {
  return (
    <div className={s.main} id='footer'>
      <div className={s.footer}>
        <div className={s.row}>
          <div className={s.column}>
            <div className={s.image}>
              <Link to='/home'>
                <img src={images.logoWhite} alt='' />
              </Link>
            </div>
            <div className={s.content}>
              <Link to='/aboutUs'>About us</Link>
              <Link to='/pricing'>Pricing</Link>
              <Link to='/support'>Support</Link>
            </div>
          </div>
          <div className={s.column}>
            <div className={s.title}>Company</div>
            <div className={s.content}>
              <Link to='/terms&Conditions'>Terms & Conditions</Link>
              <Link to='/privacyPolicy'>Privacy policy</Link>
              <Link to='/faq'>Faq's</Link>
            </div>
          </div>
          <div className={s.column}>
            <div className={s.title}>Get in Touch</div>
            <div className={s.content}>
              <div>
                <span className='material-icons-outlined'>location_on</span>
                <a
                  href='https://www.google.com/maps/dir/28.849996,78.750987/28.8500312,78.7509763/@28.849927,78.7503643,19z/data=!3m1!4b1?entry=ttu'
                  target='_blank'
                  rel='noreferrer'
                >
                  Hs no 17, Azad Nagar, Moradabad, UP, India, 244001
                </a>
              </div>
              <div>
                <span className='material-icons-outlined'>email</span>
                <a href='mailto:stashblogtech@gmail.com'>stashblogtech@gmail.com</a>
              </div>
              <div className={s.socialIcons}>
                <Link to='/'>
                  <img src={images.facebook} alt='' />
                </Link>
                <Link to='/'>
                  <img src={images.instagram} alt='' />
                </Link>
                <Link to='/'>
                  <img src={images.linkedIn} alt='' />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className={s.row}>
          <div>© 2023 All rights Reserved by StashBlog Pvt. Ltd.</div>
          <div>
            Powered by <a href='/'>DaemonHive Technologies</a>
          </div>
        </div>
      </div>
    </div>
  )
}
