import s from './styles.module.scss'
import images from 'images'

export default function Main() {
  return (
    <div className={s.main}>
      <div className={s.clientSection}>
        <div className={s.content + ' indent'}>
          <div className={s.heading}>Why our clients trust StashBlog</div>
          <div className={s.info}>
            <div className={s.image}>
              <img src={images.client} alt='' />
            </div>
            <div className={s.cards}>
              {cardData.map(({ title, description, image }, i) => (
                <CardAlt title={title} description={description} image={image} key={i} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const CardAlt = ({ title, description, image }) => (
  <div className={s.cardAlt}>
    <div className={s.header}>
      <div className={s.imageCard}>
        <img src={image} alt='' />
      </div>
      <div className={s.title}>{title}</div>
    </div>
    <div className={s.desc}>{description}</div>
  </div>
)

const cardData = [
  {
    image: images.simple,
    title: 'Simple',
    description: "Once setup is complete, it's a child's play to manage your website according to your need."
  },
  {
    image: images.showcase,
    title: 'Showcase',
    description: 'Display your products in all their glory, create an immersive experience of your website.'
  },
  {
    image: images.manage,
    title: 'Manage',
    description: 'Our user-friendly editor allows you to manage your website without any technical expertise'
  },
  {
    image: images.support,
    title: 'Support',
    description: 'Work seamlessly with our team. Our platform supports 24x7 technical expertise.'
  }
]
