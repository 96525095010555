import { Link } from 'react-router-dom'
import s from './styles.module.scss'
import * as api from 'api'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Alert, ImageTag } from 'components'
import { isCustomDomain } from 'helpers'

const IMAGE_HOST = process.env.REACT_APP_IMAGE_HOST

export default function Main({ fullscreen, setFullscreen }) {
  const [loading, setLoading] = useState(false)
  const [userInfo, setUserInfo] = useState()
  const isUserCustomDomain = isCustomDomain()

  const processing = useRef()

  const pageDataHandler = useCallback(async () => {
    if (processing.current) {
      return
    }
    processing.current = true
    setLoading(true)

    const fetchUserInfo = await api.auth.getUser({})

    if (fetchUserInfo.code === 'OK') {
      setUserInfo(fetchUserInfo.user)
    } else {
      Alert.error(fetchUserInfo.error)
    }

    processing.current = false
    setLoading(false)
  }, [])

  useEffect(() => {
    pageDataHandler()
  }, [pageDataHandler])

  return (
    !loading &&
    userInfo && (
      <>
        <div id='nav' className={s.outernav}>
          <nav className={s.nav}>
            <div className={s.left}>
              <Link to={isUserCustomDomain ? '/home' : '/' + userInfo.businessName + '/home'}>
                <ImageTag src={IMAGE_HOST + userInfo.logo} alt='' />
                <div>{userInfo.businessName}</div>
              </Link>
            </div>

            <div className={s.right}>
              <NavLinks />
            </div>
          </nav>
        </div>

        {!!fullscreen && (
          <div className={s.fullscreenMenu} onClick={() => setFullscreen(false)}>
            <div className={s.close + ' material-icons'}>close</div>

            <NavLinks />
          </div>
        )}

        <div className={s.hamburger + ' material-icons'} onClick={() => setFullscreen(true)}>
          menu
        </div>
      </>
    )
  )
}

const NavLinks = () => (
  <div className={s.navLinks}>
    {navLinks.map(({ name, path, icon }, i) => (
      <Link key={i} to={path} className={window.location.pathname.startsWith(path) ? s.active : ''}>
        <span className='material-icons-outlined'>{icon}</span>
        <div>{name}</div>
      </Link>
    ))}
  </div>
)

const navLinks = [
  {
    name: 'Dashboard',
    path: '/admin/dashboard',
    icon: 'dashboard'
  },
  {
    name: 'Products',
    path: '/admin/products',
    icon: 'view_carousel'
  },
  {
    name: 'Catalogues',
    path: '/admin/catalogues',
    icon: 'view_carousel'
  },
  {
    name: 'User Profile',
    path: '/admin/userProfileInfo',
    icon: 'person'
  },
  {
    name: 'Settings',
    path: '/admin/settings',
    icon: 'settings'
  },
  {
    name: 'Sign Out',
    path: '/admin/signOut',
    icon: 'logout'
  }
]
