import * as api from 'api'
import { Alert } from 'components'

export const fetchUserProfile = userProfile => async dispatch => {
  try {
    dispatch({ type: 'FETCH_USER_PROFILE_PENDING' })
    const fetchUser = await api.user.fetchUserProfiles({ businessName: userProfile })
    if (fetchUser.code === 'OK') {
      dispatch({ type: 'FETCH_USER_PROFILE', payload: fetchUser.userProfiles[0] })
    } else {
      Alert.error(fetchUser.error)
    }
  } catch (error) {
    Alert.error('An error occurred while fetching user profile.')
  }
}
