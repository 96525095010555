import s from './styles.module.scss'
import { Alert, ImageTag, Loader, Swiper } from 'components'
import * as api from 'api'
import { useCallback, useEffect, useRef, useState } from 'react'
import { getBusinessName } from 'helpers'

const IMAGE_HOST = process.env.REACT_APP_IMAGE_HOST

export default function Main() {
  const [loading, setLoading] = useState(false)
  const [userHome, setUserHome] = useState()
  const processing = useRef(false)
  const userProfile = getBusinessName()

  const getUserHome = useCallback(async () => {
    if (processing.current) {
      return
    }

    processing.current = true
    setLoading(true)

    const fetchUserHome = await api.user.fetchUserHome({ businessName: userProfile })

    if (fetchUserHome.code === 'OK') {
      setUserHome(fetchUserHome.userHome)
    } else {
      Alert.error(fetchUserHome.error)
    }
    processing.current = false
    setLoading(false)
  }, [userProfile])

  useEffect(() => {
    getUserHome()
  }, [getUserHome])

  return (
    <div className={s.main}>
      {!!loading && <Loader />}
      {/* {!loading && !userHome && <NoData color='var(--c-font)' />} */}
      {!loading && !!userHome && (
        <div className={s.carousel}>
          <Swiper
            infinite
            timer={3000}
            carouselType='casualBig'
            paginationOn={userHome.banners.length > 1}
            navArrowsOn={false}
            arrowsInside={true}
            borderTopRadius={false}
          >
            {userHome.banners.map((image, i) => (
              <ImageTag src={IMAGE_HOST + image} key={i} alt='' />
            ))}
          </Swiper>
        </div>
      )}
    </div>
  )
}
