import s from './styles.module.scss'
import { getBusinessName, head, isCustomDomain } from 'helpers'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Alert, Filter, ImageTag, InfiniteScroll, Loader, NoData } from 'components'
import * as api from 'api'
import { Link } from 'react-router-dom'

const perPage = 12
const filterFields = [{ field: 'Products' }, { field: 'Catalogues' }, { field: 'Category' }]
const sortFields = [
  { field: 'Date, new to old' },
  { field: 'Date, old to new' },
  { field: 'Alphabetically A-Z' },
  { field: 'Alphabetically Z-A' }
]

export default function Main() {
  const [loading, setLoading] = useState(false)
  const [heading, setHeading] = useState('Products')
  const [sort, setSort] = useState('Date, new to old')
  const [currentPage, setCurrentPage] = useState(1)
  const [searchedData, setSearchedData] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const processing = useRef(false)
  const searchQuery = new URLSearchParams(window.location.search).get('searchQuery')
  const searchQueryCategory = new URLSearchParams(window.location.search).get('category')
  const isUserCustomDomain = isCustomDomain()
  let userProfile = getBusinessName()

  useEffect(() => {
    head({ title: (userProfile ? userProfile : 'StashBlog') + ':Search' })
  }, [userProfile])

  const filterHandler = async field => {
    setHeading(field)
    setCurrentPage(1)
    setSearchedData([])
  }

  const sortHandler = async field => {
    setSort(field)
    setCurrentPage(1)
    setSearchedData([])
  }

  const searchHandler = useCallback(
    async (sort, currPage) => {
      if (processing.current) return

      processing.current = true
      setLoading(true)
      setCurrentPage(currPage + 1)

      if (heading === 'Products' && searchQuery) {
        const userProducts = await api.products.fetchAllUsersProducts({
          pageNo: currPage,
          businessName: userProfile,
          productName: searchQuery,
          sort: sort
        })

        if (userProducts.code === 'OK') {
          setSearchedData(prevData => [...prevData, ...userProducts.products])
          setTotalPages(Math.ceil(userProducts.count / perPage))
        } else {
          Alert.error(userProducts.error)
        }
      } else if (heading === 'Catalogues' && searchQuery) {
        const userCatalogues = await api.catalogues.fetchAllUsersCatalogues({
          pageNo: currPage,
          businessName: userProfile,
          catalogueName: searchQuery,
          sort: sort
        })

        if (userCatalogues.code === 'OK') {
          setTotalPages(Math.ceil(userCatalogues.count / perPage))
          setSearchedData(prevData => [...prevData, ...userCatalogues.catalogues])
        } else {
          Alert.error(userCatalogues.error)
        }
      } else if (searchQuery || searchQueryCategory) {
        const userProducts = await api.products.fetchAllUsersProducts({
          pageNo: currPage,
          businessName: userProfile,
          category: searchQuery || searchQueryCategory,
          sort: sort
        })

        if (userProducts.code === 'OK') {
          setSearchedData(prevData => [...prevData, ...userProducts.products])
          setTotalPages(Math.ceil(userProducts.count / perPage))
        }

        const userCatalogues = await api.catalogues.fetchAllUsersCatalogues({
          pageNo: currPage,
          businessName: userProfile,
          categories: searchQuery,
          sort: sort
        })

        if (userCatalogues.code === 'OK') {
          setTotalPages(Math.max(Math.ceil(userCatalogues.count / perPage), totalPages))
          setSearchedData(prevData => [...prevData, ...userCatalogues.catalogues])
        }
      }

      processing.current = false
      setLoading(false)
    },
    [heading, searchQuery, searchQueryCategory, userProfile, totalPages]
  )

  useEffect(() => {
    if (searchQueryCategory) setHeading('Category')
    else setHeading('Products')
    setSearchedData([])
    setCurrentPage(1)
  }, [searchQuery, searchQueryCategory])

  return (
    <div className={s.main}>
      <div className={s.searchResults}>
        <div className={s.content + ' indent'}>
          <div className={s.filters}>
            <Filter heading={heading} filterFields={filterFields} filterHandler={filterHandler} style2 />
            <Filter title='sort' heading={sort} filterFields={sortFields} filterHandler={sortHandler} style2 />
          </div>

          <div className={s.heading}>Results for "{searchQuery || searchQueryCategory}"</div>

          <InfiniteScroll
            next={searchHandler}
            filter={sort}
            currentPage={currentPage}
            hasMore={currentPage <= totalPages}
            loader={<Loader color='var(--c-primary)' colorText='var(--c-font)' />}
          >
            <div className={s.searchedDataResults}>
              {searchedData.map((item, i) => (
                <SearchCards
                  itemId={item._id}
                  name={item.productName ? item.productName : item.catalogueName}
                  isCatalogue={item.productName ? false : true}
                  link={
                    (item.productName
                      ? isUserCustomDomain
                        ? '/products/'
                        : '/' + item.businessName + '/products/'
                      : isUserCustomDomain
                      ? '/catalogues/'
                      : '/' + item.businessName + '/catalogues/') + window.btoa(JSON.stringify(item._id))
                  }
                  images={item.images}
                  key={i}
                />
              ))}
            </div>
          </InfiniteScroll>

          {!loading && !searchedData.length && <NoData color='var(--c-font)' />}
        </div>
      </div>
    </div>
  )
}

const SearchCards = props => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const IMAGE_HOST = process.env.REACT_APP_IMAGE_HOST
  const imageRef = useRef(null)

  const handleImageChange = useCallback(() => {
    const image = imageRef.current

    if (!image) return

    image.style.opacity = '0'
    image.style.transition = 'opacity 1s ease'

    setTimeout(() => {
      setCurrentIndex((currentIndex + 1) % props.images.length)
      image.style.opacity = '1'
    }, 500)
  }, [currentIndex, props.images.length])

  useEffect(() => {
    setTimeout(handleImageChange, 3000)
  }, [handleImageChange])

  return (
    <div className={s.searchedItemCardMain}>
      <div className={s.searchedItemCard}>
        {props.isCatalogue && <div className={s.catalogueBanner}>catalogue</div>}
        <div className={s.image}>
          <ImageTag src={IMAGE_HOST + props.images[currentIndex]} ref={imageRef} alt='' />
        </div>
        <div className={s.searchedItemName + ' ellipsis'}>{props.name}</div>
        <Link className={s.viewButton} to={props.link} target='_blank'>
          view
        </Link>
      </div>
      <div className={s.searchedItemNameAlt + ' ellipsis'}>{props.name}</div>
      <Link className={s.viewButtonAlt} to={props.link} target='_blank'>
        view
      </Link>
    </div>
  )
}
