export default function Main({
  title = 'StashBlog | A showcase experience of your products',
  description = 'A showcase experience of your products'
}) {
  document.querySelector('title').innerText = title
  document.querySelector('meta[name="title"]').content = title
  document.querySelector('meta[name="description"]').content = description

  window.scrollTo(0, 0)
}
