import { FAQ } from 'components'
import { head } from 'helpers'
import { useEffect } from 'react'
import SupportForm from './supportForm'

export default function Main() {
  useEffect(() => {
    head({ title: 'Support | StashBlog' })
  }, [])

  return (
    <>
      <SupportForm />
      <FAQ />
      {/* <GetStartedBanner /> */}
    </>
  )
}
