import { useCallback, useEffect, useRef, useState } from 'react'
import s from './styles.module.scss'
import * as api from 'api'
import { Alert, CataloguePage, NoData, ProductPage } from 'components'
import { fingerPrint } from 'helpers'

export default function Main() {
  const [loading, setLoading] = useState(false)
  const [userProduct, setUserProduct] = useState(null)
  const [userCatalogue, setUserCatalogue] = useState(null)
  const searchQuery = new URLSearchParams(window.location.search).get('queryLink')
  let { type, id } = JSON.parse(window.atob(searchQuery))
  const processing = useRef()
  const userFingerPrint = window.localStorage.getItem('fingerPrint')

  const getProductData = useCallback(
    async filter => {
      const getGeneratedLinkData = await api.products.fetchPrivateLink(filter)

      if (getGeneratedLinkData.code === 'OK') {
        setUserProduct(getGeneratedLinkData.userProduct)

        const updatePrivateProductVisits = await api.products.privateLinkVisits({
          fkProductId: id
        })

        if (updatePrivateProductVisits.code === 'OK') {
        }
        //  else {
        //   Alert.error(updatePrivateProductVisits.error)
        // }
      } else {
        Alert.error(getGeneratedLinkData.error)
      }
    },
    [id]
  )

  const getProduct = useCallback(async () => {
    if (processing.current) return

    processing.current = true
    setLoading(true)

    const newFingerPrint = await fingerPrint()

    let filter = {}

    let updateFilter = {
      fkProductId: id,
      linkViewed: true
    }

    if (!userFingerPrint) {
      updateFilter.fingerPrint = newFingerPrint

      const updatePrivateProduct = await api.products.updateGeneratedLink(updateFilter)

      if (updatePrivateProduct.code === 'OK') {
        window.localStorage.setItem('fingerPrint', newFingerPrint)
        filter.fkProductId = id
        await getProductData(filter)
      }
    } else {
      if (newFingerPrint === userFingerPrint) {
        updateFilter.fingerPrint = userFingerPrint

        const updatePrivateProduct = await api.products.updateGeneratedLink(updateFilter)

        if (updatePrivateProduct.code === 'OK') {
          filter.fkProductId = id
          filter.fingerPrint = userFingerPrint
          await getProductData(filter)
        }
      }
    }

    processing.current = false
    setLoading(false)
  }, [id, userFingerPrint, getProductData])

  const getCatalogueData = useCallback(
    async filter => {
      const getGeneratedLinkData = await api.catalogues.fetchPrivateLink(filter)

      if (getGeneratedLinkData.code === 'OK') {
        setUserCatalogue(getGeneratedLinkData.userCatalogue)

        const updatePrivateProductVisits = await api.catalogues.privateLinkVisits({
          fkCatalogueId: id
        })

        if (updatePrivateProductVisits.code === 'OK') {
        }
        //  else {
        //   Alert.error(updatePrivateProductVisits.error)
        // }
      } else {
        Alert.error(getGeneratedLinkData.error)
      }
    },
    [id]
  )

  const getCatalogue = useCallback(async () => {
    if (processing.current) return

    processing.current = true
    setLoading(true)

    const newFingerPrint = await fingerPrint()

    let filter = {}
    let updateFilter = {
      fkCatalogueId: id,
      linkViewed: true
    }

    if (!userFingerPrint) {
      updateFilter.fingerPrint = newFingerPrint

      const updatePrivateProduct = await api.catalogues.updateGeneratedLink(updateFilter)

      if (updatePrivateProduct.code === 'OK') {
        window.localStorage.setItem('fingerPrint', newFingerPrint)
        filter.fkCatalogueId = id
        await getCatalogueData(filter)
      }
    } else {
      if (newFingerPrint === userFingerPrint) {
        updateFilter.fingerPrint = userFingerPrint
        const updatePrivateProduct = await api.catalogues.updateGeneratedLink(updateFilter)
        if (updatePrivateProduct.code === 'OK') {
          filter.fkCatalogueId = id
          filter.fingerPrint = userFingerPrint
          await getCatalogueData(filter)
        }
      }
    }

    processing.current = false
    setLoading(false)
  }, [id, userFingerPrint, getCatalogueData])

  useEffect(() => {
    if (type === 'product') getProduct()
    if (type === 'catalogue') getCatalogue()
  }, [type, getProduct, getCatalogue])

  return (
    !loading && (
      <div className={s.main}>
        {type === 'product' && !userProduct && <NoData color='var(--c-font)' />}
        {type === 'product' && !!userProduct && <ProductPage product={userProduct} />}
        {type === 'catalogue' && !userCatalogue && <NoData color='var(--c-font)' />}
        {type === 'catalogue' && !!userCatalogue && <CataloguePage catalogue={userCatalogue} />}
      </div>
    )
  )
}
