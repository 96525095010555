import s from './styles.module.scss'

export default function Main(props) {
  return (
    <div className={s.main}>
      <div className={s.textField}>
        <span className='material-icons-outlined'>{props.iconName}</span>
        <textarea
          className={s.textarea}
          rows='5'
          cols='50'
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
        />
        {!!props.label && <label className={s.label}>{props.label}</label>}
        {!!props.isRequired && <span className={s.isRequired}>*</span>}
      </div>
    </div>
  )
}
