import s from './styles.module.scss'
import { head, getBusinessName, isCustomDomain } from 'helpers'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Alert, Filter, ImageTag, InfiniteScroll, Loader, Modal, NoData, Swiper } from 'components'
import * as api from 'api'
import images from 'images'

const perPage = 10
const perPhonePage = 6
const IMAGE_HOST = process.env.REACT_APP_IMAGE_HOST
const mediaMatch = window.matchMedia('(max-width: 1279px)')

const sortFields = [
  { field: 'Date, new to old' },
  { field: 'Date, old to new' },
  { field: 'Alphabetically A-Z' },
  { field: 'Alphabetically Z-A' }
]

export default function Main(props) {
  let { catalogueId } = useParams()
  if (!catalogueId) catalogueId = window.btoa(JSON.stringify(props.catalogue._id))
  const userProfile = getBusinessName()
  const isUserCustomDomain = isCustomDomain()
  const [sort, setSort] = useState('Date, new to old')
  const [canSelectProducts, setCanSelectProducts] = useState(false)
  const [queryProducts, setQueryProducts] = useState([])
  const [catalogue, setCatalogue] = useState([])
  const [products, setProducts] = useState([])
  const [productsPage, setProductsPage] = useState([])
  const [loading1, setLoading1] = useState(false)
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const processing = useRef(false)
  const processing1 = useRef(false)
  const processing2 = useRef(false)
  const [matches, setMatches] = useState(mediaMatch.matches)

  useEffect(() => {
    const handler = e => setMatches(e.matches)
    mediaMatch.addEventListener('change', handler)
    return () => mediaMatch.removeEventListener('change', handler)
  })

  useEffect(() => {
    head({
      title: (catalogue.catalogueName || 'Catalogue') + ' | ' + userProfile
    })
  }, [userProfile, catalogue.catalogueName])

  const modalHandler = product => {
    Modal.product(
      'productmodal',
      product.name,
      product.tags,
      product.category,
      product.description,
      product.link,
      product.images,
      product.createdAt,
      product.pricePerUnit,
      product.totalUnits
    )
  }

  const getUserCatalogue = useCallback(async () => {
    if (processing.current) return

    processing.current = true
    setLoading1(true)

    const userCatalogues = await api.catalogues.fetchAllUsersCatalogues({
      businessName: userProfile,
      id: JSON.parse(window.atob(catalogueId))
    })

    if (userCatalogues.code === 'OK') {
      if (userCatalogues.catalogues.length !== 0) {
        setCatalogue(userCatalogues.catalogues[0])

        if (!matches) {
          const chunks = Array.from(
            { length: Math.ceil(userCatalogues.catalogues[0].products.length / perPage) },
            (element, index) => userCatalogues.catalogues[0].products.slice(index * perPage, index * perPage + perPage)
          )
          setProductsPage(chunks)
          setTotalPages(chunks.length)
        } else {
          const chunks = Array.from(
            { length: Math.ceil(userCatalogues.catalogues[0].products.length / perPhonePage) },
            (element, index) =>
              userCatalogues.catalogues[0].products.slice(index * perPhonePage, index * perPhonePage + perPhonePage)
          )
          setProductsPage(chunks)
          setTotalPages(chunks.length)
        }
      }
    } else {
      Alert.error(userCatalogues.error)
    }

    processing.current = false
    setLoading1(false)
  }, [catalogueId, userProfile, matches])

  const getUserCatalogueProducts = useCallback(
    async (sort, currPage) => {
      if (processing1.current) return

      processing1.current = true
      setLoading(true)
      setCurrentPage(currPage + 1)

      const fetchCatalogueProducts = await api.products.fetchAllUsersProducts({
        businessName: userProfile,
        id: productsPage[currPage - 1],
        sort: sort
      })

      if (fetchCatalogueProducts.code === 'OK') {
        setProducts([...products, ...fetchCatalogueProducts.products])
      } else {
        Alert.error(fetchCatalogueProducts.error)
      }
      processing1.current = false
      setLoading(false)
    },
    [products, productsPage, userProfile]
  )

  const visitsCounter = useCallback(async () => {
    if (processing2.current) return

    processing2.current = true

    const updateCatalogueVisits = await api.catalogues.updateCatalogueVisits({
      businessName: userProfile,
      id: JSON.parse(window.atob(catalogueId))
    })

    if (updateCatalogueVisits.code === 'OK') {
      // let catalogueVisits = updateCatalogueVisitss.catalogueVisits
    } else {
      Alert.error(updateCatalogueVisits.error)
    }
    processing2.current = false
  }, [catalogueId, userProfile])

  useEffect(() => {
    if (!props.catalogue) getUserCatalogue()
    else {
      setCatalogue(props.catalogue)

      if (!matches) {
        const chunks = Array.from({ length: Math.ceil(props.catalogue.products.length / perPage) }, (element, index) =>
          props.catalogue.products.slice(index * perPage, index * perPage + perPage)
        )
        setProductsPage(chunks)
        setTotalPages(chunks.length)
      } else {
        const chunks = Array.from(
          { length: Math.ceil(props.catalogue.products.length / perPhonePage) },
          (element, index) => props.catalogue.products.slice(index * perPhonePage, index * perPhonePage + perPhonePage)
        )
        setProductsPage(chunks)
        setTotalPages(chunks.length)
      }
    }
  }, [getUserCatalogue, props.catalogue, setCatalogue, matches])

  useEffect(() => {
    if (catalogue.length !== 0) visitsCounter()
  }, [visitsCounter, catalogue])

  const selectQueryProducts = product => {
    let updatedQueryProducts = [...queryProducts]
    updatedQueryProducts.push(product)
    setQueryProducts(updatedQueryProducts)
  }

  const removeQueryProducts = product => {
    let updatedQueryProducts = [...queryProducts]
    updatedQueryProducts.pop(product)
    setQueryProducts(updatedQueryProducts)
  }

  const resetQueryProducts = () => {
    if (canSelectProducts) setQueryProducts([])
    setCanSelectProducts(!canSelectProducts)
  }

  const sortHandler = field => {
    setProducts([])
    setCurrentPage(1)
    setSort(field)
  }

  return (
    <div className={s.main}>
      {!!loading1 && <Loader color='var(--c-primary)' colorText='var(--c-font)' />}

      {!loading1 && catalogue.length === 0 && <NoData color='var(--c-font)' />}

      {!loading1 && catalogue.length !== 0 && (
        <>
          <div className={s.carousel}>
            <Swiper
              infinite
              timer={3000}
              carouselType='casualBig'
              paginationOn={catalogue.images.length > 1}
              navArrowsOn={false}
              arrowsInside={true}
              borderTopRadius={false}
            >
              {catalogue.images.map((image, i) => (
                <ImageTag src={IMAGE_HOST + image} key={i} alt='' />
              ))}
            </Swiper>
          </div>
          <div className={s.catalogueBody + ' indent'}>
            <div className={s.heading}>"{catalogue.catalogueName}"</div>
            {!loading && products.length !== 0 && (
              <>
                <div className={s.filterSec}>
                  <Filter title='sort' heading={sort} filterFields={sortFields} filterHandler={sortHandler} />
                  <div className={s.rightFilters}>
                    <div className={s.selectProducts}>
                      <div
                        onClick={resetQueryProducts}
                        style={{
                          backgroundColor: canSelectProducts && 'var(--c-black)',
                          color: canSelectProducts && 'var(--c-white)'
                        }}
                      >
                        {!canSelectProducts ? 'Select' : queryProducts.length > 0 ? 'Deselect all' : 'Select'}
                      </div>
                    </div>

                    {!!canSelectProducts && queryProducts.length > 0 && (
                      <div className={s.sendQuery}>
                        <div
                          onClick={async () =>
                            Modal.queryModal('queryModal', catalogue.businessName, queryProducts, resetQueryProducts)
                          }
                        >
                          Send Query
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            {productsPage.length !== 0 && (
              <InfiniteScroll
                next={getUserCatalogueProducts}
                filter={sort}
                currentPage={currentPage}
                hasMore={currentPage <= totalPages}
                loader={<Loader color='var(--c-primary)' colorText='var(--c-font)' />}
              >
                {products.length !== 0 && (
                  <div className={s.catalogueProducts}>
                    {products.map((product, i) => (
                      <ProductAltCards
                        productId={product._id}
                        name={product.productName}
                        description={product.description}
                        link={
                          (isUserCustomDomain ? '/products/' : '/' + product.businessName + '/products/') +
                          window.btoa(JSON.stringify(product._id))
                        }
                        images={product.images}
                        tags={product.tags}
                        category={product.category}
                        createdAt={product.createdAt}
                        pricePerUnit={product.pricePerUnit}
                        totalUnits={product.totalUnits}
                        modalHandler={modalHandler}
                        canSelectProducts={canSelectProducts}
                        selectQueryProducts={selectQueryProducts}
                        removeQueryProducts={removeQueryProducts}
                        key={i}
                      />
                    ))}
                  </div>
                )}
              </InfiniteScroll>
            )}
          </div>
        </>
      )}
    </div>
  )
}

const ProductAltCards = props => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isChecked, setIsChecked] = useState(false)
  const IMAGE_HOST = process.env.REACT_APP_IMAGE_HOST
  const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL
  const imageRef = useRef(null)

  const checkHandler = () => {
    let checkStatus = !isChecked
    setIsChecked(checkStatus)

    if (checkStatus)
      props.selectQueryProducts({
        productId: props.productId,
        link: PUBLIC_URL + props.link,
        name: props.name,
        image: props.images.length ? IMAGE_HOST + props.images[0] : images.noImage
      })
    else
      props.removeQueryProducts({
        productId: props.productId,
        link: PUBLIC_URL + props.link,
        name: props.name,
        image: props.images.length ? IMAGE_HOST + props.images[0] : images.noImage
      })
  }

  const handleImageChange = useCallback(() => {
    const image = imageRef.current

    if (!image) return

    image.style.opacity = '0'
    image.style.transition = 'opacity 1s ease'

    setTimeout(() => {
      setCurrentIndex((currentIndex + 1) % props.images.length)
      image.style.opacity = '1'
    }, 500)
  }, [currentIndex, props.images.length])

  useEffect(() => {
    setTimeout(handleImageChange, 3000)
  }, [handleImageChange])

  useEffect(() => {
    if (!props.canSelectProducts) setIsChecked(false)
  }, [props.canSelectProducts])

  return (
    <div className={s.productAltCardMain}>
      <div className={s.productAltCard}>
        {props.canSelectProducts && <input className={s.input} type='checkbox' onChange={checkHandler} />}
        <div className={s.image}>
          <ImageTag src={IMAGE_HOST + props.images[currentIndex]} ref={imageRef} alt='' />
        </div>
        <div className={s.productName + ' ellipsis'}>{props.name}</div>
        <div className={s.viewButton} onClick={() => props.modalHandler(props)}>
          view
        </div>
      </div>
      <div className={s.productNameAlt + ' ellipsis'}>{props.name}</div>
      <div className={s.viewButtonAlt} onClick={() => props.modalHandler(props)}>
        view
      </div>
    </div>
  )
}
