import Intro from './intro'
import AboutUs from './aboutUs'
import { FAQ, FeaturesSection, WhyStashBlog } from 'components'
import { useEffect } from 'react'
import { head } from 'helpers'
import Innovation from './innovation'
import OurServices from './services'

export default function Main() {
  useEffect(() => {
    head({})
  }, [])

  return (
    <>
      <Intro />
      <AboutUs />
      <WhyStashBlog />
      <Innovation />
      <FeaturesSection />
      <OurServices />
      <FAQ />
    </>
  )
}
