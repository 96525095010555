import { NoData } from 'components'
import s from './styles.module.scss'

export default function Main() {
  return (
    <div className={s.main}>
      <NoData color='var(--c-font)' />
    </div>
  )
}
