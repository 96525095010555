import s from './styles.module.scss'
import { getBusinessName, head, isCustomDomain } from 'helpers'
import { useCallback, useEffect, useRef } from 'react'
import Intro from './intro'
import Categories from './categories'
import Catalogues from './catalogues'
import { Alert, NewArrivals, NoData, TopFields } from 'components'
import { connect } from 'react-redux'
import { fetchUserProfile } from '../../../state/actions/userActions'
import * as api from 'api'

const Main = ({ dispatch, loading, user }) => {
  const userProfile = getBusinessName()
  const isUserCustomDomain = isCustomDomain()
  const processing = useRef(false)

  useEffect(() => {
    dispatch(fetchUserProfile(userProfile))
  }, [dispatch, userProfile])

  useEffect(() => {
    head({ title: isUserCustomDomain ? 'Home | ' + userProfile : userProfile + "'s Home | StashBlog" })
  }, [userProfile, isUserCustomDomain])

  const visitsCounter = useCallback(async () => {
    if (processing.current) {
      return
    }

    processing.current = true

    const updateProfileVisits = await api.user.updateProfileVisits({ businessName: userProfile })

    if (updateProfileVisits.code === 'OK') {
      // let profileVisits = updateProfileVisits.profileVisits
    } else {
      Alert.error(updateProfileVisits.error)
    }
    processing.current = false
  }, [userProfile])

  useEffect(() => {
    if (userProfile) visitsCounter()
  }, [visitsCounter, userProfile])

  return (
    <div className={s.main}>
      {!!user.user ? (
        <>
          <Intro />
          <div className={s.userProfile + ' indent'}>
            <TopFields products catalogues />
            <NewArrivals exploreMore products />
            <Categories />
            {!!user.user.totalCatalogues && <Catalogues />}
          </div>
        </>
      ) : (
        <NoData color='var(--c-font)' />
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.user,
  loading: state.loading
})

export default connect(mapStateToProps)(Main)
