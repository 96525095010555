import { useEffect } from 'react'
import s from './styles.module.scss'
import { getBusinessName, head, isCustomDomain } from 'helpers'
import { NewArrivals, NoData, TopFields } from 'components'
import CataloguesSection from './cataloguesSection'
import { connect } from 'react-redux'
import { fetchUserProfile } from '../../../state/actions/userActions'

const Main = ({ dispatch, loading, user }) => {
  const userProfile = getBusinessName()
  const isUserCustomDomain = isCustomDomain()

  useEffect(() => {
    dispatch(fetchUserProfile(userProfile))
  }, [dispatch, userProfile])

  useEffect(() => {
    head({ title: isUserCustomDomain ? 'Catalogues | ' + userProfile : userProfile + "'s Catalogues | StashBlog" })
  }, [userProfile, isUserCustomDomain])

  return (
    <div className={s.main}>
      {!!user.user ? (
        <div className={s.cataloguesPage + ' indent'}>
          <TopFields catalogues />
          <NewArrivals catalogues />
          <CataloguesSection />
        </div>
      ) : (
        <NoData color='var(--c-font)' />
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.user,
  loading: state.loading
})

export default connect(mapStateToProps)(Main)
