import { useState } from 'react'
import s from './styles.module.scss'

export default function Main(props) {
  const [isFocused, setIsFocused] = useState(false)

  return (
    <div className={s.searchBar}>
      <div className={s.fieldAlt}>
        <span
          className={'material-icons-outlined ' + s.iconOnFocus}
          style={{
            opacity: isFocused ? 1 : 0,
            display: isFocused ? 'block' : 'none',
            position: isFocused ? 'relative' : 'absolute',
            transform: isFocused ? 'translateX(0%)' : 'translateX(-50%)'
          }}
        >
          {props.iconName}
        </span>
        <input
          className={s.input}
          type={props.type}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
          autoComplete='off'
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </div>
      <span className='material-icons-outlined' onClick={props.onClick}>
        {props.iconName}
      </span>
    </div>
  )
}
