import s from './styles.module.scss'
import { Alert, Loader, NoData, ProfileCard, Swiper } from 'components'
import { useCallback, useEffect, useRef, useState } from 'react'
import * as api from 'api'

const limit = 12
const perPage = 4
const mediaMatch = window.matchMedia('(max-width: 1279px)')

export default function Main() {
  const [profiles, setProfiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [matches, setMatches] = useState(mediaMatch.matches)
  const processing = useRef(false)

  useEffect(() => {
    const handler = e => setMatches(e.matches)
    mediaMatch.addEventListener('change', handler)
    return () => mediaMatch.removeEventListener('change', handler)
  })

  const pageDataHandler = useCallback(async () => {
    if (processing.current) {
      return
    }
    processing.current = true
    setLoading(true)

    const fetchAllUsersProfiles = await api.user.fetchUserProfiles({
      limit: limit,
      isBlocked: false
    })

    if (fetchAllUsersProfiles.code === 'OK') {
      if (!matches) {
        const chunks = Array.from(
          { length: Math.ceil(fetchAllUsersProfiles.userProfiles.length / perPage) },
          (element, index) => fetchAllUsersProfiles.userProfiles.slice(index * perPage, index * perPage + perPage)
        )
        setProfiles(chunks)
      } else {
        setProfiles(fetchAllUsersProfiles.userProfiles)
      }
    } else {
      Alert.error(fetchAllUsersProfiles.error)
    }
    processing.current = false
    setLoading(false)
  }, [matches])

  useEffect(() => {
    pageDataHandler()
  }, [pageDataHandler])

  return (
    <div className={s.main}>
      <div className={s.userProfilesSection}>
        <div className={s.content + ' indent'}>
          <div className={s.heading}>User Profiles</div>
          {!!loading && <Loader color='var(--c-primary)' colorText='var(--c-font)' />}
          {!loading && profiles.length === 0 && <NoData color='var(--c-black)' />}
          {!loading && profiles.length !== 0 && (
            <div className={s.carousel}>
              <Swiper
                infinite
                // timer={3000}
                carouselType='casualProfile'
                paginationOn={false}
                navArrowsOn={profiles.length > 1 ? true : false}
                arrowsInside={false}
                borderTopRadius={false}
              >
                {!matches
                  ? profiles.map((chunk, index) => (
                      <div
                        className={s.page}
                        key={index}
                        style={{
                          gridTemplateColumns:
                            chunk.length === 1
                              ? 'auto'
                              : chunk.length === 2
                              ? 'auto auto'
                              : chunk.length === 3
                              ? 'auto auto auto'
                              : 'auto auto auto auto'
                        }}
                      >
                        {chunk.map(
                          (
                            {
                              businessName,
                              logo,
                              facebookLink,
                              instagramLink,
                              pinterestLink,
                              linkedInLink,
                              youtubeChannelLink,
                              websiteLink
                            },
                            i
                          ) => (
                            <ProfileCard
                              businessName={businessName}
                              logo={logo}
                              facebookLink={facebookLink}
                              instagramLink={instagramLink}
                              pinterestLink={pinterestLink}
                              linkedInLink={linkedInLink}
                              youtubeChannelLink={youtubeChannelLink}
                              websiteLink={websiteLink}
                              key={i}
                            />
                          )
                        )}
                      </div>
                    ))
                  : profiles.map(
                      (
                        {
                          businessName,
                          logo,
                          facebookLink,
                          instagramLink,
                          pinterestLink,
                          linkedInLink,
                          youtubeChannelLink,
                          websiteLink
                        },
                        i
                      ) => (
                        <ProfileCard
                          businessName={businessName}
                          logo={logo}
                          facebookLink={facebookLink}
                          instagramLink={instagramLink}
                          pinterestLink={pinterestLink}
                          linkedInLink={linkedInLink}
                          youtubeChannelLink={youtubeChannelLink}
                          websiteLink={websiteLink}
                          key={i}
                        />
                      )
                    )}
              </Swiper>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
