import { useCallback, useEffect, useRef, useState } from 'react'
import s from './styles.module.scss'
import { head, imageCompressor } from 'helpers'
import images from 'images'
import * as api from 'api'
import { Alert, Form, ImageTag, Input, Loader, Modal, ProgressBar, TextArea } from 'components'

const IMAGE_HOST = process.env.REACT_APP_IMAGE_HOST

export default function Main() {
  const [loading, setLoading] = useState(false)
  const [userInfo, setUserInfo] = useState()

  const [userLogo, setUserLogo] = useState()
  const [isCurrentlyUploadedLogo, setIsCurrentlyUploadedLogo] = useState(false)
  const [uploadProgressLogo, setUploadProgressLogo] = useState(0)

  const [userHomeBanners, setUserHomeBanners] = useState([])
  const [uploadingHomeBanners, setUploadingHomeBanners] = useState(0)
  const [isCurrentlyUploadedHomeBanners, setIsCurrentlyUploadedHomeBanners] = useState(false)
  const [uploadProgressHomeBanners, setUploadProgressHomeBanners] = useState(0)

  const [aboutUsDescription, setAboutUsDescription] = useState('')
  const [userAboutUsBanners, setUserAboutUsBanners] = useState([])
  const [uploadingAboutUsBanners, setUploadingAboutUsBanners] = useState(0)
  const [isCurrentlyUploadedAboutUsBanners, setIsCurrentlyUploadedAboutUsBanners] = useState(false)
  const [uploadProgressAboutUsBanners, setUploadProgressAboutUsBanners] = useState(0)

  const [facebookLink, setFacebookLink] = useState('')
  const [instagramLink, setInstagramLink] = useState('')
  const [youtubeChannelLink, setYoutubeChannelLink] = useState('')
  const [pinterestLink, setPinterestLink] = useState('')
  const [linkedInLink, setLinkedInLink] = useState('')
  const [websiteLink, setWebsiteLink] = useState('')

  const processing = useRef()

  const userHomeInfoHandler = useCallback(async businessName => {
    const fetchUserHomeInfo = await api.user.fetchUserHome({ businessName })

    if (fetchUserHomeInfo.code === 'OK') {
      if (fetchUserHomeInfo.userHome.banners.length) {
        const imageUrls = fetchUserHomeInfo.userHome.banners.map(img => `${IMAGE_HOST}${img}`)
        const imageResponses = await Promise.all(imageUrls.map(url => fetch(url)))
        const files = await Promise.all(imageResponses.map(res => res.blob()))
        const imageFiles = files.map((blob, index) => {
          const filename = fetchUserHomeInfo.userHome.banners[index]
          const fileType = blob.type
          return new File([blob], filename, { type: fileType })
        })
        setUserHomeBanners(imageFiles)
      }
    } else {
      Alert.error(fetchUserHomeInfo.error)
    }
  }, [])

  const userAboutUsInfoHandler = useCallback(async businessName => {
    const fetchUserAboutUsInfo = await api.user.fetchUserAboutUs({ businessName })

    if (fetchUserAboutUsInfo.code === 'OK') {
      if (fetchUserAboutUsInfo.userAboutUs.images.length) {
        const imageUrls = fetchUserAboutUsInfo.userAboutUs.images.map(img => `${IMAGE_HOST}${img}`)
        const imageResponses = await Promise.all(imageUrls.map(url => fetch(url)))
        const files = await Promise.all(imageResponses.map(res => res.blob()))
        const imageFiles = files.map((blob, index) => {
          const filename = fetchUserAboutUsInfo.userAboutUs.images[index]
          const fileType = blob.type
          return new File([blob], filename, { type: fileType })
        })
        setUserAboutUsBanners(imageFiles)
      }
      setAboutUsDescription(fetchUserAboutUsInfo.userAboutUs.description)
    } else {
      Alert.error(fetchUserAboutUsInfo.error)
    }
  }, [])

  const userInfoHandler = useCallback(async () => {
    if (processing.current) {
      return
    }
    processing.current = true
    setLoading(true)

    const fetchUserInfo = await api.auth.getUser({})

    if (fetchUserInfo.code === 'OK') {
      setUserInfo(fetchUserInfo.user)

      if (fetchUserInfo.user.logo !== 'NAN') {
        const logoUrl = IMAGE_HOST + fetchUserInfo.user.logo
        const logoResponse = await fetch(logoUrl)
        const files = await logoResponse.blob()
        const logoFile = new File([files], fetchUserInfo.user.logo, { type: files.type })
        setUserLogo(logoFile)
      }

      setFacebookLink(fetchUserInfo.user.facebookLink)
      setInstagramLink(fetchUserInfo.user.instagramLink)
      setYoutubeChannelLink(fetchUserInfo.user.youtubeChannelLink)
      setPinterestLink(fetchUserInfo.user.pinterestLink)
      setLinkedInLink(fetchUserInfo.user.linkedInLink)
      setWebsiteLink(fetchUserInfo.user.websiteLink)
      await userHomeInfoHandler(fetchUserInfo.user.businessName)
      await userAboutUsInfoHandler(fetchUserInfo.user.businessName)
    } else {
      Alert.error(fetchUserInfo.error)
    }

    processing.current = false
    setLoading(false)
  }, [userHomeInfoHandler, userAboutUsInfoHandler])

  useEffect(() => {
    userInfoHandler()
  }, [userInfoHandler])

  useEffect(() => {
    head({ title: 'User Profile Info | StashBlog Admin' })
  }, [])

  const saveHandler = async () => {
    if (processing.current) return

    processing.current = true

    const userData = new FormData()
    userData.append('logo', userLogo)
    userData.append('facebookLink', facebookLink)
    userData.append('instagramLink', instagramLink)
    userData.append('youtubeChannelLink', youtubeChannelLink)
    userData.append('pinterestLink', pinterestLink)
    userData.append('linkedInLink', linkedInLink)
    userData.append('websiteLink', websiteLink)
    // userData.append('updatedAt', Date.now())

    const updateUserDetails = await api.auth.updateUserDetails(userData)

    if (updateUserDetails.code === 'OK') {
      Alert.success('User Details Updated')
    } else {
      Alert.error(updateUserDetails.error)
    }

    const userHomeData = new FormData()
    if (userHomeBanners.length) {
      userHomeBanners.forEach((file, i) => {
        userHomeData.append(`banners`, file, file.name)
      })
    } else {
      userHomeData.append(`banners`, [])
    }
    // userHomeData.append('updatedAt', Date.now())

    const updateUserHomeDetails = await api.auth.updateUserHomeDetails(userHomeData)

    if (updateUserHomeDetails.code === 'OK') {
      Alert.success('User Home Details Updated')
    } else {
      Alert.error(updateUserHomeDetails.error)
    }

    const userAboutUsData = new FormData()
    userAboutUsData.append('description', aboutUsDescription)
    // userAboutUsData.append('updatedAt', Date.now())
    userAboutUsBanners.forEach((file, i) => {
      userAboutUsData.append(`banners`, file, file.name)
    })

    const updateUserAboutUsDetails = await api.auth.updateUserAboutUsDetails(userAboutUsData)
    if (updateUserAboutUsDetails.code === 'OK') {
      Alert.success('User About Us Details Updated')
    } else {
      Alert.error(updateUserAboutUsDetails.error)
    }

    processing.current = false
  }

  return (
    <div className={s.main}>
      <div className={s.userProfileInfo + ' indent'}>
        <div className={s.header} id='nav'>
          <div>
            <span className='material-icons-outlined'>person</span>
            User Profile Info
          </div>
          <div className={s.save} onClick={saveHandler}>
            save
          </div>
        </div>

        <div className={s.content + ' innerScroll'}>
          {!loading && userInfo && (
            <>
              <div className={s.personalInfo}>
                <div className={s.heading}>Basic Info</div>
                <Form className={s.form}>
                  <div className={s.uploadFiles}>
                    <div>
                      <div className={s.uploadLabel}>
                        <span className='material-icons-outlined'>cloud_upload</span>
                        Upload Logo
                      </div>
                      <Input.Admin
                        isMultiple={false}
                        type='file'
                        filesSelected={userLogo ? 1 : 0}
                        onChange={async e => {
                          setIsCurrentlyUploadedLogo(true)
                          setUploadProgressLogo(0)
                          let compressed = Array.prototype.slice.call(e.target.files)
                          // console.log('original', compressed)
                          compressed = await imageCompressor(compressed, 'logo')
                          setUploadProgressLogo(50)
                          // console.log('compressed', compressed)
                          setUserLogo(compressed[0])
                          setUploadProgressLogo(100)
                          Alert.success('Logo uploaded')
                          setIsCurrentlyUploadedLogo(false)
                        }}
                      />
                    </div>

                    {!!isCurrentlyUploadedLogo && (
                      <div className={s.uploadProgress}>
                        <div>Uploading Logo</div>
                        <ProgressBar progress={uploadProgressLogo} color={'var(--c-green)'} />
                      </div>
                    )}

                    {!!userLogo && (
                      <div className={s.imagesUploaded}>
                        <div className={s.image}>
                          <div className={s.imageActions}>
                            <span
                              className='material-icons-outlined'
                              onClick={async () => Modal.imageViewer('imageViewerModal', [userLogo])}
                              title='Open full image'
                            >
                              open_in_full
                            </span>
                            <span
                              className='material-icons-outlined'
                              onClick={async () => Modal.imageViewer('imageViewerModal', [userLogo], true, setUserLogo)}
                              title='Crop image'
                            >
                              crop
                            </span>
                            <span
                              className='material-icons-outlined'
                              onClick={async () => setUserLogo()}
                              title='Remove image'
                            >
                              delete
                            </span>
                          </div>
                          <ImageTag src={URL.createObjectURL(userLogo)} alt='' />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={s.links}>
                    <div className='row'>
                      <div className={s.social}>
                        <div>
                          <img src={images.facebookAlt} alt='' />
                        </div>
                        <Input.Admin
                          type='text'
                          placeholder='Enter facebook link'
                          value={facebookLink}
                          onChange={e => setFacebookLink(e.target.value)}
                        />
                      </div>
                      <div className={s.social}>
                        <div>
                          <img src={images.instagramAlt} alt='' />
                        </div>
                        <Input.Admin
                          type='text'
                          placeholder='Enter instagram link'
                          value={instagramLink}
                          onChange={e => setInstagramLink(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className='row'>
                      <div className={s.social}>
                        <div>
                          <img src={images.youtube} alt='' />
                        </div>

                        <Input.Admin
                          type='text'
                          placeholder='Enter youtube link'
                          value={youtubeChannelLink}
                          onChange={e => setYoutubeChannelLink(e.target.value)}
                        />
                      </div>
                      <div className={s.social}>
                        <div>
                          <img src={images.pinterest} alt='' />
                        </div>

                        <Input.Admin
                          type='text'
                          placeholder='Enter pinterest link'
                          value={pinterestLink}
                          onChange={e => setPinterestLink(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className='row'>
                      <div className={s.social}>
                        <div>
                          <img src={images.linkedInAlt} alt='' />
                        </div>

                        <Input.Admin
                          type='text'
                          placeholder='Enter linkedIn link'
                          value={linkedInLink}
                          onChange={e => setLinkedInLink(e.target.value)}
                        />
                      </div>

                      <div className={s.social}>
                        <div>
                          <img src={images.website} alt='' />
                        </div>

                        <Input.Admin
                          type='text'
                          placeholder='Enter any other website link'
                          value={websiteLink}
                          onChange={e => setWebsiteLink(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              </div>

              <div className={s.homeInfo}>
                <div className={s.heading}>Home Info</div>
                <Form className={s.form}>
                  <div className={s.uploadFiles}>
                    <div>
                      <div className={s.uploadLabel}>
                        <span className='material-icons-outlined'>cloud_upload</span>
                        Upload Banners
                      </div>
                      <Input.Admin
                        type='file'
                        filesSelected={userHomeBanners.length}
                        onChange={async e => {
                          setIsCurrentlyUploadedHomeBanners(true)
                          setUploadProgressHomeBanners(0)
                          setUploadingHomeBanners(e.target.files.length)
                          let compressed = Array.prototype.slice.call(e.target.files)
                          // console.log('original', userHomeBanners.concat(compressed))
                          compressed = await imageCompressor(compressed, 'homeBanners')
                          setUploadProgressHomeBanners(50)
                          compressed = userHomeBanners.concat(compressed)
                          // console.log('compressed', compressed)
                          setUploadProgressHomeBanners(100)
                          setUserHomeBanners(compressed)
                          Alert.success(e.target.files.length + ' home banners uploaded')
                          setIsCurrentlyUploadedHomeBanners(false)
                        }}
                      />
                    </div>

                    {!!isCurrentlyUploadedHomeBanners && !!uploadingHomeBanners && (
                      <div className={s.uploadProgress}>
                        <div>Uploading {uploadingHomeBanners} images</div>
                        <ProgressBar progress={uploadProgressHomeBanners} color={'var(--c-green)'} />
                      </div>
                    )}

                    {userHomeBanners.length !== 0 && (
                      <div className={s.imagesUploaded}>
                        {userHomeBanners.map((file, i) => (
                          <div className={s.image + ' ' + s.imageAlt} key={i}>
                            <div className={s.imageActions} style={{ aspectRatio: '16/9' }}>
                              <span
                                className='material-icons-outlined'
                                onClick={async () => Modal.imageViewer('imageViewerModal', [userHomeBanners[i]])}
                                title='Open full image'
                              >
                                open_in_full
                              </span>
                              <span
                                className='material-icons-outlined'
                                onClick={async () =>
                                  Modal.imageViewer(
                                    'imageViewerModal',
                                    [userHomeBanners[i]],
                                    true,
                                    image => {
                                      const updatedFiles = [...userHomeBanners]
                                      updatedFiles[i] = image
                                      setUserHomeBanners(updatedFiles)
                                    },
                                    16 / 9
                                  )
                                }
                                title='Crop image'
                              >
                                crop
                              </span>
                              <span
                                className='material-icons-outlined'
                                onClick={() => {
                                  const updatedFiles = [...userHomeBanners]
                                  updatedFiles.splice(i, 1)
                                  setUserHomeBanners(updatedFiles)
                                }}
                                title='Remove image'
                              >
                                delete
                              </span>
                            </div>

                            <ImageTag src={URL.createObjectURL(file)} alt='' style={{ aspectRatio: '16/9' }} />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </Form>
              </div>

              <div className={s.aboutInfo}>
                <div className={s.heading}>AboutUs Info</div>
                <Form className={s.form}>
                  <TextArea.Admin
                    iconName='description'
                    placeholder='Enter Description'
                    value={aboutUsDescription}
                    onChange={e => setAboutUsDescription(e.target.value)}
                  />
                  <div className={s.uploadFiles}>
                    <div>
                      <div className={s.uploadLabel}>
                        <span className='material-icons-outlined'>cloud_upload</span>
                        Upload AboutUs Images
                      </div>
                      <Input.Admin
                        type='file'
                        filesSelected={userAboutUsBanners.length}
                        onChange={async e => {
                          setIsCurrentlyUploadedAboutUsBanners(true)
                          setUploadProgressAboutUsBanners(0)
                          setUploadingAboutUsBanners(e.target.files.length)
                          let compressed = Array.prototype.slice.call(e.target.files)
                          if (userAboutUsBanners.length + e.target.files.length > 2) {
                            Alert.warn('Only 2 images allowed')
                            setIsCurrentlyUploadedAboutUsBanners(false)
                          } else {
                            // console.log('original', userAboutUsBanners.concat(compressed))
                            compressed = await imageCompressor(compressed, 'aboutUsBanners')
                            setUploadProgressAboutUsBanners(50)
                            compressed = userAboutUsBanners.concat(compressed)
                            // console.log('compressed', compressed)
                            setUploadProgressAboutUsBanners(100)
                            setUserAboutUsBanners(compressed)
                            Alert.success(e.target.files.length + ' about us banners uploaded')
                            setIsCurrentlyUploadedAboutUsBanners(false)
                          }
                        }}
                      />
                    </div>

                    {!!isCurrentlyUploadedAboutUsBanners && !!uploadingAboutUsBanners && (
                      <div className={s.uploadProgress}>
                        <div>Uploading {uploadingAboutUsBanners} images</div>
                        <ProgressBar progress={uploadProgressAboutUsBanners} color={'var(--c-green)'} />
                      </div>
                    )}

                    {userAboutUsBanners.length !== 0 && (
                      <div className={s.imagesUploaded}>
                        {userAboutUsBanners.map((file, i) => (
                          <div className={s.image + ' ' + s.imageAlt} key={i}>
                            <div className={s.imageActions} style={{ aspectRatio: '16/9' }}>
                              <span
                                className='material-icons-outlined'
                                onClick={async () => Modal.imageViewer('imageViewerModal', [userAboutUsBanners[i]])}
                                title='Open full image'
                              >
                                open_in_full
                              </span>
                              <span
                                className='material-icons-outlined'
                                onClick={async () =>
                                  Modal.imageViewer(
                                    'imageViewerModal',
                                    [userAboutUsBanners[i]],
                                    true,
                                    image => {
                                      const updatedFiles = [...userAboutUsBanners]
                                      updatedFiles[i] = image
                                      setUserAboutUsBanners(updatedFiles)
                                    },
                                    16 / 9
                                  )
                                }
                                title='Crop image'
                              >
                                crop
                              </span>
                              <span
                                className='material-icons-outlined'
                                onClick={() => {
                                  const updatedFiles = [...userAboutUsBanners]
                                  updatedFiles.splice(i, 1)
                                  setUserAboutUsBanners(updatedFiles)
                                }}
                                title='Remove image'
                              >
                                delete
                              </span>
                            </div>
                            <ImageTag src={URL.createObjectURL(file)} alt='' style={{ aspectRatio: '16/9' }} />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </Form>
              </div>
            </>
          )}

          {!!loading && <Loader color='var(--c-primary)' colorText='var(--c-font-dark)' />}
        </div>
      </div>
    </div>
  )
}
